// /* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Chip, Grid, MenuItem, Stack, Switch, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { listRoomTypeDetails, updateRoomtype } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack, Close } from '@mui/icons-material';
import { UpdatableField } from '../../components/TextField';
import { tokens } from '../../theme';
import { Formik } from 'formik';
import MealPlansOfRoomType from './mealPlansOfRoomType';
import RoomtypeOffers from './roomtypeOffers';
import ImgListView from '../../components/ImgList';
import ImgUploaderInput from '../../components/ImgUploaderInput';
import RoomtypeOtaCount from './roomtypeOtaCount';
import RoomtypeOtas from './roomtypeOtas';
import PlusOrMinusButton from '../../components/PlusOrMinusButton';
import RoomtypeOtaRatePlans from './roomtypeOtaRatePlans';

const textColor = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#dbdbdb",
  },
}
// const initialMealPlans = {
//   adult: {
//     ep: 0,
//     cp: 0,
//     map: 0,
//     ap: 0.
//   },
//   child: {
//     ep: 0,
//     cp: 0,
//     map: 0,
//     ap: 0.
//   },
// }

function RoomtypeDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [updation, setUpdation] = useState(false);
  const [rates, setRates] = useState({});
  // const [mealPlans, setMealPlans] = useState(initialMealPlans);
  // eslint-disable-next-line
  const [name, setName] = useState("");
  const [selectedHighlights, setSelectedHighlights] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [images, setImages] = useState([]);
  const [tab, setTab] = useState(1);

  const { roomtypeDetails, highlights, facilities, } = useSelector(
    ({
      admin: { roomtypeDetails, highlights, facilities, }
    }) => ({
      roomtypeDetails, highlights, facilities,
    })
  );
  
  useEffect(() => {
    document.title = 'Room type details';
    // dispatch(resetRoomTypeDetails());
    dispatch(listRoomTypeDetails({ id: id }));
    setRates(roomtypeDetails?.rates)
    setSelectedHighlights(roomtypeDetails?.highlights?.map(a => a._id))
    setSelectedFacilities(roomtypeDetails?.facilities?.map(a => a._id))
    setImages(roomtypeDetails?.images || [])
    // if (roomtypeDetails?.meal_plans !== null) {
    //   setMealPlans(roomtypeDetails?.meal_plans)
    // }
    // eslint-disable-next-line
  }, [dispatch, id]);

  const handleRateChange = (newValue, keyName) => {
    var valueAdd = rates;
    if (newValue?.target) {
      valueAdd[keyName] = newValue.target.value;
    } else {
      valueAdd[keyName] = newValue
    }
    setRates(valueAdd);
    setName(newValue.target.value)
  }
  // const handleMealPlanChange = (newValue, keyName, planFor) => {
  //   var valueAdd = mealPlans;
  //   if (newValue?.target) {
  //     valueAdd[planFor][keyName] = newValue.target.value;
  //   } else {
  //     valueAdd[planFor][keyName] = newValue
  //   }
  //   setMealPlans(valueAdd);
  //   setName(newValue.target.value)
  // }

  const handleFormSubmit = async (values) => {
    values.id = id;
    values.rates = rates;
    // values.meal_plans = mealPlans;
    values.highlights = selectedHighlights;
    values.facilities = selectedFacilities;
    values.images = images;
    console.log(values);
    setUpdation(updation ? false : true)
    await dispatch(updateRoomtype(values));
  };

  return (
    <Box m="20px" mb={15}>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={roomtypeDetails}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>

            <Stack
              direction={"row"}
              alignItems={"end"}
              spacing={1}
              m="20px 0"
            >
              <Button size='small' variant='outlined' onClick={() => navigate(-1)}>
                <ArrowBack fontSize='small' />
              </Button>
              {/* <Typography fontWeight="bold" variant='h4'>
                ROOM TYPE DETAILS
              </Typography> */}
              <Typography fontWeight="bold" variant="h4" textTransform={"uppercase"} color={colors.greenAccent[500]} noWrap>
                    {values?.name}
                </Typography>

            </Stack>

            <Box sx={{ borderBottom: 1, mb: 2, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={(e, n) => setTab(n)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile aria-label="scrollable force tabs example">
                <Tab value={1} label="Details" sx={{ fontWeight: "bold" }} />
                <Tab value={2} label="Meal Plans" sx={{ fontWeight: "bold" }} />
                <Tab value={3} label="Rates" sx={{ fontWeight: "bold" }} />
                <Tab value={4} label="OTA List" sx={{ fontWeight: "bold" }} />
                <Tab value={6} label="OTA Rate Plans" sx={{ fontWeight: "bold" }} />
                <Tab value={5} label="OTA Count" sx={{ fontWeight: "bold" }} />
              </Tabs>
            </Box>

            {tab === 1 && (
              <Box
                width="100%"
                p={2}
                borderRadius={2}
                backgroundColor={colors.primary[400]}
              >
                <Grid container columnSpacing={{ md: 4, xs: 2 }} rowSpacing={2}>
                  <Grid item md={12} xs={12}>
                    <Stack direction="row" justifyContent="end" spacing={2}>
                      {/* <Typography variant='h4' color={colors.greenAccent[200]} fontWeight={700}>
                        {values?.name?.toUpperCase() || "-"}
                      </Typography> */}
                      {!updation && <Button
                        size='small'
                        variant='outlined'
                        onClick={() => setUpdation(updation ? false : true)}
                      >
                        <b>Update</b>
                      </Button>}
                      {updation &&
                        <Stack direction="row" spacing={1}>
                          <Button
                            size='small'
                            variant='outlined'
                            onClick={() => setUpdation(false)}
                          >
                            <Close fontSize='small' />
                          </Button>
                          <Button
                            size='small'
                            type='submit'
                            variant='contained'
                          // onClick={handleFormSubmit}
                          >
                            <b>Save</b>
                          </Button>
                        </Stack>}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={2}>
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Name:
                        </Typography>
                        <UpdatableField
                          fullWidth
                          required
                          multiline
                          maxRows={2}
                          size='small'
                          type="text"
                          name="name"
                          sx={textColor}
                          disabled={!updation}
                          value={values?.name || ""}
                          onChange={handleChange}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Description:
                        </Typography>
                        <UpdatableField
                          fullWidth
                          multiline
                          maxRows={10}
                          size='small'
                          type="text"
                          name="description"
                          sx={textColor}
                          disabled={!updation}
                          value={values?.description || ""}
                          onChange={handleChange}
                        />
                      </Stack>
                      {/* <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Number of beds:
                        </Typography>
                        {!updation ? (
                          <UpdatableField
                            fullWidth
                            required
                            size='small'
                            type="number"
                            name="no_of_beds"
                            sx={textColor}
                            disabled={!updation}
                            value={values?.no_of_beds || ""}
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={handleChange}
                          />
                        ) : (
                          <PlusOrMinusButton
                            min={1}
                            max={20}
                            size="small"
                            value={values?.no_of_beds}
                            handleChange={(newValue) => setFieldValue("no_of_beds", newValue)}
                          />
                        )}
                      </Stack> */}
                      {/* <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Minimum number of people:
                        </Typography>
                        <UpdatableField
                          fullWidth
                          required
                          size='small'
                          type="number"
                          name="min_no_of_people"
                          sx={textColor}
                          disabled={!updation}
                          value={values?.min_no_of_people || ""}
                          InputProps={{ inputProps: { min: 1 } }}
                          onChange={handleChange}
                        />
                      </Stack> */}
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Maximum number of people / room:
                        </Typography>
                        {!updation ? (
                          <UpdatableField
                            fullWidth
                            required
                            size='small'
                            type="number"
                            name="max_no_of_people"
                            sx={textColor}
                            disabled={!updation}
                            value={values?.max_no_of_people || ""}
                            InputProps={{ inputProps: { min: 1, max: 3 } }}
                            onChange={handleChange}
                          />
                        ) : (
                          <PlusOrMinusButton
                            min={1}
                            max={3}
                            size="small"
                            value={values?.max_no_of_people}
                            handleChange={(newValue) => setFieldValue("max_no_of_people", newValue)}
                          />
                        )}
                      </Stack>
                      {/* <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Rooms for OTA:
                        </Typography>
                        {!updation ? (
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            name="ota_rooms"
                            sx={textColor}
                            disabled={!updation}
                            value={values?.ota_rooms || 0}
                            onChange={handleChange}
                            onInvalid={e => e.target.setCustomValidity('Value must be less than or equal to number of rooms')}
                            onInput={e => e.target.setCustomValidity('')}
                            InputProps={{ inputProps: { min: 0, max: values?.total_rooms } }}
                          />
                        ) : (
                          <PlusOrMinusButton
                            min={1}
                            max={values?.total_rooms}
                            size="small"
                            value={values?.ota_rooms || 0}
                            handleChange={(newValue) => setFieldValue("ota_rooms", newValue)}
                          />
                        )}
                      </Stack> */}
                      {localStorage.getItem("isDependent") === "true" ? (
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"70%"}>
                            Number of rooms:*
                          </Typography>
                          {!updation ? (
                            <UpdatableField
                              fullWidth
                              required
                              size="small"
                              type="number"
                              placeholder="Number of rooms"
                              name="display_room_no"
                              sx={textColor}
                              disabled={!updation}
                              value={values?.display_room_no || 0}
                              InputProps={{ inputProps: { min: 1 } }}
                              onChange={handleChange}
                            />
                          ) : (
                            <PlusOrMinusButton
                              min={0}
                              max={200}
                              size="small"
                              value={values?.display_room_no || 0}
                              handleChange={(newValue) => setFieldValue("display_room_no", newValue)}
                            />
                          )}
                        </Stack>
                      ) : null}
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Highlights:
                        </Typography>
                        <UpdatableField
                          select
                          fullWidth
                          size="small"
                          sx={textColor}
                          disabled={!updation}
                          value={selectedHighlights || []}
                          SelectProps={{ multiple: true }}
                          onChange={(e) => setSelectedHighlights(e.target.value)}
                        >
                          {highlights?.list?.map((e, i) => (
                            <MenuItem value={e._id} key={i}>
                              <Checkbox sx={{ p: "0 5px" }} size="small" checked={selectedHighlights?.indexOf(e._id) > -1} />
                              {e.name}
                            </MenuItem>
                          ))}
                        </UpdatableField>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Facilities:
                        </Typography>
                        <UpdatableField
                          select
                          fullWidth
                          size="small"
                          sx={textColor}
                          disabled={!updation}
                          value={selectedFacilities || []}
                          SelectProps={{ multiple: true }}
                          onChange={(e) => setSelectedFacilities(e.target.value)}
                        >
                          {facilities?.list?.map((e, i) => (
                            <MenuItem value={e._id} key={i}>
                              <Checkbox sx={{ p: "0 5px" }} size="small" checked={selectedFacilities?.indexOf(e._id) > -1} />
                              {e.name}
                            </MenuItem>
                          ))}
                        </UpdatableField>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Typography width={"70%"}>
                          Total rooms:
                        </Typography>
                        <Typography>
                          {values?.total_rooms}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Typography width={"70%"}>
                          Availability:
                        </Typography>
                        <Chip
                          variant="outlined"
                          label={values?.is_active ? "active" : "inactive"}
                          color={values?.is_active ? "primary" : "warning"}
                        />
                      </Stack>
                      {localStorage.getItem("isDependent") === "true" ? (
                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                          <Typography width={"34%"}>
                            Private:
                          </Typography>
                          <Switch
                            checked={values?.is_private}
                            disabled={!updation}
                            onChange={(e) => setFieldValue("is_private", e.target.checked)}
                          />
                        </Stack>
                      ) : null}
                    </Stack>

                    {/* {roomtypeDetails?.ota_codes ? (
                    <Stack mt={2} spacing={2}>
                      <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                        OTA Room type id
                      </Typography>
                      {Object.keys(roomtypeDetails?.ota_codes).map((key, index) => {
                        if (roomtypeDetails?.ota_codes[key]) {
                          return (
                            <Stack direction="row" justifyContent="space-between" spacing={2} key={index}>
                              <Typography width={"70%"} textTransform={"capitalize"}>
                                {key}:
                              </Typography>
                              <Typography>
                                {roomtypeDetails?.ota_codes[key]}
                              </Typography>
                            </Stack>
                          )
                        } else return null;
                      })}
                    </Stack>
                    ) : null} */}

                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={2}>
                      <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                        Rates
                      </Typography>
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Double occupancy rate:
                        </Typography>
                        <UpdatableField
                          fullWidth
                          required
                          size='small'
                          type="number"
                          name="standard_rate"
                          sx={textColor}
                          disabled={!updation}
                          value={rates?.standard_rate || ""}
                          onChange={(e) => handleRateChange(e, "standard_rate")}
                          InputProps={{ inputProps: { min: 1 } }}
                        />
                      </Stack>
                      {values?.max_no_of_people === 3 ? (
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Triple occupancy rate:
                        </Typography>
                        <UpdatableField
                          fullWidth
                          size='small'
                          type="number"
                          name="max_occupancy_rate"
                          sx={textColor}
                          disabled={!updation}
                          value={rates?.max_occupancy_rate || ""}
                          onChange={(e) => handleRateChange(e, "max_occupancy_rate")}
                        />
                      </Stack>
                      ) : null}
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Single occupancy rate:
                        </Typography>
                        <UpdatableField
                          fullWidth
                          size='small'
                          type="number"
                          name="single_occupancy_rate"
                          sx={textColor}
                          disabled={!updation}
                          value={rates?.single_occupancy_rate || ""}
                          onChange={(e) => handleRateChange(e, "single_occupancy_rate")}
                        />
                      </Stack>
                      {/* <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Extra bed rate:
                        </Typography>
                        <UpdatableField
                          fullWidth
                          size='small'
                          type="number"
                          name="extra_bed_rate"
                          sx={textColor}
                          disabled={!updation}
                          value={rates?.extra_bed_rate || ""}
                          onChange={(e) => handleRateChange(e, "extra_bed_rate")}
                        />
                      </Stack> */}
                      <Stack direction="row" spacing={2}>
                        <Typography width={"70%"}>
                          Child rate:
                        </Typography>
                        <UpdatableField
                          fullWidth
                          size='small'
                          type="number"
                          name="child_rate"
                          sx={textColor}
                          disabled={!updation}
                          value={rates?.child_rate || ""}
                          onChange={(e) => handleRateChange(e, "child_rate")}
                        />
                      </Stack>
                    </Stack>

                    <Stack mt={2} spacing={2}>
                      <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                        Images
                      </Typography>
                      {!updation ? (
                        <ImgListView images={roomtypeDetails?.images || []} />
                      ) : (
                        <ImgUploaderInput
                          images={images || []}
                          onChange={(e) => setImages(e)}
                        />
                      )}
                    </Stack>

                    {/* <Box mt={2}>
                    <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                      Meal Plans
                    </Typography>
                    <Grid container mt={1} spacing={1}>
                      <Grid item md={12} xs={12}>
                        <Typography fontWeight={700} color={colors.greenAccent[600]}>
                          Adult
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"40%"}>
                            EP:
                          </Typography>
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            sx={textColor}
                            disabled={!updation}
                            value={mealPlans?.adult?.ep}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleMealPlanChange(e, "ep", "adult")}
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"40%"}>
                            CP:
                          </Typography>
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            sx={textColor}
                            disabled={!updation}
                            value={mealPlans?.adult?.cp}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleMealPlanChange(e, "cp", "adult")}
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"40%"}>
                            MAP:
                          </Typography>
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            sx={textColor}
                            disabled={!updation}
                            value={mealPlans?.adult?.map}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleMealPlanChange(e, "map", "adult")}
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"40%"}>
                            AP:
                          </Typography>
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            sx={textColor}
                            disabled={!updation}
                            value={mealPlans?.adult?.ap}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleMealPlanChange(e, "ap", "adult")}
                          />
                        </Stack>
                      </Grid>
                    </Grid>

                    <Grid container mt={1} spacing={1}>
                      <Grid item md={12} xs={12}>
                        <Typography fontWeight={700} color={colors.greenAccent[600]}>
                          Child
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"40%"}>
                            EP:
                          </Typography>
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            sx={textColor}
                            disabled={!updation}
                            value={mealPlans?.child?.ep}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleMealPlanChange(e, "ep", "child")}
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"40%"}>
                            CP:
                          </Typography>
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            sx={textColor}
                            disabled={!updation}
                            value={mealPlans?.child?.cp}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleMealPlanChange(e, "cp", "child")}
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"40%"}>
                            MAP:
                          </Typography>
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            sx={textColor}
                            disabled={!updation}
                            value={mealPlans?.child?.map}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleMealPlanChange(e, "map", "child")}
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"40%"}>
                            AP:
                          </Typography>
                          <UpdatableField
                            fullWidth
                            size='small'
                            type="number"
                            sx={textColor}
                            disabled={!updation}
                            value={mealPlans?.child?.ap}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleMealPlanChange(e, "ap", "child")}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box> */}
                  </Grid>
                </Grid>
              </Box>
            )}
          </form>
        )}
      </Formik>

      {tab === 2 && (
        <MealPlansOfRoomType
          roomtypeId={id}
          dispatch={dispatch}
          colors={colors}
        />
      )}
      {tab === 3 && (
        <RoomtypeOffers
          roomtypeId={id}
          dispatch={dispatch}
          colors={colors}
        />
      )}
      {tab === 4 && (
        <RoomtypeOtas
          roomtypeId={id}
          dispatch={dispatch}
          colors={colors}
        />
      )}
      {tab === 5 && (
        <RoomtypeOtaCount
          roomtypeId={id}
          dispatch={dispatch}
          colors={colors}
        />
      )}
      {tab === 6 && (
        <RoomtypeOtaRatePlans
          roomtypeId={id}
          dispatch={dispatch}
          colors={colors}
        />
      )}
    </Box>
  )
}

export default RoomtypeDetails