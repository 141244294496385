import React from "react";
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../../theme";
import { Close, Warning } from "@mui/icons-material";
import { cancelBooking } from "../../../store/actions";
import { toast } from "react-toastify";
import Header from "../../../components/Header";

function CancelBookingModal({ bookingDetails, setCancelModal }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);

    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));

    const modalBoxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const handleCancelBtn = async() => {
        let res = await dispatch(cancelBooking({
            booking_id: bookingDetails?.booking_id
        }))
        if(res){
            toast.success("Booking cancelled");
            setCancelModal(false);
        }
    }

    return (
        <>
            <Box sx={modalBoxStyle}>
                <Stack direction="row" justifyContent="space-between">
                    <Header title="Are you sure to cancel this booking ?" titleVariant="h4" />
                    <Close onClick={() => setCancelModal(false)} sx={{ cursor: "pointer" }} />
                </Stack>
                <Stack mb={2} alignItems="center">
                    <Warning color="warning"/>
                </Stack>
                <Stack p={2} borderRadius={2} textAlign="center" bgcolor={colors.primary[500]}>
                    <Typography>Booking id</Typography>
                    <Typography variant="h5" fontWeight="bold">
                        {bookingDetails?.booking_id}
                    </Typography>
                </Stack>
                <Stack mt={2} direction="row" spacing={2}>
                    <Button fullWidth variant="contained" onClick={() => setCancelModal(false)}>
                        <b>Close</b>
                    </Button>
                    <Button fullWidth variant="outlined" onClick={handleCancelBtn}>
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Cancel booking</b>}
                    </Button>
                </Stack>
            </Box>
        </>
    );
}

export default CancelBookingModal;
