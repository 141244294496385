import { post } from "../../services";
import { 
    ACTIVATE_AGENT,
    ACTIVATE_SUB_AGENT,
    ADD_NEW_AGENT, 
    ADD_NEW_SUB_AGENT, 
    LIST_AGENT_DETAILS, 
    LIST_ALL_HOTEL_AGENTS, 
    UPDATE_AGENT
} from "../../graphql";
import { 
    RESET_AGENT_DETAILS, 
    RESET_ALL_HOTEL_AGENTS, 
    RESET_ALL_SUB_AGENTS_FOR_DROPDOWN, 
    SAVE_AGENT_DETAILS, 
    SAVE_ALL_HOTEL_AGENTS, 
    SAVE_ALL_SUB_AGENTS_FOR_DROPDOWN, 
    TOGGLE_LOADING 
} from "../types";

export const listAllHotelAgents = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            agents: { pageSize, pageNumber, list },
        } = getState().agents;

        let apiResponse = await post("", {
            query: LIST_ALL_HOTEL_AGENTS,
            variables: {
                pageSize: requestData?.isForDropdown ? 100 : pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_hotel_agents: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            // console.log(apiResponse);
            dispatch({
                type: SAVE_ALL_HOTEL_AGENTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHotelAgents = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HOTEL_AGENTS });
    };
};

export const addNewAgent = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_AGENT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                add_new_agent: { _id },
            } = apiResponse.data.data;
            await dispatch(resetAllHotelAgents());
            await dispatch(listAllHotelAgents());
            return _id;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAgentDetails = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            agentDetails: { pageSize, pageNumber, list },
        } = getState().agents;

        let apiResponse = await post("", {
            query: LIST_AGENT_DETAILS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_agent_details: { data, details, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_AGENT_DETAILS,
                payload: {
                    details: details,
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAgentDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_AGENT_DETAILS });
    };
};

export const getSubAgentsForDropdown = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch(resetSubAgentsDropdownList());

        let apiResponse = await post("", {
            query: LIST_AGENT_DETAILS,
            variables: {
                pageSize: 100,
                isForDropdown: true,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_agent_details: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_ALL_SUB_AGENTS_FOR_DROPDOWN,
                payload: data,
            });

            dispatch({ type: TOGGLE_LOADING, payload: false });
            return data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetSubAgentsDropdownList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_SUB_AGENTS_FOR_DROPDOWN });
    };
};

export const addNewSubAgent = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_SUB_AGENT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                add_new_sub_agent: { _id },
            } = apiResponse.data.data;
            dispatch(resetAgentDetails());
            dispatch(listAgentDetails({ id: requestData.agentId }));
            await dispatch(getSubAgentsForDropdown({ id: requestData.agentId }));
            return _id;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateAgent = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_AGENT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelAgents());
            dispatch(listAllHotelAgents());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateSubAgent = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_SUB_AGENT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAgentDetails());
            dispatch(listAgentDetails({ id: requestData.agentId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateAgent = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_AGENT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelAgents());
            dispatch(listAllHotelAgents());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};