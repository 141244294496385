/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { 
    getRegistrationStatus, listAllRoomsReports, listHotelProfile, loginWithOtp, 
    loginWithPassword, sendOtp, toggleLoading 
} from "../../store/actions";
import { Box, Button, Card, Container, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import LoginWithPassword from "./LoginWithPassword";
import LoginWithOtp from "./LoginWithOtp";
import SendOtp from "./SendOtp";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [page, setPage] = useState(1);

    useEffect(() => {
        localStorage.clear();
        dispatch(toggleLoading(false));
    }, []);

    const handleSendOtp = async (value) => {
        let result = await dispatch(sendOtp(value));
        if (result) {
            toast.success("OTP sent successfully");
            setEmail(value.email);
        }
    };

    const handleLogin = async (values) => {
        let status = await dispatch(
            email && page === 1
                ? loginWithOtp({ email: email, otp: values.otp })
                : loginWithPassword(values)
        );
        if (status === true) {
            localStorage.clear();
            let regStatus = await dispatch(getRegistrationStatus());
            dispatch(listAllRoomsReports());
            await dispatch(listHotelProfile());
            navigate(regStatus ? "/dashboard" : "/registration");
        }
    };

    return (
        <Container>
            <Box width="100%" height="100vh" sx={{ display: "flex", alignItems: "center" }}>
                <Grid container justifyContent="center">
                    <Grid item lg={5} md={6} sm={8} xs={12}>
                        <Card sx={{ m: 1, bgcolor: "transparent !important" }}>
                            {page === 1 && (
                                <>
                                    {email === "" ? (
                                        <SendOtp onSubmit={handleSendOtp} />
                                    ) : (
                                        <LoginWithOtp onSubmit={handleLogin} />
                                    )}
                                </>
                            )}
                            {page === 2 && (
                                <LoginWithPassword onSubmit={handleLogin} />
                            )}
                        </Card>
                        <Stack alignItems="center">
                            <Button
                                size="small"
                                sx={{ letterSpacing: 1, borderRadius: 20, pl: 5, pr: 5 }}
                                onClick={() => setPage(page === 1 ? 2 : 1)}
                            >
                                {page === 1 && <b>Login with password</b>}
                                {page === 2 && <b>Login with OTP</b>}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default Login;
