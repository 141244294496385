/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import { activateRoomtypeOffer, addRoomtypeOffer, listAllRoomtypeOffers, resetAllRoomtypeOffers, syncRoomtypeOffer } from '../../store/actions';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, CircularProgress, InputAdornment, MenuItem, Modal, Stack, Typography } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { DataGrid, GridFooterContainer } from '@mui/x-data-grid';
import { tableStyle } from '../../components';
import { Add, CalendarMonth, Sync } from '@mui/icons-material';
import Header from '../../components/Header';
import moment from 'moment';
import AvailabilityUpdation from '../../components/AvailabilityUpdation';
import ButtonWithConfirmModal from '../../components/ButtonWithConfirmModal';

const startIcon = {
    startAdornment: (
        <InputAdornment position="start">
            <CalendarMonth fontSize='small' sx={{ cursor: "pointer" }} />
        </InputAdornment>
    ),
}

function RoomtypeOffers({ roomtypeId, dispatch, colors }) {
    const initialValues = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD"),
        fixedRate: 0,
        percentage: 0,
        standardRate: 0,
        childRate: 0,
        singleOccupancyRate: 0,
        maxOccupancyRate: 0,
        extraBedRate: 0,
    }
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [values, setValues] = useState(initialValues);
    const [temp, setTemp] = useState("");
    const [weekdays, setSelectedWeekdays] = useState([]);
    const [isRateFixed, setIsRateFixed] = useState(true);
    const [isRateIncrement, setIsRateIncrement] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const { isLoading, offersList, showViewMore } = useSelector(
        ({
            loading,
            roomtypes: { offers: { list, showViewMore } }
        }) => ({
            isLoading: loading,
            offersList: list,
            showViewMore
        })
    );
    // console.log(offersList);
    useEffect(() => {
        dispatch(resetAllRoomtypeOffers());
        fetchMoreData();
        // eslint-disable-next-line
    }, [])

    const fetchMoreData = () => {
        dispatch(listAllRoomtypeOffers({
            roomtypeId: roomtypeId
        }));
      }
    
      const CustomFooter = () => {
        return (
          <GridFooterContainer>
            <Stack width="100%" alignItems="center">
              {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
                <b>view more</b>
              </Button> : <Typography variant="h6">All datas listed</Typography>}
            </Stack>
          </GridFooterContainer>
        );
      }

    const columns = [
        {
            field: "start_date",
            headerName: "Start date",
            width: 140,
            cellClassName: "name-column--cell",
            valueGetter: (params) => `${params.row.start_date ? moment.unix(params.row.start_date).format("DD-MMM-YYYY") : "-"}`,
        },
        {
            field: "end_date",
            headerName: "End date",
            width: 140,
            cellClassName: "name-column--cell",
            valueGetter: (params) => `${params.row.end_date ? moment.unix(params.row.end_date).format("DD-MMM-YYYY") : "-"}`,
        },
        {
            field: "weekdays",
            headerName: "Weekdays",
            width: 150,
            renderCell: ({ row: { weekdays } }) => {
                if (weekdays?.length) {
                    return (
                        <>
                            <CssTextField
                                fullWidth
                                select
                                size="small"
                                // label="Weekdays"
                                value={weekdays}
                                SelectProps={{ multiple: true }}
                            >
                                {weekdays?.map((day, i) => (
                                    <MenuItem value={day} key={i}>
                                        <Checkbox sx={{ p: "0 5px" }} checked={weekdays.indexOf(day) > -1} />
                                        {day}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </>
                    );
                } else {
                    return "-";
                }
            },
        },
        // {
        //     field: "is_rate_fixed",
        //     headerName: "Rate",
        //     width: 200,
        //     renderCell: ({ row: { is_rate_fixed, fixed_rate } }) => {
        //         return (
        //             `${is_rate_fixed ? fixed_rate : "not fixed"}`
        //         );
        //     },
        // },
        { field: "standard_rate", headerName: "Double occupancy rate", width: 120 },
        { field: "max_occupancy_rate", headerName: "Triple occupancy rate", width: 120 },
        { field: "single_occupancy_rate", headerName: "Single occupancy rate", width: 120 },
        { field: "extra_bed_rate", headerName: "Extra bed rate", width: 100 },
        { field: "child_rate", headerName: "Child rate", width: 90 },
        {
            field: "is_active",
            headerName: "Availability",
            width: 100,
            renderCell: ({ row: { is_active, _id } }) => {
                return (
                    <AvailabilityUpdation
                        isActive={is_active}
                        action={() => dispatch(activateRoomtypeOffer({ id: _id, roomtypeId: roomtypeId }))}
                    />
                );
            },
        },
        {
            field: "_id",
            headerName: "Sync",
            width: 130,
            renderCell: ({ row: { _id } }) => {
                return (
                    <ButtonWithConfirmModal
                        BtnLabel="Sync"
                        text="Are you sure to sync ?"
                        action={() => dispatch(syncRoomtypeOffer({ id: _id, roomtypeId: roomtypeId }))}
                        btnIcon={<Sync />}
                    />
                );
            },
        },
        {
            field: "last_sync",
            headerName: "Last sync",
            width: 180,
            renderCell: ({ row: { last_sync } }) => last_sync ? moment.unix(last_sync).format("DD-MMM-YYYY - HH:mm") : "-",
        },
        {
            field: "sync_status",
            headerName: "Sync status",
            width: 120,
            renderCell: ({ row: { sync_status } }) => sync_status ? sync_status : "-",
        },
        // {
        //     field: "is_rate_increment",
        //     headerName: "Increment",
        //     width: 150,
        //     renderCell: ({ row: { is_rate_fixed, is_rate_increment, percentage } }) => {
        //         return (
        //             `${!is_rate_fixed && is_rate_increment ? `${percentage} %` : "-"}`
        //         );
        //     },
        // },
        // {
        //     field: "percentage",
        //     headerName: "Decrement",
        //     width: 150,
        //     renderCell: ({ row: { is_rate_fixed, is_rate_increment, percentage } }) => {
        //         return (
        //             `${!is_rate_fixed && !is_rate_increment ? `${percentage} %` : "-"}`
        //         );
        //     },
        // },
    ];

    const handleChange = (event) => {
        let fieldname = event?.target?.name;
        let data = values;
        data[fieldname] = event?.target?.value;
        setTemp(event.target.value);
        setValues(data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            ...values,
            startDate: moment(values.startDate, "YYYY-MM-DD").unix(),
            endDate: moment(values.endDate, "YYYY-MM-DD").unix(),
            type: weekdays?.length ? "weekdays" : "seasonal",
            roomtypeId,
            isRateFixed,
            isRateIncrement,
            weekdays,
        }
        if(!isRateFixed){
            data.fixedRate = 0;
        } else {
            data.percentage = 0;
        }
        console.log(data);
        dispatch(addRoomtypeOffer(data))
        handleClose();
    }

    return (
        <Box mb={15}>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"end"}
                m="20px 0"
            >
                <Typography variant='h4' fontWeight="bold">
                    RATES
                </Typography>
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: { sm: "10px 20px", xs: "5px 10px" },
                        }}
                        onClick={handleOpen}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Add new rate
                    </Button>
                </Box>
            </Box>
            <Box
                m="8px 0 0 0"
                height="350px"
                sx={tableStyle(colors)}
            >
                <DataGrid
                    rows={offersList}
                    columns={columns}
                    components={{ Footer: CustomFooter }}
                    getRowId={(row) => row?._id}
                />
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={modalStyle}>
                    <Header title="ADD NEW RATE" titleVariant="h4" />
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="date"
                                label="start date"
                                placeholder="Start date"
                                name="startDate"
                                value={moment(values.startDate).format("YYYY-MM-DD")}
                                inputProps={{ min: moment().format("YYYY-MM-DD") }}
                                onChange={handleChange}
                                inputRef={startInputRef}
                                onClick={() => startInputRef.current.showPicker()}
                                InputProps={startIcon}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="date"
                                label="End date"
                                placeholder="End date"
                                name="endDate"
                                value={moment(values.endDate).format("YYYY-MM-DD")}
                                inputProps={{ min: moment(values.startDate).format("YYYY-MM-DD") }}
                                onChange={handleChange}
                                inputRef={endInputRef}
                                onClick={() => endInputRef.current.showPicker()}
                                InputProps={startIcon}
                            />
                            {/* <Stack direction="row" alignItems="center">
                                <Typography noWrap width={"50%"}>Fixed rate</Typography>
                                <Checkbox
                                    checked={isRateFixed}
                                    onChange={(e) => setIsRateFixed(e.target.checked)}
                                />
                                <CssTextField
                                    fullWidth
                                    required
                                    size="small"
                                    type="number"
                                    label="Rate"
                                    placeholder="Rate"
                                    name="fixedRate"
                                    disabled={!isRateFixed}
                                    value={values?.fixedRate || ""}
                                    onChange={handleChange}
                                />
                            </Stack> */}
                            <CssTextField
                                fullWidth
                                select
                                size="small"
                                label="Weekdays"
                                value={weekdays}
                                SelectProps={{ multiple: true }}
                                onChange={(e) => setSelectedWeekdays(e.target.value)}
                            >
                                {moment?.weekdays()?.map((day, i) => (
                                    <MenuItem value={day} key={i}>
                                        <Checkbox sx={{ p: "0 5px" }} checked={weekdays.indexOf(day) > -1} />
                                        {day}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Double occupancy rate"
                                placeholder="Double occupancy rate"
                                name="standardRate"
                                value={values?.standardRate || ""}
                                onChange={handleChange}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Triple occupancy rate"
                                placeholder="Triple occupancy rate"
                                name="maxOccupancyRate"
                                value={values?.maxOccupancyRate || ""}
                                onChange={handleChange}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Single occupancy rate"
                                placeholder="Single occupancy rate"
                                name="singleOccupancyRate"
                                value={values?.singleOccupancyRate || ""}
                                onChange={handleChange}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Extra bed rate"
                                placeholder="Extra bed rate"
                                name="extraBedRate"
                                value={values?.extraBedRate || ""}
                                onChange={handleChange}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Child rate"
                                placeholder="Child rate"
                                name="childRate"
                                value={values?.childRate || ""}
                                onChange={handleChange}
                            />
                            {/* <Stack direction="row" alignItems="center">
                                <Typography noWrap>Increment</Typography>
                                <Checkbox
                                    checked={isRateIncrement}
                                    disabled={isRateFixed}
                                    onChange={(e) => setIsRateIncrement(e.target.checked)}
                                />
                                <Typography noWrap>Decrement</Typography>
                                <Checkbox
                                    checked={!isRateIncrement}
                                    disabled={isRateFixed}
                                    onChange={(e) => setIsRateIncrement(!e.target.checked)}
                                />
                            </Stack>
                            <CssTextField
                                fullWidth
                                required={!isRateFixed}
                                size="small"
                                type="number"
                                label="Percentage"
                                placeholder="Percentage"
                                name="percentage"
                                disabled={isRateFixed}
                                value={values?.percentage || ""}
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                onChange={handleChange}
                            /> */}
                            
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{ fontSize: "15px" }}
                            >
                                {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </Box>
    )
}

export default RoomtypeOffers;