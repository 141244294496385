import React, { useEffect } from "react";
import { ArrowBack, Check, Close } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { listHotsoftUpdateDetails, resetHotsoftUpdateDetails } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import moment from "moment";

function HotsoftUpdateDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { hotsoftUpdateDetails } = useSelector(
        ({
            hotsoft: { hotsoftUpdateDetails },
        }) => ({
            hotsoftUpdateDetails,
        })
    );
// console.log(hotsoftUpdateDetails);
    useEffect(() => {
        document.title = 'Hotsoft Update Details';
        dispatch(resetHotsoftUpdateDetails());
        fetchMoreData();
        // eslint-disable-next-line
    }, [dispatch, id]);

    const fetchMoreData = () => {
        dispatch(listHotsoftUpdateDetails({ id: id }));
    }

    const KeyAndValue = ({ keyName, value, render }) => (
        <Grid item md={6} xs={12}>
            <Stack direction="row" spacing={2}>
                <Typography width={"40%"}>{keyName}:</Typography>
                {value ? 
                <Typography>
                    {value}
                </Typography>
                : render }
            </Stack>
        </Grid>
    )

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                spacing={2}
                m="20px 0"
            >
                <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </Button>
                <Typography fontWeight="bold" variant="h4">
                    HOTSOFT UPDATE DETAILS
                </Typography>
                <Box width={40} />
            </Stack>
            <Box
                width="100%"
                p={2}
                borderRadius={1}
                backgroundColor={colors.primary[400]}
            >
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <KeyAndValue keyName={"Start date"} value={hotsoftUpdateDetails?.start_date || "-"}/>
                    <KeyAndValue keyName={"End date"} value={hotsoftUpdateDetails?.end_date || "-"}/>
                    <KeyAndValue keyName={"Hotsoft count"} value={hotsoftUpdateDetails?.avail_count ?? "-"}/>
                    <KeyAndValue keyName={"Stop sell"} value={hotsoftUpdateDetails?.stop_sell ? <Check color="success"/> : <Close color="error"/>}/>
                    <KeyAndValue keyName={"Travelnet count"} value={hotsoftUpdateDetails?.cron_avail_count ?? "-"}/>
                    <KeyAndValue keyName={"Travelnet updated at"} value={hotsoftUpdateDetails?.cron_updated_at ? moment.unix(hotsoftUpdateDetails?.cron_updated_at).format("DD-MM-YYYY") : "-"}/>
                    <KeyAndValue keyName={"Created at"} value={hotsoftUpdateDetails?.created_at ? moment.unix(hotsoftUpdateDetails?.created_at).format("DD-MM-YYYY") : "-"}/>
                    <KeyAndValue keyName={"Updated at"} value={hotsoftUpdateDetails?.updated_at ? moment.unix(hotsoftUpdateDetails?.updated_at).format("DD-MM-YYYY") : "-"}/>
                    <KeyAndValue keyName={"Hotsoft hotel id"} value={hotsoftUpdateDetails?.hotsoft_hotel_id || "-"}/>
                    <KeyAndValue keyName={"Hotsoft room id"} value={hotsoftUpdateDetails?.hotsoft_room_id || "-"}/>
                    <KeyAndValue keyName={"Roomtype"} value={hotsoftUpdateDetails?.roomtype_id?.name || "-"}/>
                </Grid>
            </Box>
        </Box>
    );
}

export default HotsoftUpdateDetails;
