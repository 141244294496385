export const ADD_NEW_REVENUE_INPUT = `mutation ($totalRevenue: Float!, $data: [add_new_revenue_input_data]) {
  add_new_revenue_input(total_revenue: $totalRevenue, data: $data){
    message
  }
}`;

export const LIST_HOTEL_REVENUE_INPUTS = `query{
  list_hotel_revenue_inputs{
    message
    data {
      _id
      total_revenue
      data {
        month
        amount
      }
    }
  }
}`;

export const LIST_GENERATED_REVENUE = `query ($year: String) {
  list_generated_revenue(year: $year){
    message
    data {
      total_expected_revenue
      total_generated_revenue
      data {
        _id
        month
        expected_revenue
        generated_revenue
        is_expecation_meets
      }
    } 
  }
}`;