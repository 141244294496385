import React, { useEffect, useRef, useState } from "react";
import { Box, Button, InputAdornment, Stack, TextField, useTheme } from "@mui/material";
import { listAllInventorySchedules, resetAllInventorySchedules } from "../../store/actions";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { ArrowForward, CalendarMonth, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PaginationFooter, tableStyle } from "../../components";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";

const InventorySchedules = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const { invSchedulesList, pageNumber, pageSize, count } = useSelector(
        ({
            loading,
            calendar: {
                inventorySchedules: { list, pageNumber, pageSize, count },
            },
        }) => ({
            isLoading: loading,
            invSchedulesList: list,
            pageNumber,
            pageSize,
            count,
        })
    );
    // console.log(invSchedulesList);
    useEffect(() => {
        document.title = "Inventory schedules";
        dispatch(resetAllInventorySchedules());
        fetchMoreData();
        // eslint-disable-next-line
    }, [dispatch]);

    const fetchMoreData = (page) => {
        let req = startDate && endDate ? 
        { 
            ...page, 
            startDate: moment(startDate, "YYYY-MM-DD").startOf("day").unix(),
            endDate: moment(endDate, "YYYY-MM-DD").endOf("day").unix(),
        } 
        : page;
        dispatch(listAllInventorySchedules(req));
    };

    const CustomFooter = () => {
        return (
            <PaginationFooter
                count={count}
                pageSize={pageSize}
                pageNumber={pageNumber}
                fetchMoreData={(page) => fetchMoreData(page)}
            />
        );
    }

    const handleFilterBtn = () => {
        if (startDate && endDate) {
            dispatch(resetAllInventorySchedules());
            dispatch(listAllInventorySchedules({
                startDate: moment(startDate, "YYYY-MM-DD").startOf("day").unix(),
                endDate: moment(endDate, "YYYY-MM-DD").endOf("day").unix(),
            }));
        }
    }

    const columns = [
        // {
        //     field: '' , 
        //     headerName: 'no.',
        //     renderCell:(index) => index.api.getRowIndex(index.row._id) + 1,
        //     width: 20,
        // },
        {
            field: "start_date",
            headerName: "Start date",
            width: 150,
        },
        {
            field: "end_date",
            headerName: "End date",
            width: 150,
        },
        {
            field: "roomtype_id",
            headerName: "Roomtype",
            width: 150,
            renderCell: ({ row: { roomtype_id: { name } } }) => `${name}`,
        },
        { field: "rooms_available", headerName: "Rooms available", width: 150 },
        { field: "ota", headerName: "OTA", width: 150 },
        { field: "status", headerName: "Status", width: 150 },
        {
            field: "_id",
            headerName: "View details",
            width: 150,
            renderCell: ({ row: { _id } }) => {
                return (
                    <Button
                        size="small"
                        variant="contained"
                        onClick={async () => {
                            navigate(`/inventory-schedule-details/${_id}`);
                        }}
                    >
                        <ArrowForward fontSize="small" />
                    </Button>
                );
            },
        },
    ];

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={{ sm: "row", xs: "column" }}
                justifyContent={{ sm: "space-between", xs: "start" }}
                alignItems={{ sm: "center", xs: "start" }}
                m="20px 0"
            >
                <Header title="INVENTORY SCHEDULES" subtitle="welcome to inventory schedules" />
            </Stack>
            <Stack
                direction={"row"}
                justifyContent={{ md: "flex-end", xs: "flex-start" }}
                spacing={1}
            >
                <TextField
                    fullWidth
                    size="small"
                    type="date"
                    label="Start Date"
                    placeholder="Start Date"
                    sx={{ width: 170 }}
                    inputRef={startInputRef}
                    onClick={() => startInputRef.current.showPicker()}
                    value={startDate || ""}
                    onChange={(newValue) => {
                        setStartDate(newValue.target.value)
                        if (endDate && moment(newValue.target.value).add(1, "days").format("YYYY-MM-DD") > endDate) {
                            setEndDate(moment(newValue.target.value).add(1, "days").format("YYYY-MM-DD"));
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CalendarMonth />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    fullWidth
                    size="small"
                    type="date"
                    label="End Date"
                    placeholder="End Date"
                    sx={{ width: 170 }}
                    inputRef={endInputRef}
                    onClick={() => endInputRef.current.showPicker()}
                    inputProps={{ min: startDate }}
                    value={endDate || ""}
                    onChange={(newValue) => setEndDate(newValue.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CalendarMonth />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    size="small"
                    variant="outlined"
                    onClick={handleFilterBtn}
                >
                    <Search />
                </Button>
            </Stack>
            <Box m="8px 0 0 0" height="68vh" sx={tableStyle(colors)}>
                <DataGrid
                    rows={invSchedulesList}
                    columns={columns}
                    components={{ Footer: CustomFooter }}
                    getRowId={(row) => row?._id}
                />
            </Box>
        </Box>
    );
};

export default InventorySchedules;
