/*eslint-disable*/
import React, { useRef, useState } from 'react';
import { addRoomtypeOffer } from '../../../store/actions';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, CircularProgress, Divider, IconButton, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { CssTextField } from '../../../components/TextField';
import { Add, ArrowBack, CalendarMonth, Cancel } from '@mui/icons-material';
import moment from 'moment';

const types = [
    { name: "Seasonal", value: "seasonal" },
    { name: "Weekdays", value: "weekdays" },
    { name: "Fixed rate", value: "fixed_rate" },
    { name: "Occupancy based", value: "occupancy_based" },
    { name: "Demand based", value: "demand_based" },
    { name: "Promotional", value: "promotional" },
]

const startIcon = {
    startAdornment: (
        <InputAdornment position="start">
            <CalendarMonth fontSize='small' sx={{ cursor: "pointer" }} />
        </InputAdornment>
    ),
}

function AddNewRate({ dispatch, colors, roomtypeId, setIsAdding }) {
    const initialValues = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD"),
        type: "seasonal",
        fixedRate: 0,
        percentage: 0,
        standardRate: 0,
        childRate: 0,
        singleOccupancyRate: 0,
        maxOccupancyRate: 0,
        extraBedRate: 0,
        numOfDays: 0,
    }
    const initialPerc = {
        percentage: "",
        standard_rate: "",
        child_rate: "",
        single_occupancy_rate: "",
        max_occupancy_rate: "",
        extra_bed_rate: "",
    }
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [values, setValues] = useState(initialValues);
    const [percentages, setPercentages] = useState([initialPerc]);
    const [temp, setTemp] = useState("");
    const [weekdays, setSelectedWeekdays] = useState([]);
    const [isRateFixed, setIsRateFixed] = useState(false);
    const [isRateIncrement, setIsRateIncrement] = useState(false);

    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));
    // console.log(offersList);

    const handleChange = (event) => {
        let fieldname = event?.target?.name;
        let data = values;
        data[fieldname] = event?.target?.value;
        setTemp(event.target.value);
        setValues(data);
    }
    const handleNewFieldBtn = () => {
        var joined = percentages.concat(initialPerc);
        setPercentages(joined)
    }
    const removeField = (index) => {
        if (percentages?.length > 1) {
            var arr = percentages;
            var filtered = arr.filter((e, i) => i !== index);
            setPercentages(filtered)
        }
    }
    const handleNewFieldValueChange = (i, event) => {
        var valueAdd = percentages;
        const field = event.target.name;
        if (event?.target) {
            valueAdd[i][field] = event.target.value;
        } else {
            valueAdd[i][field] = event
        }
        setTemp(event.target.value);
        setPercentages(valueAdd)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            ...values,
            startDate: moment(values.startDate, "YYYY-MM-DD").unix(),
            endDate: moment(values.endDate, "YYYY-MM-DD").unix(),
            roomtypeId,
            isRateFixed,
            isRateIncrement,
            weekdays,
        }
        if (!["seasonal", "weekdays", "fixed_rate"].includes(values?.type)) {
            data.percentages = percentages;
        }
        if (!isRateFixed) {
            data.fixedRate = 0;
        } else {
            data.percentage = 0;
        }
        console.log(data);
        dispatch(addRoomtypeOffer(data))
        setValues(initialValues);
        setIsAdding(false);
    }

    return (
        <Box>
            <Stack
                direction={"row"}
                alignItems={"end"}
                spacing={1}
                mb={3}
            >
                <Button size='small' variant='outlined' onClick={() => {
                    setIsAdding(false)
                }}>
                    <ArrowBack fontSize='small' />
                </Button>
                <Typography color={colors.grey[100]} fontWeight="bold" variant='h4'>
                    ADD NEW RATE
                </Typography>
            </Stack>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <CssTextField
                            fullWidth
                            required
                            size="small"
                            type="date"
                            label="start date"
                            placeholder="Start date"
                            name="startDate"
                            value={moment(values.startDate).format("YYYY-MM-DD")}
                            inputProps={{ min: moment().format("YYYY-MM-DD") }}
                            onChange={handleChange}
                            inputRef={startInputRef}
                            onClick={() => startInputRef.current.showPicker()}
                            InputProps={startIcon}
                        />
                        <CssTextField
                            fullWidth
                            required
                            size="small"
                            type="date"
                            label="End date"
                            placeholder="End date"
                            name="endDate"
                            value={moment(values.endDate).format("YYYY-MM-DD")}
                            inputProps={{ min: moment(values.startDate).format("YYYY-MM-DD") }}
                            onChange={handleChange}
                            inputRef={endInputRef}
                            onClick={() => endInputRef.current.showPicker()}
                            InputProps={startIcon}
                        />
                    </Stack>
                    <CssTextField
                        fullWidth
                        select
                        size="small"
                        label="Rate type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                    >
                        {types?.map((type) => (
                            <MenuItem value={type.value} key={type.value}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </CssTextField>
                    <Divider />
                    {values?.type === "weekdays" ? (
                        <CssTextField
                            fullWidth
                            select
                            size="small"
                            label="Weekdays"
                            value={weekdays}
                            SelectProps={{ multiple: true }}
                            onChange={(e) => setSelectedWeekdays(e.target.value)}
                        >
                            {moment?.weekdays()?.map((day, i) => (
                                <MenuItem value={day} key={i}>
                                    <Checkbox sx={{ p: "0 5px" }} checked={weekdays.indexOf(day) > -1} />
                                    {day}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    ) : null}
                    {values?.type === "occupancy_based" ? (
                        <CssTextField
                            fullWidth
                            required
                            size="small"
                            type="number"
                            label="Rate starts before (number of days)"
                            placeholder="Rate starts before (number of days)"
                            name="numOfDays"
                            value={values?.numOfDays || ""}
                            onChange={handleChange}
                            inputProps={{ min: 0 }}
                        />
                    ) : null}
                    {["seasonal", "weekdays", "fixed_rate"].includes(values?.type) ?
                        <>
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Double occupancy rate"
                                placeholder="Double occupancy rate"
                                name="standardRate"
                                value={values?.standardRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Triple occupancy rate"
                                placeholder="Triple occupancy rate"
                                name="maxOccupancyRate"
                                value={values?.maxOccupancyRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Single occupancy rate"
                                placeholder="Single occupancy rate"
                                name="singleOccupancyRate"
                                value={values?.singleOccupancyRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Extra bed rate"
                                placeholder="Extra bed rate"
                                name="extraBedRate"
                                value={values?.extraBedRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Child rate"
                                placeholder="Child rate"
                                name="childRate"
                                value={values?.childRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                        </>
                        : (
                            <>
                                {percentages?.map((el, idx) => (
                                    <Stack
                                        key={idx}
                                        p={2}
                                        spacing={2}
                                        borderRadius={2}
                                        bgcolor={colors.primary[400]}
                                    >
                                        <Stack alignItems="end" m={-1}>
                                            <IconButton size='small' onClick={() => removeField(idx)}>
                                                <Cancel />
                                            </IconButton>
                                        </Stack>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Percentage"
                                            placeholder="Percentage"
                                            name="percentage"
                                            value={el?.percentage ?? ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0, max: 100 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Double occupancy rate"
                                            placeholder="Double occupancy rate"
                                            name="standard_rate"
                                            value={el?.standard_rate ?? ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Triple occupancy rate"
                                            placeholder="Triple occupancy rate"
                                            name="max_occupancy_rate"
                                            value={el?.max_occupancy_rate ?? ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Single occupancy rate"
                                            placeholder="Single occupancy rate"
                                            name="single_occupancy_rate"
                                            value={el?.single_occupancy_rate || ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Extra bed rate"
                                            placeholder="Extra bed rate"
                                            name="extra_bed_rate"
                                            value={el?.extra_bed_rate || ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Child rate"
                                            placeholder="Child rate"
                                            name="child_rate"
                                            value={el?.child_rate || ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Stack>
                                ))}
                                <Button fullWidth size='small' onClick={handleNewFieldBtn}>
                                    <Add /> <b>Add new percentage</b>
                                </Button>
                            </>
                        )}
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Button variant="outlined" onClick={() => setIsAdding(false)}>
                            <b>Cancel</b>
                        </Button>
                        <Button type="submit" variant="contained">
                            {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default AddNewRate;