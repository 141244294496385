import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Divider, Grid, Link, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Stack, Typography, useTheme } from "@mui/material";
import { listHotelProfile, resetHotelProfile, updateHotelProfile } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import BasicInfo from "../registration/basicInfo";
import PropertyInfo from "../registration/propertyInfo";
import { Close, Edit, Key, KeyboardArrowDown } from "@mui/icons-material";
import ChangePassword from "./changePassword";

function Profile() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [updation, setUpdation] = useState(false);
    const [basicInfoData, setBasicInfoData] = useState({});
    const [propInfoData, setPropInfoData] = useState({});
    const [contactData, setContactData] = useState({ name: "" });
    
    const [showModdal, setShowModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const { isLoading, hotelProfile } = useSelector(({ loading, registration: { hotelProfile } }) => ({
        isLoading: loading,
        hotelProfile,
    }));
    const {
        title,
        first_name,
        last_name,
        email,
        address1,
        address2,
        phone,
        mobile,
        city,
        country,
        state,
        postal_code,
        fax,
        property,
        slug,
        logo,
        hotel_img,
        cancel_policy,
        min_child_age,
        max_child_age,
        contact_person,
        gstin,
        bank_details,
        bke_discount,
    } = hotelProfile;

    useEffect(() => {
        document.title = "Profile";
        dispatch(resetHotelProfile());
        dispatch(listHotelProfile());
        setBasicInfoData(hotelProfile);
        if(hotelProfile?.property){
            setPropInfoData(hotelProfile?.property);
        }
        if(hotelProfile?.contact_person){
            setContactData(hotelProfile?.contact_person);
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const handleProfileSubmit = async () => {
        console.log(basicInfoData);
        dispatch(updateHotelProfile(basicInfoData));
    }

    const handlePropertySubmit = async () => {
        if (propInfoData?.type === null) {
            propInfoData.type = "Hotel";
        }
        if (propInfoData?.country === null) {
            propInfoData.country = "IN"
        }
        if (propInfoData?.state === null) {
            propInfoData.state = "Kerala"
        }
        let data = {
            property: propInfoData,
            contact_person: contactData
        };
        dispatch(updateHotelProfile(data));
        handleProfileSubmit();
    };

    const FieldAndValue = ({ field, value, sx = {} }) => (
        <Stack direction="row" spacing={2} sx={sx}>
            <Typography width={"40%"}>{field}:</Typography>
            <Typography>{value}</Typography>
        </Stack>
    );

    return (
        <Box m="20px" mb={15}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Header title="PROFILE" subtitle="welcome to Profile" />
                <Button
                    size="small"
                    variant="outlined"
                    endIcon={updation ? <Close /> : <KeyboardArrowDown />}
                    onClick={updation ? () => setUpdation(false) : handleMenuClick}
                >
                    {updation ? <b>Close</b> : <b>Options</b>}
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setUpdation(true)
                            handleMenuClose()
                        }}
                    >
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Update Profile</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setShowModal(true)
                            handleMenuClose()
                        }}
                    >
                        <ListItemIcon>
                            <Key fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Change Password</ListItemText>
                    </MenuItem>
                </Menu>
            </Stack>
            <Box width="100%" p={2} borderRadius={1} backgroundColor={colors.primary[400]}>
                {updation ? (
                    <Stack spacing={2}>
                        <Typography variant="h4" fontWeight="bold" color={colors.greenAccent[500]}>
                            USER INFO
                        </Typography>
                        <BasicInfo
                            basicInfoData={basicInfoData}
                            setBasicInfoData={setBasicInfoData}
                            handleProfileSubmit={handleProfileSubmit}
                        />
                        <br />
                        <Typography variant="h4" fontWeight="bold" color={colors.greenAccent[500]}>
                            PROPERTY INFO
                        </Typography>
                        <PropertyInfo
                            propInfoData={propInfoData}
                            setPropInfoData={setPropInfoData}
                            contactData={contactData}
                            setContactData={setContactData}
                            basicInfoData={basicInfoData}
                            setBasicInfoData={setBasicInfoData}
                            handlePropertySubmit={handlePropertySubmit}
                        />
                    </Stack>
                ) : (
                    <Grid container spacing={{ md: 4, xs: 2 }}>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={2}>
                                <Typography variant="h4" fontWeight="bold" color={colors.greenAccent[500]}>
                                    USER INFO
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    <Typography width={"40%"}>Name:</Typography>
                                    <Typography sx={{textTransform: "capitalize"}}>
                                        {title || ""} {first_name || "-"} {last_name || ""}
                                    </Typography>
                                </Stack>
                                <FieldAndValue field={"Email"} value={email || "-"}/>
                                <FieldAndValue field={"Mobile"} value={mobile || "-"}/>
                                <FieldAndValue field={"Phone"} value={phone || "-"}/>
                                <Stack direction="row" spacing={2}>
                                    <Typography width={"40%"}>Address:</Typography>
                                    <Stack>
                                        <Typography>{address1 || "-"}</Typography>
                                        <Typography>{address2 || ""}</Typography>
                                    </Stack>
                                </Stack>
                                <FieldAndValue field={"City"} value={city || "-"}/>
                                <FieldAndValue field={"State"} value={state || "-"}/>
                                <FieldAndValue field={"Country"} value={country || "-"}/>
                                <FieldAndValue field={"Postal code"} value={postal_code || "-"}/>
                                <FieldAndValue field={"Fax"} value={fax || "-"}/>
                                <Divider />
                                <Typography variant="h4" fontWeight={600} color={colors.greenAccent[600]}>
                                    Account details
                                </Typography>
                                <FieldAndValue field={"A/c hoder name"} value={bank_details?.acc_holder_name || "-"}/>
                                <FieldAndValue field={"A/c no"} value={bank_details?.acc_no || "-"}/>
                                <FieldAndValue field={"IFSC code"} value={bank_details?.ifsc || "-"}/>
                                <FieldAndValue field={"A/c type"} value={bank_details?.acc_type || "-"}/>
                                <FieldAndValue field={"Bank name"} value={bank_details?.bank_name || "-"}/>
                                <FieldAndValue field={"Branch"} value={bank_details?.branch_name || "-"}/>
                                <FieldAndValue field={"GST No"} value={gstin || "-"}/>
                                <Typography variant="h4" fontWeight={600} color={colors.greenAccent[600]}>
                                    Booking engine
                                </Typography>
                                <FieldAndValue field={"Min. child age"} value={min_child_age || "-"}/>
                                <FieldAndValue field={"Max. child age"} value={max_child_age || "-"}/>
                                <FieldAndValue field={"Booking engine discount(%)"} value={bke_discount ? `${bke_discount} %` : "-"}/>
                                <Stack direction="row" spacing={2} >
                                    <Typography width={"40%"}>Url:</Typography>
                                    <Link href={`https://hotels.travelnet.in/booking/${slug}`}>
                                        {`https://hotels.travelnet.in/booking/${slug}` || "-"}
                                    </Link>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={2}>
                                <Typography variant="h4" fontWeight="bold" color={colors.greenAccent[500]}>
                                    PROPERTY INFO
                                </Typography>
                                {logo ? (
                                    <Stack spacing={2}>
                                        <Typography width={"40%"}>Image for email:</Typography>
                                        <Stack>
                                            <img width={"60%"} src={logo} alt="" />
                                        </Stack>
                                    </Stack>
                                ) : null}
                                {hotel_img ? (
                                    <Stack spacing={2}>
                                        <Typography width={"40%"}>Hotel image:</Typography>
                                        <Stack>
                                            <img width={"60%"} src={hotel_img} alt="" />
                                        </Stack>
                                    </Stack>
                                ) : null}
                                <FieldAndValue field={"Name"} value={property?.name || "-"} sx={{textTransform: "capitalize"}}/>
                                <FieldAndValue field={"Type"} value={property?.type || "-"}/>
                                <FieldAndValue field={"Address"} value={property?.address || "-"}/>
                                <FieldAndValue field={"Location"} value={property?.location || "-"}/>
                                <FieldAndValue field={"Finance email"} value={property?.finance_email || "-"}/>
                                <FieldAndValue field={"Finance mobile"} value={property?.finance_mobile || "-"}/>
                                {/* <FieldAndValue field={"Reservation email"} value={property?.reservation_email || "-"}/> */}
                                <Stack direction="row" spacing={2}>
                                    <Typography width={"40%"}>{"Reservation email(s)"}:</Typography>
                                    <Stack alignItems="start">
                                        {property?.reservation_email ?
                                            property?.reservation_email?.split(",")?.map((e) => (
                                                <Chip label={e} key={e} sx={{ mr: 1, mb: 1 }} />
                                            )) 
                                        : "-"}
                                    </Stack>
                                </Stack>
                                <FieldAndValue field={"Reservation mobile"} value={property?.reservation_mobile || "-"}/>
                                <FieldAndValue field={"C-Form Submission Email"} value={property?.c_form_email || "-"}/>
                                <FieldAndValue field={"Land phone"} value={property?.land_phone || "-"}/>
                                {property?.green_hotel?.length ? (
                                    <Stack direction="row" spacing={2}>
                                        <Typography width={"40%"}>Green Hotel:</Typography>
                                        <Stack>
                                            {property?.green_hotel?.map((item) => (
                                                <Typography key={item}>{item}</Typography>
                                            ))}
                                        </Stack>
                                    </Stack>
                                ) : null}
                                <FieldAndValue field={"City"} value={property?.city || "-"}/>
                                <FieldAndValue field={"State"} value={property?.state || "-"}/>
                                <FieldAndValue field={"Country"} value={property?.country || "-"}/>
                                <FieldAndValue field={"Postal code"} value={property?.postal_code || "-"}/>
                                <FieldAndValue field={"Fax"} value={property?.fax || "-"}/>
                            </Stack>
                            <br />
                            <Stack spacing={2}>
                                <Typography variant="h4" fontWeight={600} color={colors.greenAccent[600]}>
                                    Contact Person
                                </Typography>
                                <FieldAndValue field={"Name"} value={contact_person?.name || "-"}/>
                                <FieldAndValue field={"Email"} value={contact_person?.email || "-"}/>
                                <FieldAndValue field={"Mobile"} value={contact_person?.mobile || "-"}/>
                                <FieldAndValue field={"Phone"} value={contact_person?.phone || "-"}/>
                                <FieldAndValue field={"Designation"} value={contact_person?.designation || "-"}/>
                                <FieldAndValue field={"Address"} value={contact_person?.address || "-"}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                                <Stack spacing={2}>
                                    <Divider />
                                    <Stack direction="row" spacing={2}>
                                        <Typography sx={{whiteSpace: "nowrap"}} width={"40%"}>Cancellation policy :</Typography>
                                        <Typography>{cancel_policy || "-"}</Typography>
                                    </Stack>
                                    {/* <FieldAndValue field={"Cancellation policy"} value={cancel_policy || "-"} /> */}
                                </Stack>
                        </Grid>
                    </Grid>
                )}
            </Box>

            <Modal
                open={showModdal}
                onClose={() => setShowModal(false)}
            >
                <Box sx={modalStyle}>
                    <ChangePassword 
                        dispatch={dispatch} 
                        isLoading={isLoading} 
                        setShowModal={setShowModal}
                    />
                </Box>
            </Modal>
        </Box>
    );
}

export default Profile;
