// /*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { activateRoomtypeOffer, listAllRoomTypes, listAllRoomtypeOffers, resetAllRoomtypeOffers, syncRoomtypeOffer } from '../../../store/actions';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { CssTextField } from '../../../components/TextField';
import { DataGrid, GridFooterContainer } from '@mui/x-data-grid';
import { tableStyle } from '../../../components';
import { Add, RoomPreferences, Sync } from '@mui/icons-material';
import moment from 'moment';
import AvailabilityUpdation from '../../../components/AvailabilityUpdation';
import ButtonWithConfirmModal from '../../../components/ButtonWithConfirmModal';
import AddNewRate from './addNewRate';

function RatesInRevManager({ dispatch, colors }) {
    const [roomtypeId, setRoomtypeId] = useState("");
    const [isAdding, setIsAdding] = useState(false);

    const { offersList, showViewMore, roomTypes } = useSelector(
        ({
            roomtypes: { offers: { list, showViewMore } },
            admin: { roomTypes }
        }) => ({
            offersList: list,
            showViewMore,
            roomTypes
        })
    );
    // console.log(offersList);
    useEffect(() => {
        dispatch(listAllRoomTypes({ isForDropdown: true }));
        if (roomTypes?.list?.length) {
            setRoomtypeId(roomTypes?.list[0]?._id)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(resetAllRoomtypeOffers());
        fetchMoreData();
        // eslint-disable-next-line
    }, [roomtypeId])

    const fetchMoreData = () => {
        if (roomtypeId) {
            dispatch(listAllRoomtypeOffers({
                roomtypeId: roomtypeId
            }));
        }
    }

    const CustomFooter = () => {
        return (
            <GridFooterContainer>
                <Stack width="100%" alignItems="center">
                    {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
                        <b>view more</b>
                    </Button> : <Typography variant="h6">All datas listed</Typography>}
                </Stack>
            </GridFooterContainer>
        );
    }
    
    const columns = [
        {
            field: "start_date",
            headerName: "Start date",
            width: 140,
            cellClassName: "name-column--cell",
            valueGetter: (params) => `${params.row.start_date ? moment.unix(params.row.start_date).format("DD-MMM-YYYY") : "-"}`,
        },
        {
            field: "end_date",
            headerName: "End date",
            width: 140,
            cellClassName: "name-column--cell",
            valueGetter: (params) => `${params.row.end_date ? moment.unix(params.row.end_date).format("DD-MMM-YYYY") : "-"}`,
        },
        { 
            field: "type", 
            headerName: "Rate type", 
            width: 120,
            renderCell: ({ row: { type } }) => <Typography textTransform={"capitalize"}>{type ? type?.replace("_", " ") : "-"}</Typography>
        },
        {
            field: "weekdays",
            headerName: "Weekdays",
            width: 150,
            renderCell: ({ row: { weekdays } }) => {
                if (weekdays?.length) {
                    return (
                        <>
                            <CssTextField
                                fullWidth
                                select
                                size="small"
                                // label="Weekdays"
                                value={weekdays}
                                SelectProps={{ multiple: true }}
                            >
                                {weekdays?.map((day, i) => (
                                    <MenuItem value={day} key={i}>
                                        <Checkbox sx={{ p: "0 5px" }} checked={weekdays.indexOf(day) > -1} />
                                        {day}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </>
                    );
                } else {
                    return "-";
                }
            },
        },
        { field: "standard_rate", headerName: "Double occupancy rate", width: 120 },
        { field: "max_occupancy_rate", headerName: "Triple occupancy rate", width: 120 },
        { field: "single_occupancy_rate", headerName: "Single occupancy rate", width: 120 },
        { field: "extra_bed_rate", headerName: "Extra bed rate", width: 100 },
        { field: "child_rate", headerName: "Child rate", width: 90 },
        {
            field: "is_active",
            headerName: "Availability",
            width: 100,
            renderCell: ({ row: { is_active, _id } }) => {
                return (
                    <AvailabilityUpdation
                        isActive={is_active}
                        action={() => dispatch(activateRoomtypeOffer({ id: _id, roomtypeId: roomtypeId }))}
                    />
                );
            },
        },
        {
            field: "_id",
            headerName: "Sync",
            width: 130,
            renderCell: ({ row: { _id } }) => {
                return (
                    <ButtonWithConfirmModal
                        BtnLabel="Sync"
                        text="Are you sure to sync ?"
                        action={() => dispatch(syncRoomtypeOffer({ id: _id, roomtypeId: roomtypeId }))}
                        btnIcon={<Sync />}
                    />
                );
            },
        },
        {
            field: "last_sync",
            headerName: "Last sync",
            width: 180,
            renderCell: ({ row: { last_sync } }) => last_sync ? moment.unix(last_sync).format("DD-MMM-YYYY - HH:mm") : "-",
        },
        {
            field: "sync_status",
            headerName: "Sync status",
            width: 120,
            renderCell: ({ row: { sync_status } }) => sync_status ? sync_status : "-",
        },
    ];

    return (
        <Box mb={15}>
            {!isAdding ? (
                <>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"end"}
                        mb={2}
                    >
                        <CssTextField
                            select
                            size='small'
                            value={roomtypeId || "no_preference"}
                            label="Room Type"
                            sx={{ width: 150 }}
                            onChange={(e) => setRoomtypeId(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <RoomPreferences />
                                    </InputAdornment>
                                ),
                            }}
                        >
                            <MenuItem value={"no_preference"} sx={{ display: "none" }}>
                                {"No Preference"}
                            </MenuItem>
                            {roomTypes?.list?.map((roomType, index) => (
                                <MenuItem value={roomType._id} key={index}>
                                    {roomType.name}
                                </MenuItem>
                            ))}
                        </CssTextField>
                        <Box>
                            <Button
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                }}
                                onClick={() => setIsAdding(true)}
                            >
                                <Add sx={{ mr: "10px" }} />
                                Add new rate
                            </Button>
                        </Box>
                    </Stack>
                    <Box
                        m="8px 0 0 0"
                        height="350px"
                        sx={tableStyle(colors)}
                    >
                        <DataGrid
                            rows={offersList}
                            columns={columns}
                            components={{ Footer: CustomFooter }}
                            getRowId={(row) => row?._id}
                        />
                    </Box>
                </>
            ) : (
                <AddNewRate colors={colors} dispatch={dispatch} roomtypeId={roomtypeId} setIsAdding={setIsAdding}/>
            )}
        </Box>
    )
}

export default RatesInRevManager;