import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { Close } from "@mui/icons-material";

export default function ButtonWithConfirmModal({ BtnLabel = "", text = "", action, isIconButton, btnIcon }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modal, setModal] = useState(false);

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    function handleActionBtn() {
        action()
        setModal(false)
    }

    return (
        <>
            {isIconButton ? (
                <IconButton onClick={() => setModal(true)}>
                    {btnIcon}
                </IconButton>
            ) : (
                <Button
                    size="small"
                    variant="contained"
                    endIcon={btnIcon ? btnIcon : null}
                    onClick={() => setModal(true)}
                >
                    <b>{BtnLabel || "Click"}</b>
                </Button>
            )}
            <Modal open={modal} onClose={() => setModal(false)}>
                <Box sx={modalStyle}>
                    <Stack direction="row" justifyContent="space-between" mb={5}>
                        <Typography variant="h4">
                            {text || "Are you sure ?"}
                        </Typography>
                        <Close onClick={() => setModal(false)} sx={{ cursor: "pointer" }} />
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="end">
                        <Button
                            // fullWidth
                            variant="text"
                            onClick={() => setModal(false)}
                        >
                            <b>Close</b>
                        </Button>
                        <Button
                            // fullWidth
                            sx={{ width: 100 }}
                            variant="contained"
                            onClick={handleActionBtn}
                        >
                            <b>Yes</b>
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}
