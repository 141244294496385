/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import { listBookingDetails, resetBookingDetails, updateCheckinDetails } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CssTextField } from '../../../components/TextField';
import { ArrowBack } from '@mui/icons-material';
import { tokens } from '../../../theme';

function UpdateCheckinDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [adults, setAdults] = useState({arr:[]});
  const [name, setName] = useState("");

  const { bookingDetails: { total_adults, total_childs }} = useSelector(
    ({ booking: { bookingDetails } }) => ({
      bookingDetails,
    })
  );
  // console.log(adults);
  useEffect(() => {
    document.title = 'Booking details';
    dispatch(resetBookingDetails());
    dispatch(listBookingDetails({ bookingId: id }));
    createArray();
  }, [dispatch, id]);

  const createArray = () => {
    let arr = [];
    for(var i = 0; i < total_adults; i++){
      arr.push({
        name: "",
        email: "",
        address: "",
        sex: "",
        country: "",
        // age: "",
        mobile: "",
        id: `100${i}`
      })
    }
    setAdults({arr: arr});
    // setAdults({ arr: Array(total_adults).fill(fields) });
  };

  const handleAdultValueChange = (index, newValue, id) => {
    let field = newValue.target.name;
    let valueAdd = adults.arr;
    if (newValue?.target) {
      valueAdd = valueAdd.map((x) => {
        if (x.id === id) x[field] = newValue.target.value;
        return x;
      });
    }
    else {
      valueAdd[index][field] = newValue
    }
    setName(valueAdd)
    setAdults({ arr: valueAdd });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = adults.arr;
    data = data.map(function(item){ 
      delete item.id 
      return item
    });
    console.log(data);
    await dispatch(updateCheckinDetails({booking_id: id, customers: adults?.arr}));
    navigate(-1);
  }

  return (
    <Box m="20px" mb={15}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
        spacing={2}
        m="20px 0"
      >
        <Button size='small' variant='outlined' onClick={() => navigate(-1)}>
          <ArrowBack fontSize='small' />
        </Button>
        <Typography fontWeight="bold" variant='h4'>
          UPDATE CHECKIN DETAILS
        </Typography>
        <Box width={40}/>
        
      </Stack>
      <Box
        width="100%"
        p={2}
        borderRadius={2}
        backgroundColor={colors.primary[400]}
      >
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Adults</Typography>
          </Grid>
          {adults?.arr?.map((element, index) => (
          <Grid item md={6} xs={12} key={index}>
            <Stack p={2} spacing={2} borderRadius={2} bgcolor={colors.primary[500]}>
              <Stack spacing={2} direction="row" alignItems="center">
                <Typography width={"60%"}>
                  Name:
                </Typography>
                <CssTextField
                  fullWidth
                  required
                  size="small"
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={element?.name || ""}
                  onChange={(newValue) => handleAdultValueChange(index, newValue, element.id)}
                />
              </Stack>
              <Stack spacing={2} direction="row" alignItems="center">
                <Typography width={"60%"}>
                  Age:
                </Typography>
                <CssTextField
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Age"
                  name="age"
                  value={element?.age}
                  onChange={(newValue) => handleAdultValueChange(index, newValue, element.id)}
                />
              </Stack>
              <Stack spacing={2} direction="row" alignItems="center">
                <Typography width={"60%"}>
                  Email:
                </Typography>
                <CssTextField
                  fullWidth
                  size="small"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={element?.email}
                  onChange={(newValue) => handleAdultValueChange(index, newValue, element.id)}
                />
              </Stack>
            </Stack>
          </Grid>
           ))}
           <Grid item xs={12}>
            <Stack alignItems="flex-end">
              <Button type='submit' variant='contained'>
                <b>Submit</b>
              </Button>
            </Stack>
           </Grid>
        </Grid>
        </form>
      </Box>
    </Box>
  )
}

export default UpdateCheckinDetails