import React, { useState } from 'react'
// import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, Grid, Link, Modal, Stack, Typography } from '@mui/material'
import { ArrowBack, ArrowForward, KeyboardArrowDown, KeyboardArrowUp, MeetingRoom, Person, Search } from '@mui/icons-material';
// import Header from '../../components/Header';
import SelectedRoomsModal from './selectedRoomsModal';
import noImage from "../../assets/img/no_image0.jpeg";
import SimpleImageSlider from "react-simple-image-slider";
import moment from 'moment';
import "./styles.css";

function AvailableRoomtypes({ roomtypes = [], colors, setPage, roomtypeId, setRoomtypeId }) {
    const [selected, SetSelected] = useState(false);
    const [open, setOpen] = useState(false);
    const [policyModal, setPolicyModal] = useState(false);
    const [imgModal, setImgModal] = useState(false);
    const [img, setImg] = useState("");
    const [expanded, setExpanded] = useState([]);
    let datas = localStorage.getItem("checkingData");
    datas = JSON.parse(datas);

    let start = moment.unix(datas?.checkinDate);
    let end = moment.unix(datas?.checkoutDate );
    let diff = Math.ceil(moment.duration(start.diff(end)).asDays());
    diff = Math.abs(diff);

    const handleOpenModal = (item) => {
        SetSelected(item);
        setRoomtypeId(item?._id);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const handlexpandBtn = (id) => {
        var data = expanded;
        if (data.includes(id)) {
            data = data.filter(item => item !== id);
        } else {
            data = [].concat(data, id);
        }
        setExpanded(data)
    }

    const modalStyle = (wid) => {
        return {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { sm: wid, xs: "90%" },
            bgcolor: colors.primary[400],
            outline: "none",
            boxShadow: 24,
            p: 2,
        }
    };

    return (
        <>
        <Stack minHeight="100vh" justifyContent="center" alignItems="center">
        <Stack mt={2} mb={2} width="100%">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button variant='contained' onClick={() => setPage(1)}>
                        <ArrowBack />
                    </Button>
                </Grid>
                {roomtypes.map((e, i) => (
                    <Grid item xs={12} key={i}>
                        <Stack p={2} bgcolor={"rgb(0, 0, 0, 0.7)"} style={{backdropFilter: `blur(5px)`}} spacing={2} borderRadius={3}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography textTransform="uppercase" fontWeight={700} fontSize={20} color={colors.greenAccent[500]}>
                                    {e.name}
                                </Typography>
                            </Stack>
                            <Stack direction={{ sm: "row", xs: "column" }} spacing={2} justifyContent="space-between">
                                <Stack direction={{ sm: "row", xs: "column" }} spacing={2}>
                                    <Stack width={{sm: 300, xs: "100%"}} height={200} className="img-container" borderRadius={2} overflow="hidden">
                                        {e.images[0] ?
                                        <SimpleImageSlider
                                            width={"100%"}
                                            height={"100%"}
                                            images={e.images}
                                            showBullets={true}
                                            showNavs={true}
                                            navMargin={1}
                                            className={e.images[0] ? 'image' : ""}
                                            autoPlay
                                            autoPlayDelay={5}
                                            onClick={(idx) => {
                                                setImgModal(true)
                                                setImg(e.images[idx || 0])
                                            }}
                                        /> 
                                        : <img src={`${noImage}?w=164&h=164&fit=crop&auto=format`} width={"100%"} height={"100%"} alt=''/>}
                                        {/* <img className={e.images[0] ? 'image' : ""} style={{borderRadius: 5}} src={e.images[0] ? `${e.images[0]}?w=164&h=164&fit=crop&auto=format` : `${noImage}?w=164&h=164&fit=crop&auto=format`} alt='' /> */}
                                        {/* {e.images[0] ? (
                                        <div className="top">
                                            <div
                                                className="text"
                                                style={{cursor: "default"}}
                                                // onClick={() => {
                                                //     setImg(e.images[0]);
                                                //     setImgModal(true);
                                                // }}
                                            >
                                                Click image to open
                                            </div>
                                        </div>
                                        ) : null} */}
                                    </Stack>
                                    <Stack spacing={2} alignItems="start" justifyContent="space-between">
                                        <Stack direction="row" spacing={2}>
                                            <Stack justifyItems={"center"} spacing={1}>
                                                <Typography>Max occupancy / room</Typography>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                    p={0.5}
                                                    textAlign="center"
                                                    border={1}
                                                    borderRadius={1}
                                                    borderColor={colors.greenAccent[500]}
                                                    // bgcolor={colors.primary[700]}
                                                    boxShadow={15}
                                                    fontSize={20}

                                                >
                                                    <Person />
                                                    <b>{e.max_no_of_people}</b>
                                                </Stack>
                                            </Stack>
                                            <Stack justifyItems={"center"} spacing={1}>
                                                <Typography>Available rooms</Typography>
                                                {/* <hr /> */}
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                    p={0.5}
                                                    textAlign="center"
                                                    border={1}
                                                    borderRadius={1}
                                                    borderColor={colors.greenAccent[500]}
                                                    // bgcolor={colors.primary[700]}
                                                    boxShadow={15}
                                                    fontSize={20}
                                                >
                                                    <MeetingRoom />
                                                    <b>{e.available_rooms}</b>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Button
                                            size="small"
                                            sx={{ letterSpacing: 1, borderRadius: 20, p: 0, pr: 1 }}
                                            onClick={() => handlexpandBtn(e?._id)}
                                            endIcon={expanded?.includes(e?._id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        >
                                            <b>{"more details"}</b>
                                        </Button>
                                    </Stack>
                                </Stack>
                                <Stack alignItems="end" justifyContent="space-between">
                                    <Stack textAlign="end">
                                        <Typography><i>for 2 {e.max_no_of_people === 1 ? "person" : "people"} {e.display_room_no ? `x ${e.display_room_no} rooms` : null} :</i></Typography>
                                        <Typography color={"#aaa"}>{e?.total_price / diff} x {diff} {"day(s)"}</Typography>
                                        {e?.actual_price && e?.actual_price > e?.total_price ? <Typography fontSize={16}><s>₹{e?.actual_price}</s></Typography> : null}
                                        <Typography fontSize={20} color={colors.greenAccent[500]}><b>₹{e?.total_price}</b></Typography>
                                    </Stack>
                                    <Button 
                                        variant='contained' 
                                        onClick={() => handleOpenModal(e)}
                                        disabled={e?.total_price < 1}
                                        endIcon={<ArrowForward />}
                                    ><b>Select</b></Button>
                                </Stack>
                            </Stack>
                            {expanded?.includes(e?._id) && (
                                <>
                                    {e.description ?
                                        <Typography fontSize="15px">
                                            {e.description}
                                        </Typography>
                                    : null}
                                    <Link fontSize={15} onClick={() => setPolicyModal(true)}>
                                        Cancellation policy and terms and conditions
                                    </Link>
                                </>
                            )}
                        </Stack>
                    </Grid>
                ))}
            </Grid>
            {roomtypes?.length < 1 && (
                <Stack mt={2} alignItems="center" justifyContent="center" bgcolor={colors.primary[400]} spacing={1} width={"100%"} height={300}>
                    <Search fontSize='large' />
                    <Typography textAlign="center">
                        No rooms are available in the given date range. Try a different date range.
                    </Typography>
                </Stack>
            )}

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={modalStyle(400)}>
                    <SelectedRoomsModal roomtypeId={roomtypeId} setPage={setPage} selected={selected} colors={colors}/>
                </Box>
            </Modal>

            <Modal
                open={policyModal}
                onClose={() => setPolicyModal(false)}
            >
                <Box sx={modalStyle(600)}>
                    <Box fontSize={15} maxHeight={"80vh"} overflow="auto">
                        <u>Cancellation Policy:</u>
                        <ul>
                            <li>No refund is applicable for NO SHOW</li>
                            <li>Booking Cancellation on 30 Days window will refund 100% amount</li>
                            <li>Booking Cancellation on 15 Days window will be charge 50% amount</li>
                            <li>Booking Cancellation on 7 days window will be charge 100% amount</li>
                        </ul>
                        <u>Terms and conditions:</u>
                        <div>
                            As per the current government protocol, every tourist/guest needs to present at least 1 covid vaccination
                            certificate or latest (72 hours) RTPCR negative certificate at the time check-in to the resort.Every guest needs to
                            present any of the valid ID proofs at the time of check-in
                        </div>
                        <ul>
                            <li>Out side guests are not welcome due to Covid protocol. Prior intimation needs to be given to the resort in
                                case of visit of outside guests and they are requested to leave the resort by 09.30 PM by all means.</li>
                            <li>Pets are not allowed in the resort</li>
                            <li>Any parties/functions or loud voice not permitted after 09.00 PM</li>
                            <li>Outside food is not permitted in the resort</li>
                            <li>Check-in Time is 02:00 PM and check-out time is 11:00 AM</li>
                        </ul>
                    </Box>
                    <Stack alignItems="end">
                        <Button onClick={() => setPolicyModal(false)}><b>Close</b></Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={imgModal}
                onClose={() => setImgModal(false)}
            >
                <Box sx={modalStyle("50%")}>
                    <Stack width={"100%"} height={"100%"}>
                        <img src={img} alt=''/>
                    </Stack>
                </Box>
            </Modal>
        </Stack>
        </Stack>
        </>
    )
}

export default AvailableRoomtypes