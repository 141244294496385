import React from 'react';
import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { createRoomTypes, getRegistrationStatus } from '../../store/actions';
import { useNavigate } from 'react-router-dom';

function RoomCreation({ setPage, roomTypes, setRoomTypes, colors, dispatch, isRegistration }) {
    const navigate = useNavigate();

    const handleNewFieldValueChange = (i, roomIndex, newValue, keyName) => {
        var valueAdd = roomTypes.arr;
        if (newValue?.target) {
            valueAdd[i].rooms[roomIndex][keyName] = newValue.target.value;
        } else {
            valueAdd[i].rooms[roomIndex][keyName] = newValue
        }
        setRoomTypes({ arr: valueAdd })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(roomTypes?.arr);
        let status = await dispatch(createRoomTypes({
            isForReg: isRegistration ? true : false,
            roomtypes: roomTypes?.arr,
        }));
        let reg = await dispatch(getRegistrationStatus());
        if (status && reg){
            if(isRegistration){
                window.open("/dashboard", "_self");
            }else{
                navigate("/room-types");
            }
        }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                {roomTypes?.arr?.map((e, i) => (
                    <Box mb={2} key={i}>
                        <Stack mb={1}>
                            <Typography variant='h4' textTransform="uppercase" fontWeight={700}>
                                {roomTypes?.arr[i]?.name}
                            </Typography>
                        </Stack>
                        <Grid container spacing={1}>
                            {roomTypes?.arr[i]?.rooms?.map((room, roomIndex) => (
                                <Grid item lg={6} xs={12} key={roomIndex}>
                                    <Box p={2} mb={1} bgcolor={colors.primary[500]} borderRadius={2}>
                                        <Grid container columnSpacing={{ md: 4, xs: 2 }} rowSpacing={2}>
                                            <Grid item md={12} xs={12}>
                                                <Chip label={`Room ${roomIndex + 1}`} fontWeight={600} />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography width={"60%"}>
                                                        Room No:*
                                                    </Typography>
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        size="small"
                                                        type="text"
                                                        placeholder="Room No"
                                                        value={roomTypes?.arr[i]?.rooms[roomIndex]?.room_no}
                                                        onChange={(newValue) => handleNewFieldValueChange(i, roomIndex, newValue, 'room_no')}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography width={"60%"}>
                                                        Description:
                                                    </Typography>
                                                    <CssTextField
                                                        fullWidth
                                                        size="small"
                                                        type="text"
                                                        placeholder="Description"
                                                        value={roomTypes?.arr[i]?.rooms[roomIndex]?.description}
                                                        onChange={(newValue) => handleNewFieldValueChange(i, roomIndex, newValue, 'description')}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography width={"60%"}>
                                                        Room size:
                                                    </Typography>
                                                    <CssTextField
                                                        fullWidth
                                                        size="small"
                                                        type="text"
                                                        placeholder="Room size"
                                                        value={roomTypes?.arr[i]?.rooms[roomIndex]?.room_size}
                                                        onChange={(newValue) => handleNewFieldValueChange(i, roomIndex, newValue, 'room_size')}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))}
                <Stack mt={1} direction="row" justifyContent="space-between">
                    <Button variant='outlined' onClick={() => setPage(4)}>
                        <b>go back</b>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <b>Create rooms</b>
                    </Button>
                </Stack>
            </form>
        </Box>
    )
}

export default RoomCreation