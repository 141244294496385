/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { checkAvailability, resetAvailabilityData } from '../../store/actions';
import { Button, CircularProgress, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { useSearchParams } from 'react-router-dom';
import { CalendarMonth, Home } from '@mui/icons-material';
import { Formik } from 'formik';
import moment from 'moment';
// import PlusOrMinusButton from '../../components/PlusOrMinusButton';

export default function Page1({ code, setPage, setErrMsg, isPrivate, dispatch, colors, hotelDetails, isLoading }) {
    const checkinRef = useRef();
    const checkoutRef = useRef();
    const [pref, setPref] = useState(isPrivate ? "private" : "Room_only");
    const [searchParams, setSearchParams] = useSearchParams();
    var checkInDate = searchParams.get("CheckInDate");
    var checkOutDate = searchParams.get("CheckOutDate");

    useEffect(() => {
        if(checkInDate && checkOutDate){
            callCheckAvailaility();
        }
    },[checkInDate, checkOutDate])

    const callCheckAvailaility = async() => {
        if(!checkInDate || !moment(checkInDate, "DD/MM/YYYY", true).isValid()){
            setErrMsg("Invalid check-in date!");
            return setPage(5);
        }
        else if(!checkOutDate || !moment(checkOutDate, "DD/MM/YYYY", true).isValid()){
            setErrMsg("Invalid check-out date!");
            return setPage(5);
        }
        else if(moment(checkInDate, "DD/MM/YYYY").isBefore(moment().startOf("day"))){
            setErrMsg("Check-in date cannot be lower than current date!");
            return setPage(5);
        }
        else if (moment(checkInDate, "DD/MM/YYYY") > moment(checkOutDate, "DD/MM/YYYY")) {
            setErrMsg("Check-in date cannot be greater than Check-out date!");
            return setPage(5);
        }

        if(checkInDate === checkOutDate){
            checkOutDate = moment(checkOutDate, "DD/MM/YYYY").add(1, "day").format("DD/MM/YYYY");
        }
        let data = {
            hotelCode: code,
            checkinDate: moment(checkInDate, "DD/MM/YYYY").startOf("day").unix(),
            checkoutDate: moment(checkOutDate, "DD/MM/YYYY").startOf("day").unix(),
            numOfAdults: 2,
            numOfChilds: 0,
            totalRooms: 1,
        }
        dispatch(checkAvailability(data));
        localStorage.setItem("checkingData", JSON.stringify(data));
        setPage(2);
    }

    const initialValues = {
        checkinDate: moment().format("YYYY-MM-DD"),
        checkoutDate: moment().add(1, "days").format("YYYY-MM-DD"),
        numOfAdults: 2,
        numOfChilds: 0,
        totalRooms: 1,
    };

    const handleFormSubmit = async (values) => {
        let data = {
            ...values,
            hotelCode: code,
            checkinDate: moment(values.checkinDate, "YYYY-MM-DD").unix(),
            checkoutDate: moment(values.checkoutDate, "YYYY-MM-DD").unix(),
            isPrivate: pref === "private" ? true : false,
        }
        // console.log(data);
        dispatch(resetAvailabilityData());
        dispatch(checkAvailability(data));
        localStorage.setItem("checkingData", JSON.stringify(data));
        setPage(2);
    }

    return (
        <>
            <Stack minHeight="100vh" justifyContent="center" alignItems="center">
                <Grid container spacing={2} justifyContent="center">
                    <Grid item md={6} xs={12} mt={2} mb={2}>
                        <Stack
                            p={1}
                            // pl={2}
                            // pr={2}
                            spacing={{ md: 2, xs: 1 }}
                            width="100%"
                            direction="row"
                            bgcolor={colors.greenAccent[700]}
                            // color="#000"
                            justifyContent="space-between"
                        >
                            <Stack direction="row" spacing={1}>
                                <Home />
                                <Typography textTransform={"uppercase"} fontWeight="bold">
                                    {hotelDetails?.name || "-"}
                                </Typography>
                            </Stack>
                            {/* <Stack direction="row" spacing={1}>
                                <BorderAll />
                                <Typography textTransform={"uppercase"} fontWeight="bold">
                                    {hotelDetails?.property?.type || "-"}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Place />
                                <Typography textTransform={"uppercase"} fontWeight="bold">
                                    {hotelDetails?.address || "-"}
                                </Typography>
                            </Stack> */}
                        </Stack>
                        <Stack
                            p={2}
                            // bgcolor={colors.primary[400]}
                            bgcolor={"rgb(0, 0, 0, 0.7)"}
                            style={{backdropFilter: `blur(3px)`}}
                        >
                            <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2} alignItems="end">
                                            <Grid item md={6} xs={12}>
                                                <Typography><b>Check-in date</b></Typography>
                                                <CssTextField
                                                    fullWidth
                                                    required
                                                    size='small'
                                                    type="date"
                                                    placeholder="Check-in date"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        if (moment(e.target.value).add(1, "days").format("YYYY-MM-DD") > values.checkoutDate) {
                                                            setFieldValue(
                                                                'checkoutDate',
                                                                moment(e.target.value).add(1, "days").format("YYYY-MM-DD")
                                                            );
                                                        }
                                                    }}
                                                    value={values.checkinDate}
                                                    name="checkinDate"
                                                    error={!!touched.checkinDate && !!errors.checkinDate}
                                                    helperText={touched.checkinDate && errors.checkinDate}
                                                    inputProps={{ min: moment().format("YYYY-MM-DD") }}
                                                    inputRef={checkinRef}
                                                    onClick={() => checkinRef.current.showPicker()} 
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CalendarMonth sx={{ cursor: "pointer" }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    // disabled={page === 2}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Typography><b>Check-out date</b></Typography>
                                                <CssTextField
                                                    fullWidth
                                                    required
                                                    size="small"
                                                    type="date"
                                                    placeholder="Checkout Date"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.checkoutDate}
                                                    name="checkoutDate"
                                                    error={!!touched.checkoutDate && !!errors.checkoutDate}
                                                    helperText={touched.checkoutDate && errors.checkoutDate}
                                                    inputProps={{ min: moment(values.checkinDate).add(1, "days").format("YYYY-MM-DD") }}
                                                    inputRef={checkoutRef}
                                                    onClick={() => checkoutRef.current.showPicker()} 
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CalendarMonth sx={{ cursor: "pointer" }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    // disabled={page === 2}
                                                />
                                            </Grid>
                                            {isPrivate ? (
                                                <Grid item md={12} xs={12}>
                                                    <Typography><b>Book</b></Typography>
                                                    <RadioGroup
                                                        row
                                                        value={pref}
                                                        onChange={(e) => setPref(e.target.value)}
                                                    >
                                                        <FormControlLabel value="private" control={<Radio />} label="Entire Property" />
                                                        <FormControlLabel value="Room_only" control={<Radio />} label="Individual Room" />
                                                    </RadioGroup>
                                                </Grid>
                                            ) : null}
                                            {/* <Grid item md={6} xs={12}></Grid> */}
                                            {/* <Grid item md={6} xs={12}>
                                                <Typography><b>Number of adults</b></Typography>
                                                <PlusOrMinusButton
                                                    min={1}
                                                    max={20}
                                                    name="numOfAdults"
                                                    value={values.numOfAdults}
                                                    handleChange={(value) => setFieldValue("numOfAdults", value)}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Typography><b>Number of childs</b></Typography>
                                                <PlusOrMinusButton
                                                    min={0}
                                                    max={20}
                                                    name="numOfChilds"
                                                    value={values.numOfChilds}
                                                    handleChange={(value) => setFieldValue("numOfChilds", value)}
                                                />
                                            </Grid> */}
                                            {/* <Grid item md={6} xs={12}>
                                                <Typography><b>Total rooms</b></Typography>
                                                <PlusOrMinusButton
                                                    min={1}
                                                    max={30}
                                                    name="totalRooms"
                                                    value={values.totalRooms}
                                                    handleChange={(value) => setFieldValue("totalRooms", value)}
                                                />
                                            </Grid> */}
                                            {/* {!isPrivate ? (
                                                <Grid item md={6} xs={12}></Grid>
                                            ) : null}  */}
                                            <Grid item md={12} xs={12}>
                                                <Button
                                                    fullWidth
                                                    // size="small"
                                                    type="submit"
                                                    color="secondary"
                                                    variant="contained"
                                                    // disabled={page === 2}
                                                    sx={{ fontSize: "15px" }}
                                                >
                                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Check availability</b>}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}