// docs https://github.com/azouaoui-med/react-pro-sidebar
/* eslint-disable */
import { useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";

// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import { AddToQueue, AutoAwesome, BarChart, Groups2, Groups3Outlined, Inventory, InventoryTwoTone, LibraryBooks, MeetingRoomOutlined, MenuBook, RoomPreferences, Settings, TableView, Widgets } from "@mui/icons-material";
import { getRegistrationStatus } from "../../../store/actions";
import { useDispatch } from "react-redux";

const MyProSidebar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("/dashboard");
  const [regStatus, setRegStatus] = useState(false);
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const selectionStyle = { color: !regStatus ? colors.grey[300] : "" };

  const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <MenuItem
        active={selected === to}
        style={{ color: regStatus ? colors.grey[600] : colors.grey[100] }}
        onClick={() => setSelected(to)}
        icon={icon}
        routerLink={<Link to={to} />}
        disabled={regStatus === false}
      >
        <Typography sx={selectionStyle}>{title}</Typography>
      </MenuItem>
    );
  };
  
  useEffect(() => {
    setSelected(location.pathname);
  },[location.pathname])

  useEffect(() => {
    fetchStatus();
  },[dispatch]);

  const fetchStatus = async() => {
    let reg = await dispatch(getRegistrationStatus());
    setRegStatus(reg);
  }
  
  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[400]}
        image={sidebarImage}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : sidebarRTL ? (
                <SwitchLeftOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              ) : (
                <SwitchRightOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              )
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                ml="15px"
              >
                {/* <Typography variant="h3" color={colors.grey[100]}>
                  ADMIN
                </Typography> */}
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {/* {!collapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  "& .avater-image": {
                    backgroundColor: colors.primary[500],
                  },
                }}
              >
                <img
                  className="avater-image"
                  alt="profile user"
                  width="100px"
                  height="100px"
                  src={"../../assets/user.png"}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  User
                </Typography>
              </Box>
            </Box>
          )} */}
          <Box paddingLeft={collapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "10px 20px 5px 24px" }}
            >
              Data
            </Typography>
            <Item
              title="Calendar"
              to="/calendar"
              icon={<TableView sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Bookings"
              to="/bookings"
              icon={<LibraryBooks sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Check-in details"
              to="/checkin-details"
              icon={<CalendarTodayOutlinedIcon sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Inventory reports"
              to="/inventory"
              icon={<Inventory sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Inventory schedules"
              to="/inventory-schedules"
              icon={<InventoryTwoTone sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Rooms"
              to="/rooms"
              icon={<MeetingRoomOutlined sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Room Types"
              to="/room-types"
              icon={<RoomPreferences sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />
            {localStorage.getItem("userRole") !== "manager" ? (
              <>
                <Item
                  title="Reports"
                  to="/reports"
                  icon={<BarChart sx={selectionStyle} />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "10px 20px 5px 24px" }}
                >
                  More
                </Typography>
                <Item
                  title="Settings"
                  to="/settings"
                  icon={<Settings sx={selectionStyle} />}
                  selected={selected}
                  setSelected={setSelected}
                />
                {localStorage?.getItem("isRevenueManager") === "true" ? (
                  <Item
                    title="Revenue Manager"
                    to="/revenue-manager"
                    icon={<MenuBook sx={selectionStyle} />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ) : null}
                {localStorage?.getItem("isHotsoft") === "true" ? (
                  <Item
                    title="Hotsoft"
                    to="/hotsoft-updates"
                    icon={<Widgets sx={selectionStyle} />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ) : null}
              </>
            ) : null} */}
            {/* <Item
              title="Pricings"
              to="/pricings"
              icon={<LocalOffer sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Highlights"
              to="/highlights"
              icon={<AutoAwesome sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Facilities"
              to="/facilities"
              icon={<AddToQueue sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Agents"
              to="/agents"
              icon={<Groups2 sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Staffs"
              to="/staffs"
              icon={<Groups3Outlined sx={selectionStyle} />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="Profile Form"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
