import { RESET_ALL_ROOMTYPE_OFFERS, RESET_ALL_ROOMTYPE_OTA_COUNT, SAVE_ALL_ROOMTYPE_OFFERS, SAVE_ALL_ROOMTYPE_OTA_COUNT } from "../types";

const INITIAL_STATE = {
    offers: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    otaCounts: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
};

const RoomtypesReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_ROOMTYPE_OFFERS:
            return {
                ...state,
                offers: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_ROOMTYPE_OFFERS:
            return {
                ...state,
                offers: INITIAL_STATE.offers,
            };

        case SAVE_ALL_ROOMTYPE_OTA_COUNT:
            return {
                ...state,
                otaCounts: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_ROOMTYPE_OTA_COUNT:
            return {
                ...state,
                otaCounts: INITIAL_STATE.otaCounts,
            };

        default:
            return state;
    }
};

export default RoomtypesReducer;
