import React, { useEffect, useState } from 'react';
import { UpdatableField } from './TextField';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

// const textColor = {
//     "& .MuiInputBase-input.Mui-disabled": {
//         WebkitTextFillColor: "#fff",
//         textAlign: "center",
//     },
// }

function PlusOrMinusButton({ name = "", label = "", value = 1, min = 0, max = 5, handleChange, size = "small" }) {
    const [num, setNum] = useState(value);
    const handleMinusBtn = () => {
        if (num > min) {
            setNum(parseInt(num) - 1);
        }
    }
    const handlePlusBtn = () => {
        if (num < max) {
            setNum(parseInt(num) + 1);
        }
    }

    useEffect(() => {
        handleChange(num);
        // eslint-disable-next-line
    }, [num])

    return (
        <Box width="100%" sx={{ position: "relative" }}>
            <UpdatableField
                fullWidth
                required
                size={size}
                // type="number"
                // value={num}
                // sx={textColor}
                name={name}
                label={label}
                inputProps={{ min: min, max: max }}
                disabled
                InputProps={{
                    startAdornment: (
                        <InputAdornment sx={{ ml: -1 }} position="start">
                            <IconButton color='primary' onClick={handleMinusBtn}>
                                <Remove />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment sx={{ mr: -1 }} position="end">
                            <IconButton color='primary' onClick={handlePlusBtn}>
                                <Add />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <h4 style={{
                position: "absolute",
                left: "50%",
                top: size === "small" ? "2%" : "13%",
                transform: "translate(-50%, -50%)",
            }}>
                {num}
            </h4>
        </Box>
    )
}

export default PlusOrMinusButton