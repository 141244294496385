import React, { useEffect, useState } from 'react';
import { filterGroupBookingDatas } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Chip, CircularProgress, Grid, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { addGroupBooking, getMealPlansForDropdown, listAllHotelAgents, resetAllHotelAgents, resetSubAgentsDropdownList } from '../../store/actions';
import { ArrowForward, Block, Close } from '@mui/icons-material';
import { CssTextField } from '../../components/TextField';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import GroupBookingPage2 from './groupBookingPage2';
import ConfirmModal from '../../components/ConfirmModal';

function GroupBookingPage1() {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);

    const data = JSON.parse(localStorage.getItem("grpBooking"));
    const rooms = data ? filterGroupBookingDatas(data) : [];
    const [roomsData, setRoomsData] = useState({ arr: rooms });
    const [mPlans, setMPlans] = useState([]);
    // eslint-disable-next-line
    const [temp, setTemp] = useState("");
    const [page, setPage] = useState(1);
    const [modal, setModal] = useState(false);

    const { isLoading, agentsList, subAgentsList } = useSelector(
        ({
            loading,
            agents: {
                agents: { list },
                subAgentsList,
            },
        }) => ({
            isLoading: loading,
            agentsList: list,
            subAgentsList,
        })
    );

    useEffect(() => {
        if(!data){
            navigate("/calendar");
        }
        dispatch(resetAllHotelAgents());
        dispatch(listAllHotelAgents({isForDropdown: true}));
        dispatch(resetSubAgentsDropdownList());
        fetchMealPlans();
    // eslint-disable-next-line
    }, [])

    const fetchMealPlans = async () => {
        let mealPlans = await dispatch(getMealPlansForDropdown())
        setMPlans(mealPlans);
        
        const rooms = roomsData.arr;
        let mpAdded = await rooms.map((e) => {
            let mPlns = mealPlans?.filter((l) => l.roomtype_id === e?.roomtype_id);
            if (mPlns[0]?._id) {
                e.meal_plan_id = mPlns[0]?._id;
            }
            return e;
        })
        setRoomsData({ arr: mpAdded });
    }

    const handleChange = (event, i) => {
        let valueAdd = roomsData.arr;
        let keyName = event?.target?.name;
        if (event?.target) {
            valueAdd[i][keyName] = event.target.value;
        } else {
            valueAdd[i][keyName] = event
        }
        setTemp(event.target.value);
        setRoomsData({ arr: valueAdd });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(2);
    }

    const handleBlockButton = async () => {
        let data = {
            name: "Blocked",
            guest_name: "Blocked",
            email: "block@phs.in",
            guest_email: "block@phs.in",
            status: "hold",
            agent_id: "",
            sub_agent_id: "",
            rooms: roomsData.arr.map((e) => ({
                checkin_date: e.checkin_date,
                checkout_date: e.checkout_date,
                room_id: e.room_id,
                room_number: e.room_number,
                no_of_adults: e.no_of_adults,
                no_of_childs: e.no_of_childs,
                meal_plan_id: e.meal_plan_id || "",
            }))
        };
    
        console.log(data);
        let result = await dispatch(addGroupBooking(data));
        if (result) {
          navigate("/bookings");
        }
      };

    return (
        <>
            <Box m="20px" mb={15}>
                <Header title="GROUP BOOKING" />
                <Box
                    width="100%"
                    p={2}
                    borderRadius={2}
                    backgroundColor={colors.primary[400]}
                >
                    {page === 1 && (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {roomsData?.arr?.map((element, index) => {
                                    let mealPlans = mPlans?.filter((e) => e.roomtype_id === element?.roomtype_id);
                                    return (
                                        <Grid item md={6} xs={12} key={index}>
                                            <Box
                                                width="100%"
                                                p={2}
                                                borderRadius={2}
                                                backgroundColor={colors.primary[500]}
                                            >
                                                <Stack spacing={2}>
                                                    <Stack color={colors.greenAccent[500]} fontWeight={600}>
                                                        Room no: {element?.room_no}
                                                    </Stack>
                                                    <Stack direction={{ sm: "row", xs: "column" }} spacing={{ sm: 2, xs: 0 }}>
                                                        <Typography width={"67%"} mt={1}>
                                                            Selected dates:
                                                        </Typography>
                                                        <Grid container spacing={1}>
                                                            {element?.dates?.map((e, i) => (
                                                                <Grid item key={i}>
                                                                    <Chip label={e} size='small' />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Stack>
                                                    {/* <Stack direction="row" spacing={2} alignItems="center">
                                                    <Typography width={"70%"}>
                                                        Check-in date:
                                                    </Typography>
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        size='small'
                                                        type="text"
                                                        placeholder="Check-out date"
                                                        disabled
                                                        value={moment.unix(element?.checkin_date).format("DD-MMM-YYYY") || "-"}
                                                    />
                                                </Stack>
                                                <Stack direction="row" spacing={2} alignItems="center">
                                                    <Typography width={"70%"}>
                                                        Check-out date:
                                                    </Typography>
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        size='small'
                                                        type="text"
                                                        placeholder="Check-out date"
                                                        disabled
                                                        value={moment.unix(element?.checkout_date).format("DD-MMM-YYYY") || "-"}
                                                    />
                                                </Stack> */}
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Number of adults:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            required
                                                            size='small'
                                                            type="number"
                                                            placeholder="Number of adults"
                                                            name="no_of_adults"
                                                            value={element?.no_of_adults}
                                                            onChange={(e) => handleChange(e, index)}
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                        />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Number of children:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            required
                                                            size='small'
                                                            type="number"
                                                            placeholder="Number of children"
                                                            name="no_of_childs"
                                                            value={element?.no_of_childs}
                                                            onChange={(e) => handleChange(e, index)}
                                                            InputProps={{ inputProps: { min: 0 } }}
                                                        />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Meal plan:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            select
                                                            size="small"
                                                            name='meal_plan_id'
                                                            value={element?.meal_plan_id || "no_preference"}
                                                            onChange={(e) => handleChange(e, index)}
                                                        >
                                                            {mealPlans?.length < 1 ?
                                                            <MenuItem value={"no_preference"}>
                                                                {"CP"}
                                                            </MenuItem>
                                                            : null}
                                                            {mealPlans?.map((e) => (
                                                                <MenuItem value={e?._id} key={e?._id}>
                                                                    <Stack
                                                                        width="100%"
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        spacing={2}
                                                                    >
                                                                        <Typography>
                                                                            {e?.name?.toUpperCase()}
                                                                        </Typography>
                                                                        <Typography>
                                                                            ₹{e?.rate}
                                                                        </Typography>
                                                                    </Stack>
                                                                </MenuItem>
                                                            ))}
                                                        </CssTextField>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                                <Grid item md={12} xs={12}>
                                    <Stack direction={{xs: "column", sm: "row"}} spacing={2} justifyContent="space-between">
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate(-1)}
                                            startIcon={<Close />}
                                        >
                                            <b>Cancel</b>
                                        </Button>
                                        <Stack direction="row" spacing={2}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                endIcon={<Block />}
                                                onClick={() => setModal(true)}
                                            >
                                                <b>Block</b>
                                            </Button>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                endIcon={<ArrowForward />}
                                                disabled={localStorage?.getItem("isHotsoft") === "true"}
                                            >
                                                {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>continue</b>}
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    )}

                    {page === 2 && (
                        <GroupBookingPage2
                            roomsData={roomsData}
                            totalAmount={roomsData.arr.reduce((k,l) => k+l.rate, 0)}
                            agentsList={agentsList}
                            subAgentsList={subAgentsList}
                            isLoading={isLoading}
                            setPage={setPage}
                            navigate={navigate}
                        />
                    )}
                </Box>
            </Box>

            <ConfirmModal
                open={modal}
                setModal={setModal}
                text="Are you sure to block the selected rooms?"
                action={handleBlockButton}
            />
        </>
    )
}

export default GroupBookingPage1;