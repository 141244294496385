import {
    SAVE_ALL_HIGHLIGHTS,
    RESET_ALL_HIGHLIGHTS_DATA,
    SAVE_ALL_BOOKINGS,
    RESET_ALL_BOOKINGS,
    SAVE_ALL_ROOMS_LIST,
    RESET_ALL_ROOMS_LIST,
    SAVE_ALL_FACILITIES,
    RESET_ALL_FACILITIES_DATA,
    SAVE_ALL_ROOM_TYPES_LIST,
    RESET_ALL_ROOM_TYPES_LIST,
    SAVE_AVALABLE_ROOMS,
    RESET_AVALABLE_ROOMS,
    SAVE_ALL_RATES,
    RESET_ALL_RATES,
    SAVE_ALL_CALENDAR_REPORTS,
    RESET_ALL_CALENDAR_REPORTS,
    SAVE_ROOMTYPE_DETAILS,
    RESET_ROOMTYPE_DETAILS,
    SAVE_ROOM_DETAILS,
    RESET_ROOM_DETAILS
} from "../types";

const INITIAL_STATE = {
    highlights: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    facilities: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    bookings: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 300,
    },
    rooms: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 200,
    },
    roomTypes: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
        count: 1,
    },
    rates: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    availableRooms: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 20,
    },
    roomDetails: {},
    roomtypeDetails: {},
    calendarReports: [],
};

const adminReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_HIGHLIGHTS:
            return {
                ...state,
                highlights: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HIGHLIGHTS_DATA:
            return {
                ...state,
                highlights: INITIAL_STATE.highlights,
            };

        case SAVE_ALL_FACILITIES:
            return {
                ...state,
                facilities: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_FACILITIES_DATA:
            return {
                ...state,
                facilities: INITIAL_STATE.facilities,
            };

        case SAVE_ALL_BOOKINGS:
            return {
                ...state,
                bookings: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_BOOKINGS:
            return {
                ...state,
                bookings: INITIAL_STATE.bookings,
            };

        case SAVE_ALL_ROOMS_LIST:
            return {
                ...state,
                rooms: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_ROOMS_LIST:
            return {
                ...state,
                rooms: INITIAL_STATE.rooms,
            };

        case SAVE_ALL_ROOM_TYPES_LIST:
            return {
                ...state,
                roomTypes: {
                    pageSize: 10,
                    ...payload,
                },
            };

            case SAVE_ALL_RATES:
            return {
                ...state,
                rates: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_RATES:
            return {
                ...state,
                rates: INITIAL_STATE.rates,
            };

        case RESET_ALL_ROOM_TYPES_LIST:
            return {
                ...state,
                roomTypes: INITIAL_STATE.roomTypes,
            };

            case SAVE_ROOMTYPE_DETAILS:
                return {
                    ...state,
                    roomtypeDetails: payload
                };
    
            case RESET_ROOMTYPE_DETAILS:
                return {
                    ...state,
                    roomtypeDetails: INITIAL_STATE.roomtypeDetails,
                };

        case SAVE_AVALABLE_ROOMS:
            return {
                ...state,
                availableRooms: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_AVALABLE_ROOMS:
            return {
                ...state,
                availableRooms: INITIAL_STATE.availableRooms,
            };

        case SAVE_ALL_CALENDAR_REPORTS:
            return {
                ...state,
                calendarReports: payload
            };

        case RESET_ALL_CALENDAR_REPORTS:
            return {
                ...state,
                calendarReports: INITIAL_STATE.calendarReports,
            };

        case SAVE_ROOM_DETAILS:
            return {
                ...state,
                roomDetails: payload
            };

        case RESET_ROOM_DETAILS:
            return {
                ...state,
                roomDetails: INITIAL_STATE.roomDetails,
            };

        default:
            return state;
    }
};

export default adminReducer;
