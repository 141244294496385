/*eslint-disable*/
import React, { useEffect } from "react";
import { Box, Button, Stack, useTheme } from "@mui/material";
import { activateRoomtype, listAllFacilities, listAllHighlights, listAllRoomTypes, listRoomTypeDetails, resetAllRoomTypesList, resetRoomTypeDetails } from "../../store/actions";
// import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Add, ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PaginationFooter } from "../../components";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AvailabilityUpdation from "../../components/AvailabilityUpdation";
import ResponsiveTable from "../../components/ResponsiveTable";

const RoomTypes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { roomTypesList, pageNumber, pageSize, count } = useSelector(
    ({
      admin: {
        roomTypes: { list, pageNumber, pageSize, count },
      },
    }) => ({
      roomTypesList: list,
      pageNumber,
      pageSize,
      count,
    })
  );

  useEffect(() => {
    document.title = 'Room Types';
    dispatch(resetAllRoomTypesList());
    dispatch(listAllRoomTypes());
    dispatch(listAllHighlights());
    dispatch(listAllFacilities());
  }, []);

  const fetchMoreData = (page) => {
    dispatch(listAllRoomTypes(page));
  }

  const CustomFooter = () => {
    return (
      <PaginationFooter 
        count={count}
        pageSize={pageSize}
        pageNumber={pageNumber}
        fetchMoreData={(page) => fetchMoreData(page)}
      />
    );
  }

  const columns = [
    { field: "name", headerName: "Name", cellClassName: "name-column--cell", width: 200, hideInXs: true },
    { field: "description", headerName: "Description", width: 250, hideInXs: true, },
    // { field: "no_of_beds", headerName: "Number of beds", width: 120 },
    { field: "min_no_of_people", headerName: "Min. number of people", width: 170 },
    { field: "max_no_of_people", headerName: "Max. number of people", width: 170 },
    // {
    //   field: "is_active",
    //   headerName: "Availability",
    //   width: 100,
    //   renderCell: ({ row: { is_active } }) => {
    //     return (
    //       <Chip
    //         size="small"
    //         variant="outlined"
    //         label={is_active ? "active" : "inactive"}
    //         color={is_active ? "primary" : "warning"}
    //       />
    //     );
    //   },
    // },
    {
      field: "is_active",
      headerName: "Availability",
      width: 100,
      renderCell: ({ row: { is_active, _id, name } }) => {
        return (
          <AvailabilityUpdation
            name={name}
            isActive={is_active}
            action={() => dispatch(activateRoomtype({ id: _id }))}
          />
        );
      },
    },
    {
      field: "_id",
      headerName: "View details",
      width: 150,
      hideInXs: true,
      renderCell: ({ row: { _id } }) => {
        return (
          <Button
            size="small"
            variant="contained"
            onClick={async () => {
              await dispatch(resetRoomTypeDetails());
              await dispatch(listRoomTypeDetails({ id: _id }));
              navigate(`/roomtype-details/${_id}`);
            }}
            endIcon={<ArrowForward fontSize="small" />}
            sx={{ fontWeight: 600 }}
          >
            View
          </Button>
        )
      },
    },
  ];
  return (
    <Box m="20px" mb={15}>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={{ sm: "space-between", xs: "start" }}
        alignItems={{ sm: "center", xs: "start" }}
        m="20px 0"
      >
        <Header title="ROOM TYPES" subtitle="welcome to Room Types" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => navigate("/roomtype-creation")}
          >
            <Add sx={{ mr: "10px" }} />
            Create room types
          </Button>
        </Box>
      </Stack>

      <ResponsiveTable
        rows={roomTypesList}
        columns={columns}
        CustomFooter={CustomFooter}
        showPagination={true}
        buttonAction={async (e) => {
          await dispatch(resetRoomTypeDetails());
          await dispatch(listRoomTypeDetails({ id: e?._id }));
          navigate(`/roomtype-details/${e?._id}`);
        }}
        cardHead={{
          title: "name",
          subtitle: "description",
      }}
      />
      {/* <Box
        m="8px 0 0 0"
        height="80vh"
        sx={tableStyle(colors)}
      >
        <DataGrid
          rows={roomTypesList}
          columns={columns}
          components={{ Footer: CustomFooter }}
          getRowId={(row) => row?._id}
        />
      </Box> */}
    </Box>
  );
};

export default RoomTypes;
