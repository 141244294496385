import React, { useEffect, useRef, useState } from "react";
import {
    Box, Button, IconButton, InputAdornment, Typography, ButtonGroup, Grid,
    MenuItem, Stack, useTheme, TextField, Checkbox,
} from "@mui/material";
import {
    ArrowBackIosNew, ArrowForward, ArrowForwardIos, CalendarMonth, Close, ExpandLess, ExpandMore, Search
} from "@mui/icons-material";
import { listAllRoomsReports, resetAllRoomsReports } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";
import "./style.css"
import { toast } from "react-toastify";
import CalendarPopover from "../../components/calendarPopover";
import MonthlyReportsMobile from "./monthlyReportsMobile";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const years = (num = 10) => {
    const year = new Date(moment().add(5, "year")).getFullYear();
    return Array.from({ length: num }, (v, i) => year - num + i + 1);
}

const WeeklyReportsMobile = () => {
    const d = new Date();
    const [dateNum, setDateNum] = useState(localStorage.getItem("dateNum") || d.getMonth());
    const [year, setYear] = useState(localStorage.getItem("year") || d.getFullYear());
    const theme = useTheme();
    const startInputRef = useRef();
    const endInputRef = useRef();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [type, setType] = useState(localStorage.getItem("calType") || "week");
    const [startDate, setStartDate] = useState(localStorage.getItem("startDate") || moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(localStorage.getItem("endDate") || moment().add(7, "days").format("YYYY-MM-DD"));
    const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("expanded")) || []);
    const [selection, setSelection] = useState(false);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedRoomsData, setSelectedRoomsData] = useState([]);
    const [bookingDetails, setBookingDetails] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, room, item) => {
        setBookingDetails({
            ...item,
            ...room,
        })
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { roomsReports } = useSelector(({ calendar: { roomsReports } }) => ({
        roomsReports,
    })
    );
    // console.log(roomsReports);
    useEffect(() => {
        document.title = 'Calendar';
        if (!roomsReports?.length) {
            if (type === "week") {
                dataFetch();
            } else {
                handleMonthlyDataFetch(dateNum, year, true);
            }
        }
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (!expanded?.length) {
            let roomtypeNames = roomsReports?.map(a => a.roomtype._id);
            setExpanded(roomtypeNames);
            localStorage.setItem("expanded", JSON.stringify(roomtypeNames));
        }
        // eslint-disable-next-line
    }, [roomsReports]);

    const dataFetch = () => {
        // dispatch(resetAllRoomsReports());
        dispatch(listAllRoomsReports({
            startDate: moment(startDate, "YYYY-MM-DD").format("DD-MMM-YYYY"),
            endDate: moment(endDate, "YYYY-MM-DD").format("DD-MMM-YYYY"),
            noLoading: true,
        }));
    }

    const handleMonthlyDataFetch = (m = dateNum, y = year, noLoading = false) => {
        let start = `01-${months[m]}-${y}`;
        let end = moment(start, 'DD-MMM-YYYY').endOf('month').format('DD-MMM-YYYY');
        // dispatch(resetAllRoomsReports());
        dispatch(listAllRoomsReports({ startDate: start, endDate: end, noLoading: noLoading }));
    }

    const handleExpandBtn = (value) => {
        var data = expanded;
        if (data.includes(value)) {
            data = data.filter(item => item !== value);
        } else {
            data = [].concat(data, value);
        }
        setExpanded(data)
    }

    const handleFilterBtn = (start = startDate, end = endDate) => {
        if (end > start) {
            setSelectedRooms([])
            setSelectedRoomsData([])
            setSelection(false)
            // dispatch(resetAllRoomsReports());
            dispatch(listAllRoomsReports({
                startDate: moment(start, "YYYY-MM-DD").format("DD-MMM-YYYY"),
                endDate: moment(end, "YYYY-MM-DD").format("DD-MMM-YYYY")
            }));
        } else {
            toast.warning("Start date cannot be greater than or equal to end date!")
        }
    }

    const handleRoomSelection = (room, roominfo) => {
        let data = selectedRooms;
        let info = selectedRoomsData;
        if (data?.includes(room)) {
            let index = data?.indexOf(room);
            data[index] = undefined;
            info[index] = undefined;
            data = data.filter(item => item !== undefined);
            info = info.filter(item => item !== undefined);
        } else {
            data = [].concat(data, room);
            info = [].concat(info, roominfo);
        }
        // console.log(roominfo);
        setSelectedRooms(data);
        setSelectedRoomsData(info);
    }

    const handleContinueBtn = () => {
        localStorage.setItem("grpBooking", JSON.stringify(selectedRoomsData));
        setSelection(selection ? false : true);
        navigate("/group-booking")
        // setShowSidebar(true);
    }

    const ColorCode = ({ size = 15, color, label }) => (
        <Grid item>
            <Stack direction="row" spacing={1} alignItems="center">
                <Box width={size} height={size} bgcolor={color} />
                <Typography>{label}</Typography>
            </Stack>
        </Grid>
    )

    return (
        <Box m="20px" mb={15}>
            {/* <Container disableGutters> */}
            <Stack direction={{ sm: "row", xs: "column" }} justifyContent="space-between">
                <Header title="CALENDAR" subtitle="Full Calendar Interactive Page" />
                <Stack alignItems="end">
                    <Grid container mb={1} spacing={1} justifyContent={{ sm: "flex-end", xs: "flex-start" }}>
                        <ColorCode color={"#4BB543"} label={"Available"} />
                        {/* <ColorCode color={"#d32f2f"} label={"Unavailable"}/> */}
                        <ColorCode color={"#ff9800"} label={"Booked"} />
                        <ColorCode color={"#ffe600"} label={"PHS Booking"} />
                        <ColorCode color={"#525252"} label={"Blocked"} />
                    </Grid>
                    <Grid container spacing={1} justifyContent={{ sm: "flex-end", xs: "flex-start" }}>
                        <ColorCode size={10} color={"#8cf2ff"} label={"Direct"} />
                        <ColorCode size={10} color={"#aaabff"} label={"Agent"} />
                        <ColorCode size={10} color={"#dbdbdb"} label={"OTA"} />
                    </Grid>
                </Stack>
            </Stack>
            <Stack width={"100%"} ml={-2.5} mb={-2.5} bgcolor={colors.primary[400]} p={2} direction="row" spacing={1} position={"fixed"} bottom={20} zIndex={1} boxShadow={"1px -4px 35px -4px rgba(0,0,0,0.75)"}>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        size="small"
                        variant="contained"
                        endIcon={!selection ? <ArrowForward /> : ""}
                        onClick={() => {
                            setSelectedRooms([])
                            setSelectedRoomsData([])
                            setSelection(selection ? false : true)
                        }}
                        disabled={localStorage?.getItem("isHotsoft") === "true"}
                    >
                        {selection ? <Close fontSize='small' /> : <b>Book / Block</b>}
                    </Button>
                </Box>
                {selection &&
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            size="small"
                            variant="contained"
                            endIcon={<ArrowForward />}
                            disabled={!selectedRooms?.length}
                            onClick={handleContinueBtn}
                        >
                            <b>Continue</b>
                        </Button>
                    </Box>}
            </Stack>
            <Stack
                spacing={2}
                mt={2}
                direction={{ md: "row", xs: "column" }}
                alignItems={{ md: "flex-end", xs: "normal" }}
                justifyContent={{ md: "space-between", xs: "center" }}
            >
                <ButtonGroup
                    disableElevation
                    size="small"
                // variant="contained"
                >
                    <Button
                        variant={type === "week" ? "contained" : "outlined"}
                        onClick={() => {
                            setType("week")
                            localStorage.setItem("calType", "week")
                            dispatch(resetAllRoomsReports());
                            handleFilterBtn()
                        }}
                    >
                        <b>Week</b>
                    </Button>
                    <Button
                        variant={type === "month" ? "contained" : "outlined"}
                        onClick={() => {
                            setType("month")
                            localStorage.setItem("calType", "month")
                            dispatch(resetAllRoomsReports());
                            handleMonthlyDataFetch()
                        }}
                    >
                        <b>Month</b>
                    </Button>
                </ButtonGroup>
                {type === "week" ? (
                    <Stack
                        direction={"row"}
                        justifyContent={{ md: "flex-end", xs: "flex-start" }}
                        spacing={1}
                    >
                        <IconButton onClick={() => {
                            let start = moment(startDate, "YYYY-MM-DD").subtract(7, "days").format("YYYY-MM-DD")
                            let end = startDate
                            setStartDate(start)
                            setEndDate(end)
                            localStorage.setItem("startDate", start)
                            localStorage.setItem("endDate", end)
                            handleFilterBtn(start, end)
                        }}>
                            <ArrowBackIosNew />
                        </IconButton>
                        <TextField
                            fullWidth
                            size="small"
                            type="date"
                            label="Start Date"
                            placeholder="Start Date"
                            sx={{ width: 170 }}
                            inputRef={startInputRef}
                            onClick={() => startInputRef.current.showPicker()}
                            // onKeyDown={(event) => event.preventDefault()}
                            // onFocus={() => startInputRef.current.showPicker()} 
                            value={startDate || ""}
                            onChange={(newValue) => {
                                setStartDate(newValue.target.value)
                                localStorage.setItem("startDate", newValue.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonth sx={{ cursor: "pointer" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            type="date"
                            label="End Date"
                            placeholder="End Date"
                            sx={{ width: 170 }}
                            inputRef={endInputRef}
                            onClick={() => endInputRef.current.showPicker()}
                            inputProps={{ min: startDate }}
                            value={endDate || ""}
                            onChange={(newValue) => {
                                setEndDate(newValue.target.value)
                                localStorage.setItem("endDate", newValue.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonth sx={{ cursor: "pointer" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => handleFilterBtn()}
                            disabled={selection && startDate < moment().format("YYYY-MM-DD")}
                        >
                            <Search />
                        </Button>
                        <IconButton onClick={() => {
                            let start = endDate
                            let end = moment(endDate, "YYYY-MM-DD").add(7, "days").format("YYYY-MM-DD")
                            setStartDate(start)
                            setEndDate(end)
                            localStorage.setItem("startDate", start)
                            localStorage.setItem("endDate", end)
                            handleFilterBtn(start, end)
                        }}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton onClick={() => {
                            let m = dateNum > 0 ? parseInt(dateNum) - 1 : 11;
                            let y = dateNum > 0 ? year : parseInt(year) - 1;
                            handleMonthlyDataFetch(m, y)
                            setDateNum(m)
                            setYear(y)
                            localStorage.setItem("dateNum", m)
                            localStorage.setItem("year", y)
                        }}>
                            <ArrowBackIosNew />
                        </IconButton>
                        <TextField
                            select
                            size="small"
                            variant="standard"
                            value={dateNum === "01" ? "1" : dateNum}
                            onChange={(e) => {
                                // handleMonthlyDataFetch(e.target.value)
                                setDateNum(e.target.value)
                                localStorage.setItem("dateNum", e.target.value)
                            }}
                        >
                            {months?.map((m, i) => (
                                <MenuItem value={i} key={m}>
                                    {m}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            size="small"
                            variant="standard"
                            value={year}
                            onChange={(e) => {
                                // handleMonthlyDataFetch(dateNum, e.target.value)
                                setYear(e.target.value)
                                localStorage.setItem("year", e.target.value)
                            }}
                        >
                            {years()?.map((y) => (
                                <MenuItem value={y} key={y}>
                                    {y}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* <Typography fontSize={20} width={50}>
                                    {year}
                                </Typography> */}
                        <IconButton
                            color="primary"
                            sx={{ borderRadius: 1 }}
                            onClick={() => handleMonthlyDataFetch(dateNum, year)}
                        >
                            <Search />
                        </IconButton>
                        <IconButton onClick={() => {
                            let m = dateNum < 11 ? parseInt(dateNum) + 1 : 0;
                            let y = dateNum < 11 ? year : parseInt(year) + 1;
                            handleMonthlyDataFetch(m, y)
                            setDateNum(m)
                            setYear(y)
                            localStorage.setItem("dateNum", m)
                            localStorage.setItem("year", y)
                        }}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Stack>
                )}
            </Stack>




            {type === "week" ? (
                <>
            {roomsReports?.length ? (
                <Box width={"100%"} mt={2} display={"flex"} flexDirection={"column"} gap={2} className="reports">
                    {roomsReports?.map((item, index) => (
                        <Box key={index} border={"5px solid #2e7c67"}>
                            <Box
                                className="typename"
                                style={{ backgroundColor: "#2e7c67", color: "#fff" }}
                                onClick={() => handleExpandBtn(item?.roomtype?._id)}
                                title={item?.roomtype?.name}
                            >
                                <Stack direction="row" alignItems="center" justifyContent="space-between" pl={1}>
                                    <Typography fontWeight={600} width={140} noWrap textTransform="capitalize">
                                        {item?.roomtype?.name}
                                    </Typography>
                                    <IconButton size="small">
                                        {expanded.includes(item?.roomtype?._id) ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </Stack>
                            </Box>

                            {expanded?.includes(item?.roomtype?._id) && (
                                <>
                                    {item?.room_nos?.map((num, numIndex) => (
                                        <Box key={numIndex}>
                                            <Box title={num} style={{ backgroundColor: colors.primary[800] }} p={0.5}>
                                                <Typography fontWeight={600} width={160} noWrap textTransform="capitalize">
                                                    {num}
                                                </Typography>
                                            </Box>
                                            <Grid container spacing={0.5}>
                                                {item?.rooms?.map((room, roomIndex) => {
                                                    if (room?.room_no === num) {
                                                        let date = new Date(room?.date);
                                                        if (room?.available) {
                                                            return (
                                                                <Grid item xs={1.5} key={roomIndex}>
                                                                    <Box
                                                                        p={0.2}
                                                                        title={item?.date}
                                                                        bgcolor={date.getDay() === 6 || date.getDay() === 0 ? colors.redAccent[700] : colors.primary[400]}
                                                                        color={moment(date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") ? "yellow" : ""}
                                                                    >
                                                                        <Stack textAlign={"center"}>
                                                                            <Typography fontSize={10}>{months[date.getMonth()]} <b>{date.getDate()}</b></Typography>
                                                                            {/* <Typography fontSize={10}>{date.getFullYear()}</Typography> */}
                                                                        </Stack>
                                                                        {/* <small>{room?.date}</small> */}
                                                                    </Box>
                                                                    {/* <Box p={0.2}>
                                                                            {item?.rate ?? "-"}
                                                                        </Box> */}
                                                                    <Box
                                                                        // width={"200px"}
                                                                        className="avl"
                                                                        height={25}
                                                                        style={{
                                                                            cursor: selection && !moment(date).isBefore(moment().startOf("day")) ? "pointer" : "not-allowed",
                                                                            backgroundColor: moment(date).isBefore(moment().startOf("day")) ? "grey" : selectedRooms.includes(room) ? "green" : "",
                                                                            borderBottom: date.getDay() === 6 || date.getDay() === 0 ? `4px solid ${colors.redAccent[700]}` : "",
                                                                        }}
                                                                        onClick={selection && !moment(date).isBefore(moment().startOf("day")) ? () => {
                                                                            handleRoomSelection(room, {
                                                                                room_id: room?._id,
                                                                                room_no: room?.room_no,
                                                                                roomtype: item?.roomtype?.name,
                                                                                roomtype_id: item?.roomtype?._id,
                                                                                date: room?.date,
                                                                                // rate: roomsReports[index]?.data[0]?.rate / (roomsReports[index]?.data?.length - 1),
                                                                                rate: roomsReports[index]?.data[0]?.rate,
                                                                                index: roomIndex,
                                                                            })
                                                                        } : null}
                                                                    >
                                                                        <Box width={20}>
                                                                            <Checkbox
                                                                                size="small"
                                                                                checked={selectedRooms.includes(room)}
                                                                                sx={{ p: 0, display: selection && !moment(date).isBefore(moment().startOf("day")) ? "" : "none" }}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        } else {
                                                            return (
                                                                <Grid item xs={1.5} key={roomIndex}>
                                                                    <Box
                                                                        p={0.2}
                                                                        fontSize={12}
                                                                        title={item?.date}
                                                                        bgcolor={date.getDay() === 6 || date.getDay() === 0 ? colors.redAccent[700] : colors.primary[400]}
                                                                        color={moment(date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") ? "yellow" : ""}
                                                                    >
                                                                        <Stack textAlign={"center"}>
                                                                            <Typography fontSize={10}>{months[date.getMonth()]} <b>{date.getDate()}</b></Typography>
                                                                            {/* <Typography fontSize={10}>{date.getFullYear()}</Typography> */}
                                                                        </Stack>
                                                                    </Box>
                                                                    <Box
                                                                        // width={"200px"}
                                                                        p={0.2}
                                                                        height={25}
                                                                        display={"flex"}
                                                                        justifyContent={"center"}
                                                                        alignItems={"center"}
                                                                        onClick={(e) => handleClick(e, room, item)}
                                                                        className={room?.status === "hold" ? "hold" : room?.type === "phs" ? "phs" : "bkd"}
                                                                        style={{
                                                                            borderBottom: date.getDay() === 6 || date.getDay() === 0 ? `4px solid ${colors.redAccent[700]}` : "",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            className={
                                                                                room?.booking_from === "direct_booking" ? "direct" :
                                                                                    room?.booking_from === "agent" ? "agent" :
                                                                                        ["ota", "bookingdotcom", "yatra", "goibibo", "agoda", "expedia", "booklogic"].includes(room?.booking_from) ? "ota" : ""
                                                                            }
                                                                            title="expand" width={"80%"}
                                                                            overflow="hidden"
                                                                            // borderRadius={1}
                                                                            pl={0.1} pr={0.1}
                                                                        >
                                                                            <Typography fontWeight={600} fontSize={12} p={0} noWrap>
                                                                                <small>{room?.guest_name}</small>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        }
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </Grid>
                                        </Box>
                                    ))}
                                </>
                            )}
                        </Box>
                    ))}
                </Box>
            ) : null}
            </>) : (
                <MonthlyReportsMobile
                    selection={selection}
                    handleRoomSelection={handleRoomSelection}
                    selectedRooms={selectedRooms}
                />
            )}

            {!roomsReports?.length ? (
                <Stack
                    textAlign="center"
                    height="200px"
                    justifyContent="center"
                >
                    Loading...
                </Stack>
            ) : null}





            <CalendarPopover
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                bookingDetails={bookingDetails}
                colors={colors}
                navigate={navigate}
            />
        </Box>
    );
};

export default WeeklyReportsMobile;
