export const LIST_ALL_CALENDAR_REPORTS = `query ($date: String) {
  list_all_calendar_reports(date: $date){
    message
    data {
      date
      total_bookings
      available_rooms
      booked_rooms
    }
  }
}`;

export const LIST_ALL_ROOMS_REPORTS = `query ($startDate: String, $endDate: String) {
  list_all_rooms_reports1(start_date: $startDate, end_date: $endDate){
    message
    data{
      roomtype {
        _id
        name
      }
      data {
        date
        available_rooms
        booked_rooms
        rate
      }
      rooms {
        _id
        room_no
        date
        guest_name
        guest_email
        available
        booking_id
        checkin_date
        checkout_date
        status
        booking_from
        type
      }
      room_nos
    }
  }
}`;

export const LIST_ALL_INVENTORY_REPORTS = `query ($startDate: String, $endDate: String) {
  list_all_inventory_reports(start_date: $startDate, end_date: $endDate){
    message
    data{
      roomtype {
        _id
        name
      }
      data {
        date
        available_rooms
        booked_rooms
        total_rooms
        rate
        total_ota_inventory
        total_ota_booking
        total_non_ota_booking
      }
      ota_data {
        ota
        date
        total_inventory
        available_inventory
        total_booked_inventory
      }
      all_otas
    }
  }
}`;

export const LIST_ALL_INVENTORY_SCHEDULES = `query ($pageSize: Int, $pageNumber: Int, $startDate: Int, $endDate: Int) {
  list_all_inventory_schedules(page_size: $pageSize, page_number: $pageNumber, start_date: $startDate, end_date: $endDate){
    message
    data {
      _id
      rooms_available
      status
      start_date
      end_date
      ota
      api_calls
      is_finished
      roomtype_id {
        _id
        name
      }
    }
    hasMore
    count
  }
}`;

export const LIST_INVENTORY_SCHEDULE_DETAILS = `query ($id: String!) {
  list_inventory_schedule_details(_id: $id){
    message
    data {
      _id
      rooms_available
      status
      start_date
      end_date
      ota
      api_calls
      is_finished
      roomtype_id {
        _id
        name
        description
      }
      rates {
        standard_rate
        child_rate
        single_occupancy_rate
        max_occupancy_rate
        extra_bed_rate
      }
      calls_timeline {
        timeline
        status
        request
        response
      }
    }
  }
}`;