/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, ButtonGroup, Checkbox, Grid, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { listAllRooms, resetAllBookingsData, listAllBookings } from "../../store/actions";
import { Add, ArrowBackIosNew, ArrowForward, ArrowForwardIos, CalendarMonth } from "@mui/icons-material";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StatusChip } from "../../components/Chips";
import { ViewMoreFooter } from "../../components/DataGrid";
import { CssTextField } from "../../components/TextField";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";
import "./style.css";
import ResponsiveTable from "../../components/ResponsiveTable";

const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

const Bookings = () => {
    const d = new Date();
    const [dateNum, setDateNum] = useState(d.getMonth());
    const [year, setYear] = useState(d.getFullYear());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [searchId, setSearchId] = useState("");
    const [type, setType] = useState("all");
    const [startDate, setStartDate] = useState(moment(new Date(), "YYYY-MM-DD").startOf("day").unix());
    const [endDate, setEndDate] = useState(moment(new Date(), "YYYY-MM-DD").add(1, "y").endOf("day").unix());
    const [filtered, setFiltered] = useState([]);
    const [withoutCanc, SetWithoutCanc] = useState([]);
    const [selected, setSelected] = useState([]);
    const allStatuses = [
        { name: "confirmed", value: "confirmed"},
        { name: "booked", value: "booked"},
        { name: "cancelled", value: "cancelled"},
        { name: "Blocked", value: "hold"},
    ]

    const { bookingsList, showViewMore } = useSelector(
        ({
            admin: {
                bookings: { list, showViewMore }
            },
        }) => ({
            bookingsList: list,
            showViewMore,
        })
    );

    useEffect(() => {
        document.title = 'Bookings';
        dispatch(resetAllBookingsData());
        fetchMoreData();
    }, [startDate, endDate]);

    useEffect(() => {
        if(type === "month"){
            handleMonthlyDataFetch();
        }
    }, [dateNum]);

    useEffect(() => {
        var withoutCancelled = bookingsList?.filter((e) => e?.status !== "cancelled");
        SetWithoutCanc(withoutCancelled);

        var data = bookingsList?.filter((e) => selected?.includes(e?.status));
        setFiltered(data);
    }, [selected, bookingsList]);

    const fetchMoreData = () => {
        if (startDate && endDate) {
            dispatch(listAllBookings({ startDate: startDate, endDate: endDate }));
        } else {
            dispatch(listAllBookings());
        }
        dispatch(listAllRooms());
    };

    const handleMonthlyDataFetch = () => {
        let start = `01-${months[dateNum]}-${year}`;
        let end = moment(new Date(start)).endOf('month').format('DD-MMM-YYYY');
        start = moment(new Date(start)).unix();
        end = moment(new Date(end)).unix();
        dispatch(resetAllBookingsData());
        dispatch(listAllBookings({ startDate: start, endDate: end }));
    }

    const CustomFooter = () => {
        return (
            <ViewMoreFooter showViewMore={showViewMore} fetchMoreData={fetchMoreData} />
        );
    }

    const columns = [
        {
            field: "guest_name",
            headerName: "Guest info",
            width: 220,
            hideInXs: true,
            renderCell: ({ row: { guest_name, guest_email } }) => {
                return (
                    <Stack sx={{ wordBreak: "break-word" }}>
                        <Typography color={colors.greenAccent[400]}>
                            <b>{guest_name}</b>
                        </Typography>
                        <Typography fontSize={"small"}>
                            {guest_email || ""}
                        </Typography>
                    </Stack>
                )
            },
        },
        {
            field: "roomtypes",
            headerName: "Roomtype(s)",
            width: 150,
            renderCell: ({ row: { roomtypes } }) => {
                if (roomtypes?.length > 1) {
                    return (
                        <>
                            <CssTextField
                                // fullWidth
                                select
                                size="small"
                                variant="standard"
                                sx={{width: 200}}
                                value={roomtypes}
                                SelectProps={{ multiple: true }}
                            >
                                {roomtypes?.map((day, i) => (
                                    <MenuItem value={day} key={i}>
                                        {/* <Checkbox sx={{ p: "0 5px" }} checked={roomtypes.indexOf(day) > -1} /> */}
                                        {day}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </>
                    );
                } else {
                    return `${roomtypes[0]}`;
                }
            },
        },
        {
            field: "checkin_date",
            headerName: "Check-in date",
            width: 130,
            renderCell: ({ row }) => `${row?.checkin_date ? moment.unix(row.checkin_date).format("YYYY-MM-DD") : "-"}`,
        },
        {
            field: "checkout_date",
            headerName: "Check-out date",
            width: 130,
            renderCell: ({ row }) => `${row.checkout_date ? moment.unix(row.checkout_date).format("YYYY-MM-DD") : "-"}`,
        },
        // { field: "total_price", headerName: "Booking Price", width: 120 },
        { 
            field: "total_price", 
            headerName: "Price", 
            width: 100,
            renderCell: ({ row: { custom_price, total_price } }) => `${custom_price ? custom_price : total_price}`,
        },
        { field: "total_rooms", headerName: "Total rooms", width: 80 },
        { 
            field: "booking_from", 
            headerName: "Booking from", 
            width: 150,
            renderCell: ({ row: { booking_from } }) => `${booking_from?.replace("_", " ")}`, 
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            renderCell: ({ row: { status } }) => <StatusChip status={status} />,
        },
        {
            field: "_id",
            headerName: "View details",
            width: 110,
            hideInXs: true,
            renderCell: ({ row: { booking_id } }) => {
                return (
                    <Button 
                        size="small" 
                        variant="contained" 
                        onClick={() => navigate(`/booking-details/${booking_id}`)}
                        endIcon={<ArrowForward fontSize="small" />}
                        sx={{ fontWeight: 600 }}
                    >
                        View
                    </Button>
                )
            },
        },
    ];
    return (
        <Box m="20px" mb={15}>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={smScreen ? "center" : "start"}
                m="20px 0"
            >
                <Header title="BOOKINGS" subtitle="welcome to Bookings" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={() => navigate("/new-booking")}
                        disabled={localStorage?.getItem("isHotsoft") === "true"}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Add new booking
                    </Button>
                </Box>
            </Box>
            <Box className="box1">
            <Stack className="box2" direction={"row"} justifyContent={{ md: "space-between" }} alignItems={{sm: "end", xs: "start"}} spacing={2}>
                <Stack direction="row" spacing={1}>
                    <ButtonGroup
                        disableElevation
                        size="small"
                    >
                        <Button
                            variant={type === "all" ? "contained" : "outlined"}
                            onClick={() => {
                                setType("all")
                                dispatch(resetAllBookingsData());
                                fetchMoreData();
                            }}
                        >
                            <b>Recent</b>
                        </Button>
                        <Button
                            variant={type === "month" ? "contained" : "outlined"}
                            onClick={() => {
                                setType("month")
                                handleMonthlyDataFetch()
                            }}
                        >
                            <b>Monthly</b>
                        </Button>
                    </ButtonGroup>
                    <TextField
                        select
                        size="small"
                        variant="outlined"
                        label="Filter by status"
                        value={selected?.length ? selected : ["all"]}
                        onChange={(e) => setSelected(e?.target?.value?.filter(e => e !== "all"))}
                        sx={{ width: 150 }}
                        SelectProps={{ multiple: true }}
                    >
                        <MenuItem value={"all"}>
                            <em>All</em>
                        </MenuItem>
                        {allStatuses?.map((e) => (
                            <MenuItem value={e.value} key={e.value}>
                                <Checkbox size="small" sx={{ p: "0 5px 0 0" }} checked={selected.indexOf(e.value) > -1} />
                                {e.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Open booking with id"
                        value={searchId}
                        onChange={(e) => setSearchId(e.target.value?.replace(" ", ""))}
                        sx={{ width: 220 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton 
                                        size="small" 
                                        onClick={(e) => navigate(`/booking-details/${searchId}`)}
                                        disabled={!searchId}
                                    >
                                    <ArrowForward fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                {type === "all" ? (
                <Stack direction={"row"} spacing={1}>
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="Start Date"
                        placeholder="Start Date"
                        sx={{ width: 170 }}
                        value={startDate ? moment.unix(startDate).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setStartDate(moment(newValue.target.value, "YYYY-MM-DD").startOf("day").unix())
                            if (moment(newValue.target.value).add(1, "days").format("YYYY-MM-DD") > moment.unix(endDate).format("YYYY-MM-DD")) {
                                setEndDate(moment(newValue.target.value, "YYYY-MM-DD").add(1, "days").endOf("day").unix());
                            }
                        }}
                        inputRef={startInputRef}
                        onClick={() => startInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="End Date"
                        placeholder="End Date"
                        sx={{ width: 170 }}
                        inputProps={{ min: moment.unix(startDate).format("YYYY-MM-DD") }}
                        value={endDate ? moment.unix(endDate).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setEndDate(moment(newValue.target.value, "YYYY-MM-DD").endOf("day").unix())
                        }}
                        inputRef={endInputRef}
                        onClick={() => endInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                ) : (
                    <Stack direction="row" alignItems="center">
                        <IconButton onClick={() => {
                            setDateNum(dateNum > 0 ? dateNum - 1 : 11)
                            setYear(dateNum > 0 ? year : year - 1)
                        }}>
                            <ArrowBackIosNew />
                        </IconButton>
                        <Typography fontSize={20} width={100}>
                            {months[dateNum]}, {year}
                        </Typography>
                        <IconButton onClick={() => {
                            setDateNum(dateNum < 11 ? dateNum + 1 : 0)
                            setYear(dateNum < 11 ? year : year + 1)
                        }}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
            </Box>
            
            <ResponsiveTable 
                rows={selected?.length ? filtered : withoutCanc}
                columns={columns}
                CustomFooter={CustomFooter}
                buttonAction={(e) => navigate(`/booking-details/${e.booking_id}`)}
                cardHead={{
                    title: "guest_name",
                    subtitle: "guest_email",
                }}
            />
            {/* <Box
                m="8px 0 0 0"
                width="100%"
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        // border: "none",
                        fontSize: 16,
                    },
                    "& .MuiDataGrid-cell": {
                        // borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={selected?.length ? filtered : withoutCanc}
                    columns={columns}
                    components={{ Toolbar: GridToolbar, Footer: CustomFooter }}
                    getRowId={(row) => row?._id}
                />
            </Box> */}
            
            {/* <Box>
                <Grid container spacing={2}>
                    {bookingsList?.map((el, idx) => (
                        <Grid item xs={12} md={6} key={idx}>
                            <Stack
                                p={1}
                                bgcolor={colors.primary[400]}
                                fontSize={14}
                                onClick={() => navigate(`/booking-details/${el?.booking_id}`)}
                                sx={{ cursor: "pointer" }}
                            >
                                <table>
                                    <tbody>
                                        <tr>
                                            <td width={150}>Email:</td>
                                            <td><Typography sx={{ wordBreak: "break-word" }}>{el?.guest_email || "-"}</Typography></td>
                                        </tr>
                                        <tr>
                                            <td>Check-in date:</td>
                                            <td>
                                                <Typography>
                                                {el.checkin_date ? moment.unix(el.checkin_date).format("YYYY-MM-DD") : "-"}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Check-out date:</td>
                                            <td>
                                                <Typography>
                                                {el.checkin_date ? moment.unix(el.checkout_date).format("YYYY-MM-DD") : "-"}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Price:</td>
                                            <td>
                                                <Typography>{el.total_price ?? "-"}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total rooms:</td>
                                            <td>
                                                <Typography>{el.total_rooms ?? "-"}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Booking from:</td>
                                            <td>
                                                <Typography>{el.booking_from ?? "-"}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <td>
                                                <StatusChip status={el?.status} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Box> */}
        </Box>
    );
};

export default Bookings;
