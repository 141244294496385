import { Button, CircularProgress, Grid, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CssTextField } from '../../components/TextField';
import { ArrowBack, Email, Person, Phone, Restaurant } from '@mui/icons-material';
import { addBooking, listMealPlans } from '../../store/actions';
// import { useNavigate } from 'react-router-dom';
import * as yup from "yup";

function BookNow({ code, isLoading, setPage, roomtypeId, dispatch, setPaymentData }) {
    // const navigate = useNavigate();
    const [mPlans, setMPlans] = useState([]);
    const [mealPlan, setMealPlan] = useState("no_preference");
    let datas = localStorage.getItem("checkingData");
    datas = JSON.parse(datas);

    useEffect(() => {
        fetchMealPlans();
    // eslint-disable-next-line
    }, [])

    const fetchMealPlans = async() => {
        let mPlans = await dispatch(listMealPlans({ roomtypeId: roomtypeId }));
        setMPlans(mPlans);
        if(mPlans?.length){
            setMealPlan(mPlans[0]?._id)
        }
    }

    const initialValues = {
        title: "Mr",
        name: "",
        first_name: "",
        last_name: "",
        email: "",
        guest_mobile: "",
        guest_address: "",
        remarks: "",
        buyer_pincode: "682304",
    };

    const validationSchema = yup.object().shape({
        first_name: yup
            .string()
            .matches(/^\S*$/, "Spaces not allowed")
            .matches(/^\w*$/, "Special chars not allowed")
            .required("Required"),
        last_name: yup
            .string()
            .matches(/^\S*$/, "Spaces not allowed")
            .matches(/^\w*$/, "Special chars not allowed")
            .required("Required"),
        email: yup.string().email("Invalid email").required("Required"),
        guest_mobile: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Invalid mobile number")
            .max(20, "Invalid mobile number")
            // .matches(/^\d{10}$/, "Invalid mobile number")
            .required("Required"),
        // buyer_pincode: yup.number().required("Required"),
    })

    const handleFormSubmit = async (values) => {
        values.email = values?.email?.toLowerCase();
        let val = {
            ...values,
            hotel_code: code,
            roomtype_id: roomtypeId,
            no_of_adults: datas?.numOfAdults,
            no_of_childs: datas?.numOfChilds,
            total_rooms: datas?.totalRooms,
            checkin_date: datas?.checkinDate,
            checkout_date: datas?.checkoutDate,
            name: `${values.first_name} ${values.last_name || ""}`,
            meal_plan_id: mealPlan === "no_preference" ? "" : mealPlan,
            // first_name: values?.name,
            // last_name: "a",
        }
        console.log(val);
        let res = await dispatch(addBooking(val));
        if (res) {
            setPaymentData({
                ...res,
                ...values,
            })
            setPage(4);
            // navigate(`/booking/${code}/payment`)
        }
    }

    return (
        <>
            <Stack minHeight="100vh" justifyContent="center" alignItems="center">
                <Grid container spacing={2} justifyContent="center" mt={2} mb={2}>
                    <Grid item md={8} xs={12}>
                        <Button variant='contained' onClick={() => setPage(2)}>
                            <ArrowBack />
                        </Button>
                        <Stack p={2} bgcolor={"rgb(0, 0, 0, 0.7)"} style={{ backdropFilter: `blur(5px)` }} borderRadius={2} mt={2}>
                            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item md={6} xs={12}>
                                                <Stack direction="row">
                                                    <CssTextField
                                                        select
                                                        required
                                                        name="title"
                                                        onChange={handleChange}
                                                        value={values.title}
                                                        sx={{ width: 100 }}
                                                    >
                                                        <MenuItem value={"Mr"}>
                                                            {"Mr"}
                                                        </MenuItem>
                                                        <MenuItem value={"Ms"}>
                                                            {"Ms"}
                                                        </MenuItem>
                                                    </CssTextField>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        // size="small"
                                                        type="text"
                                                        // label="Name"
                                                        placeholder="Fist name *"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.first_name}
                                                        name="first_name"
                                                        error={!!touched.first_name && !!errors.first_name}
                                                        helperText={touched.first_name && errors.first_name}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Person />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    // size="small"
                                                    type="text"
                                                    // label="Name"
                                                    placeholder="Last name *"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.last_name}
                                                    name="last_name"
                                                    error={!!touched.last_name && !!errors.last_name}
                                                    helperText={touched.last_name && errors.last_name}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Person />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    // size="small"
                                                    type="email"
                                                    // label="Email"
                                                    placeholder="Email *"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                    error={!!touched.email && !!errors.email}
                                                    helperText={touched.email && errors.email}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Email />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    // size="small"
                                                    type="number"
                                                    placeholder="Mobile *"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.guest_mobile}
                                                    name="guest_mobile"
                                                    error={!!touched.guest_mobile && !!errors.guest_mobile}
                                                    helperText={touched.guest_mobile && errors.guest_mobile}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Phone />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    // size="small"
                                                    type="number"
                                                    label="Paid Amount"
                                                    placeholder="Paid Amount"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.paidAmount}
                                                    name="paidAmount"
                                                    onInvalid={e => e.target.setCustomValidity(rateErrorMsg)}
                                                    onInput={e => e.target.setCustomValidity('')}
                                                    error={!!touched.paidAmount && !!errors.paidAmount}
                                                    helperText={touched.paidAmount && errors.paidAmount}
                                                    InputProps={{
                                                        inputProps: { min: 0, max: selectedRooms[0]?.rate },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Money />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid> */}
                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    select
                                                    // size="small"
                                                    value={status}
                                                    label="Status"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Build />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                >
                                                    <MenuItem value={"confirmed"}>
                                                        {"Confirmed"}
                                                    </MenuItem>
                                                    <MenuItem value={"hold"}>
                                                        {"Hold"}
                                                    </MenuItem>
                                                </CssTextField>
                                            </Grid> */}
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    select
                                                    // size="small"
                                                    value={mealPlan}
                                                    label="Meal Plan"
                                                    onChange={(e) => setMealPlan(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Restaurant />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                >
                                                    {!mPlans?.length ?
                                                        <MenuItem value={"no_preference"}>
                                                            {"CP"}
                                                        </MenuItem>
                                                        : null}
                                                    {mPlans?.map((e) => (
                                                        <MenuItem value={e?._id} key={e?._id}>
                                                            <Stack width="100%">
                                                                <Stack
                                                                    width="100%"
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    spacing={2}
                                                                >
                                                                    <Typography>
                                                                        {e?.name?.toUpperCase()}
                                                                    </Typography>
                                                                    <Typography>
                                                                        ₹{e?.rate}
                                                                    </Typography>
                                                                </Stack>
                                                                <Typography color={"#aaa"}><small>{e?.description}</small></Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                    ))}
                                                </CssTextField>
                                            </Grid>
                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    // size="small"
                                                    type="number"
                                                    placeholder="Pincode *"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.buyer_pincode}
                                                    name="buyer_pincode"
                                                    error={!!touched.buyer_pincode && !!errors.buyer_pincode}
                                                    helperText={touched.buyer_pincode && errors.buyer_pincode}
                                                // InputProps={{
                                                //     startAdornment: (
                                                //         <InputAdornment position="start">
                                                //             <Phone />
                                                //         </InputAdornment>
                                                //     ),
                                                // }}
                                                />
                                            </Grid> */}

                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                    maxRows={Infinity}
                                                    type="text"
                                                    size="small"
                                                    label="Address"
                                                    placeholder="Address"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.guest_address}
                                                    name="guest_address"
                                                    error={!!touched.guest_address && !!errors.guest_address}
                                                    helperText={touched.guest_address && errors.guestAddress}
                                                // InputProps={{
                                                //     startAdornment: (
                                                //         <InputAdornment position="start">
                                                //             <EditLocation />
                                                //         </InputAdornment>
                                                //     ),
                                                // }}
                                                />
                                            </Grid> */}
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                    maxRows={Infinity}
                                                    type="text"
                                                    size="small"
                                                    label="Remarks"
                                                    placeholder="Remarks"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.remarks}
                                                    name="remarks"
                                                    error={!!touched.remarks && !!errors.remarks}
                                                    helperText={touched.remarks && errors.remarks}
                                                // InputProps={{
                                                //     startAdornment: (
                                                //         <InputAdornment position="start">
                                                //             <NoteAdd />
                                                //         </InputAdornment>
                                                //     ),
                                                // }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={12}>
                                                <Button
                                                    fullWidth
                                                    // size="small"
                                                    color="secondary"
                                                    variant="outlined"
                                                    sx={{ fontSize: "15px" }}
                                                    onClick={() => setPage(2)}
                                                >
                                                    <b>Go Back</b>
                                                </Button>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Button
                                                    fullWidth
                                                    // size="small"
                                                    type="submit"
                                                    color="secondary"
                                                    variant="contained"
                                                    sx={{ fontSize: "15px" }}
                                                >
                                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Book Now</b>}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}

export default BookNow