import React, { useEffect, useState } from "react";
import {
    Box, IconButton, Typography, Stack, Table, useTheme,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox,
} from "@mui/material";
import { ExpandLess, ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
// import { listAllRoomsReports, resetAllRoomsReports } from "../../store/actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import moment from "moment";
import "./style.css";
import CalendarPopover from "../../components/calendarPopover";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const MonthlyReportsMobile = ({ selection, handleRoomSelection, selectedRooms }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    // const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("expanded")) || []);
    const [bookingDetails, setBookingDetails] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, room, item) => {
        setBookingDetails({
            ...item,
            ...room,
        })
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { roomsReports } = useSelector(({ calendar: { roomsReports } }) => ({
        roomsReports,
    })
    );
    // console.log(roomsReports);
    // useEffect(() => {
    //     document.title = 'Calendar';
    //     handleMonthlyDataFetch(dateNum, year, true);
    //     // eslint-disable-next-line
    // }, [dispatch]);

    useEffect(() => {
        if (!expanded?.length) {
            let roomtypeNames = roomsReports?.map(a => a.roomtype._id);
            setExpanded(roomtypeNames);
            localStorage.setItem("expanded", JSON.stringify(roomtypeNames));
        }
        // eslint-disable-next-line
    }, [roomsReports]);

    // const handleMonthlyDataFetch = (m = dateNum, y = year, noLoading = false) => {
    //     let start = `01-${months[m]}-${y}`;
    //     let end = moment(start, 'DD-MMM-YYYY').endOf('month').format('DD-MMM-YYYY');
    //     // dispatch(resetAllRoomsReports());
    //     dispatch(listAllRoomsReports({ startDate: start, endDate: end, noLoading: noLoading }));
    // }

    const handleExpandBtn = (value) => {
        var data = expanded;
        if (data.includes(value)) {
            data = data.filter(item => item !== value);
        } else {
            data = [].concat(data, value);
        }
        setExpanded(data)
    }

    return (
        <Box>
            {roomsReports?.length ? (
                <Box width={"100%"} mt={2} display={"flex"} flexDirection={"column"} gap={2} className="reports">
                    {roomsReports?.map((item, index) => (
                        <Box key={index} border={"5px solid #2e7c67"}>
                            <Box
                                className="typename"
                                style={{ backgroundColor: "#2e7c67", color: "#fff" }}
                                onClick={() => handleExpandBtn(item?.roomtype?._id)}
                                title={item?.roomtype?.name}
                            >
                                <Stack direction="row" alignItems="center" justifyContent="space-between" pl={1}>
                                    <Typography fontWeight={600} width={140} noWrap textTransform="capitalize">
                                        {item?.roomtype?.name}
                                    </Typography>
                                    <IconButton size="small">
                                        {expanded.includes(item?.roomtype?._id) ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </Stack>
                            </Box>


                            {/* {expanded?.includes(item?.roomtype?._id) && ( */}
                            <Box sx={{ overflowX: "auto" }}>
                                <TableContainer>
                                    <Box component={Table} alignItems="center">
                                        <TableHead>
                                            <TableRow>
                                                {expanded.includes(item?.roomtype?._id) ? <th style={{ backgroundColor: colors.blueAccent[700] }}></th> : null}
                                                {roomsReports[0]?.data?.map((item, index) => {
                                                    let date = new Date(item.date);
                                                    return (
                                                        <th key={index} style={{
                                                            color: moment(date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") ? "yellow" : "",
                                                            backgroundColor: date.getDay() === 6 || date.getDay() === 0 ? colors.redAccent[700] : colors.blueAccent[700],
                                                        }}>
                                                            <Stack textAlign={"center"}>
                                                                <Typography fontSize={10}>{months[date.getMonth()]}</Typography>
                                                                <Typography><b>{date.getDate()}</b></Typography>
                                                                {/* <Typography fontSize={10}>{date.getFullYear()}</Typography> */}
                                                            </Stack>
                                                        </th>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {expanded.includes(item?.roomtype?._id) ?<TableCell /> : null}
                                                {item?.data?.map((e, i) => (
                                                    <TableCell key={i}>
                                                        <Stack direction={"column"} spacing={1} textAlign="center">
                                                            <div
                                                                className="avl-num"
                                                                style={{ color: e?.available_rooms < 1 ? "#d32f2f" : "" }}
                                                            >
                                                                <b>{e?.available_rooms}</b>
                                                            </div>
                                                            {/* {e?.booked_rooms ? <div className="bkd-num"><b>{e?.booked_rooms}</b></div> : null} */}
                                                        </Stack>
                                                    </TableCell>
                                                ))}
                                            </TableRow>

                                            {expanded?.includes(item?.roomtype?._id) && (
                                                <>
                                                    {item?.room_nos?.map((num, numIndex) => (
                                                        <TableRow key={numIndex} style={{ backgroundColor: colors.primary[500] }}>
                                                            <TableCell title={num} style={{ backgroundColor: colors.primary[800] }}>
                                                                <Typography fontWeight={600} width={40} noWrap textTransform="capitalize">
                                                                    {num}
                                                                </Typography>
                                                            </TableCell>
                                                            {item?.rooms?.map((room, roomIndex) => {
                                                                if (room?.room_no === num) {
                                                                    let date = new Date(room?.date);
                                                                    if (room?.available) {
                                                                        return (
                                                                            <TableCell
                                                                                key={roomIndex}
                                                                                width={"200px"}
                                                                                className="avl"
                                                                                style={{
                                                                                    cursor: selection && !moment(date).isBefore(moment().startOf("day")) ? "pointer" : "not-allowed",
                                                                                    backgroundColor: moment(date).isBefore(moment().startOf("day")) ? "grey" : selectedRooms.includes(room) ? "green" : "",
                                                                                    borderBottom: date.getDay() === 6 || date.getDay() === 0 ? `4px solid ${colors.redAccent[700]}` : "",
                                                                                }}
                                                                                onClick={selection && !moment(date).isBefore(moment().startOf("day")) ? () => {
                                                                                    handleRoomSelection(room, {
                                                                                        room_id: room?._id,
                                                                                        room_no: room?.room_no,
                                                                                        roomtype: item?.roomtype?.name,
                                                                                        roomtype_id: item?.roomtype?._id,
                                                                                        date: room?.date,
                                                                                        // rate: roomsReports[index]?.data[0]?.rate / (roomsReports[index]?.data?.length - 1),
                                                                                        rate: roomsReports[index]?.data[0]?.rate,
                                                                                        index: roomIndex,
                                                                                    })
                                                                                } : null}
                                                                            >
                                                                                <Box width={20}>
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        checked={selectedRooms.includes(room)}
                                                                                        sx={{ p: 0, display: selection && !moment(date).isBefore(moment().startOf("day")) ? "" : "none" }}
                                                                                    />
                                                                                </Box>
                                                                            </TableCell>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <TableCell
                                                                                key={roomIndex}
                                                                                width={"200px"}
                                                                                onClick={(e) => handleClick(e, room, item)}
                                                                                className={room?.status === "hold" ? "hold" : room?.type === "phs" ? "phs" : "bkd"}
                                                                                style={{
                                                                                    borderBottom: date.getDay() === 6 || date.getDay() === 0 ? `4px solid ${colors.redAccent[700]}` : "",
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                    className={
                                                                                        room?.booking_from === "direct_booking" ? "direct" :
                                                                                            room?.booking_from === "agent" ? "agent" :
                                                                                                ["ota", "bookingdotcom", "yatra", "goibibo", "agoda", "expedia", "booklogic"].includes(room?.booking_from) ? "ota" : ""
                                                                                    }
                                                                                    title="expand"
                                                                                    overflow="hidden"
                                                                                    width={20}
                                                                                    borderRadius={1}
                                                                                >
                                                                                    <Typography fontWeight={600} fontSize={14} p={0} noWrap>
                                                                                        <small>{room?.guest_name}</small>
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </TableRow>
                                                    ))}
                                                </>
                                            )}
                                        </TableBody>
                                    </Box>
                                </TableContainer>

                            </Box>
                        </Box>
                    ))}
                </Box>
            ) : null}

            {!roomsReports?.length ? (
                <Stack
                    textAlign="center"
                    height="200px"
                    justifyContent="center"
                >
                    Loading...
                </Stack>
            ) : null}

            <CalendarPopover
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                bookingDetails={bookingDetails}
                colors={colors}
                navigate={navigate}
            />
        </Box>
    );
};

export default MonthlyReportsMobile;
