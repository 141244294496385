import {
    RESET_AGENT_DETAILS,
    RESET_ALL_HOTEL_AGENTS,
    RESET_ALL_SUB_AGENTS_FOR_DROPDOWN,
    SAVE_AGENT_DETAILS,
    SAVE_ALL_HOTEL_AGENTS,
    SAVE_ALL_SUB_AGENTS_FOR_DROPDOWN,
} from "../types";

const INITIAL_STATE = {
    agents: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    agentDetails: {
        details: {},
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    subAgentsList: [],
};

const agentsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_HOTEL_AGENTS:
            return {
                ...state,
                agents: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HOTEL_AGENTS:
            return {
                ...state,
                agents: INITIAL_STATE.agents,
            };

        case SAVE_AGENT_DETAILS:
            return {
                ...state,
                agentDetails: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_AGENT_DETAILS:
            return {
                ...state,
                agentDetails: INITIAL_STATE.agentDetails,
            };

        case SAVE_ALL_SUB_AGENTS_FOR_DROPDOWN:
            return {
                ...state,
                subAgentsList: payload
            };

        case RESET_ALL_SUB_AGENTS_FOR_DROPDOWN:
            return {
                ...state,
                subAgentsList: INITIAL_STATE.subAgentsList,
            };

        default:
            return state;
    }
};

export default agentsReducer;
