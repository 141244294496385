export const ADD_NEW_ROOM_TYPE = `mutation ($name: String!, $description: String, $numOfBeds: Int!, $minNumOfPeople: Int!, $maxNumOfPeople: Int!, $highlights: [String], $facilities: [String], $images: [String], $rates: add_new_roomtype_rates!, $rooms: [add_new_roomtype_rooms]) {
    add_new_roomtype(name: $name, description: $description, no_of_beds: $numOfBeds, min_no_of_people: $minNumOfPeople, max_no_of_people: $maxNumOfPeople, highlights: $highlights, facilities: $facilities, images: $images, rates: $rates, rooms: $rooms){
      message
    }
  }
`;

export const LIST_ALL_ROOM_TYPES = `query ($pageSize: Int, $pageNumber: Int, $isForDropdown: Boolean) {
    list_all_roomtypes(page_size: $pageSize, page_number: $pageNumber, is_for_dropdown: $isForDropdown){
      message
      data {
        _id
        name
        description
        no_of_beds
        min_no_of_people
        max_no_of_people
        is_active
      }
      hasMore
      count
    }
  }
`;

export const LIST_ROOMTYPE_DETAILS = `query ($id: String!) {
    list_roomtype_details(_id: $id){
      message
      data {
        _id
        name
        description
        no_of_beds
        min_no_of_people
        max_no_of_people
        total_rooms
        ota_rooms
        is_private
        is_active
        rates {
          standard_rate
          child_rate
          single_occupancy_rate
          max_occupancy_rate
          extra_bed_rate
        }
        highlights {
          _id
          name
          icon
        }
        facilities {
          _id
          name
          icon
        }
        meal_plans{
          adult{
            ep
            cp
            map
            ap
          }
          child {
            ep
            cp
            map
            ap
          }
        }
        images
        display_room_no
        ota_codes {
          goibibo
          bookingdotcom
          expedia
          agoda
          yatra
        }
      }
    }
  }
`;

export const UPDATE_ROOMTYPE = `mutation ($id: String!, $name: String!, $description: String, $no_of_beds: Int!, $min_no_of_people: Int!, $max_no_of_people: Int!, $ota_rooms: Int, $is_private: Boolean, $display_room_no: Int, $highlights: [String], $facilities: [String], $images: [String], $rates: update_roomtype_rates!, $meal_plans: update_roomtype_meal_plans) {
    update_roomtype(_id: $id, name: $name, description: $description, no_of_beds: $no_of_beds, min_no_of_people: $min_no_of_people, max_no_of_people: $max_no_of_people, ota_rooms: $ota_rooms, is_private: $is_private, display_room_no: $display_room_no, highlights: $highlights, facilities: $facilities, images: $images, rates: $rates, meal_plans: $meal_plans){
      message
    }
  }
`;

export const ACTIVATE_ROOMTYPE = `mutation ($id: String!) {
    activate_roomtype(_id: $id){
      message
    }
  }
`;

export const LIST_ALL_MEAL_PLANS = `query ($roomtypeId: String, $pageSize: Int, $pageNumber: Int, $isForDropdown: Boolean) {
  list_all_meal_plans(roomtype_id: $roomtypeId, page_size: $pageSize, page_number: $pageNumber, is_for_dropdown: $isForDropdown){
    message
    data {
      _id
      name
      description
      rate
      roomtype_id
      is_active
    }
    hasMore
  }
}`;

export const ADD_NEW_MEAL_PLAN = `mutation ($roomtypeId: String!, $name: String!, $description: String, $rate: Float!, $isForChild: Boolean!) {
  add_new_meal_plan(roomtype_id: $roomtypeId, name: $name, description: $description, rate: $rate, is_for_child: $isForChild){
    message
  }
}`;

export const ACTIVATE_MEAL_PLAN = `mutation ($id: String!) {
  activate_meal_plan(_id: $id){
    message
  }
}`;

export const DELETE_MEAL_PLAN = `mutation ($id: String!) {
  delete_meal_plan(_id: $id){
    message
  }
}`;

export const UPDATE_MEAL_PLAN = `mutation ($id: String!, $name: String!, $description: String, $rate: Float!) {
  update_meal_plan(_id: $id, name: $name, description: $description, rate: $rate){
    message
  }
}`;

export const LIST_ALL_ROOMTYPE_OFFERS = `query ($pageSize: Int, $pageNumber: Int, $roomtypeId: String!) {
  list_all_roomtype_offers(page_size: $pageSize, page_number: $pageNumber, roomtype_id: $roomtypeId){
    message
    data {
      _id
      start_date
      end_date
      is_rate_fixed
      percentage
      fixed_rate
      is_rate_increment
      is_active
      standard_rate
      child_rate
      single_occupancy_rate
      max_occupancy_rate
      extra_bed_rate
      weekdays
      last_sync
      type
      sync_status
    }
    hasMore
  }
}`;

export const ADD_ROOMTYPE_OFFER = `mutation ($roomtypeId: String!, $startDate: Int!, $endDate: Int!, $isRateFixed: Boolean!, $percentage: Float!, $fixedRate: Float!, $isRateIncrement: Boolean!, $standardRate: Float, $childRate: Float, $singleOccupancyRate: Float, $maxOccupancyRate: Float, $extraBedRate: Float, $weekdays: [String], $type: String, $numOfDays: Int, $percentages: [add_roomtype_offer_percentages]) {
  add_roomtype_offer(roomtype_id: $roomtypeId, start_date: $startDate, end_date: $endDate, is_rate_fixed: $isRateFixed, percentage: $percentage, fixed_rate: $fixedRate, is_rate_increment: $isRateIncrement, standard_rate: $standardRate, child_rate: $childRate, single_occupancy_rate: $singleOccupancyRate, max_occupancy_rate: $maxOccupancyRate, extra_bed_rate: $extraBedRate, weekdays: $weekdays, type: $type, no_of_days: $numOfDays, percentages: $percentages) {
    message
  }
}`;

export const ACTIVATE_ROOMTYPE_OFFER = `mutation ($id: String!) {
  activate_roomtype_offer(_id: $id){
    message
  }
}`;

export const LIST_ALL_ROOMTYPE_OTA_COUNT = `query ($pageSize: Int, $pageNumber: Int, $roomtypeId: String!) {
  list_all_roomtype_ota_count(page_size: $pageSize, page_number: $pageNumber, roomtype_id: $roomtypeId){
    message
    data {
      _id
      start_date
      end_date
      count
      is_active
      last_sync
      sync_status
      otas
    }
    hasMore
  }
}`;

export const ADD_ROOMTYPE_OTA_COUNT = `mutation ($roomtypeId: String!, $startDate: Int!, $endDate: Int!, $count: Int!, $otas: [String]) {
  add_roomtype_ota_count(roomtype_id: $roomtypeId, start_date: $startDate, end_date: $endDate, count: $count, otas: $otas){
    message
  }
}`;

export const ACTIVATE_ROOMTYPE_OTA_COUNT = `mutation ($id: String!) {
  activate_roomtype_ota_count(_id: $id){
    message
  }
}`;

export const SYNC_ROOMTYPE_OFFER = `mutation ($id: String!) {
  sync_roomtype_offer(_id: $id){
    message
  }
}`;

export const SYNC_ROOMTYPE_OTA_COUNT = `mutation ($id: String!) {
  sync_roomtype_ota_count(_id: $id){
    message
  }
}`;

export const LIST_ALL_ROOMTYPE_OTAS = `query ($pageSize: Int, $pageNumber: Int, $roomtypeId: String!) {
  list_all_roomtype_otas(page_size: $pageSize, page_number: $pageNumber, roomtype_id: $roomtypeId){
    message
    data {
      _id
      ota
      roomtype_code
      is_active
    } 
  }
}`;

export const ACTIVATE_ROOMTYPE_OTA = `mutation ($id: String!) {
  activate_roomtype_ota(_id: $id){
    message
  }
}`;

export const GET_OTA_PROPERTY_DETAILS = `query ($id: String!) {
  get_ota_property_details(_id: $id){
    message
    data
  }
}`;