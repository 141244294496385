import { post } from "../../services";
import { CREATE_ROOMTYPES, HOTEL_PROFILE, REGISTRATION_STATUS, UPDATE_HOTEL_PROFILE } from "../../graphql";
import { RESET_HOTEL_PROFILE, SAVE_HOTEL_PROFILE, TOGGLE_LOADING } from "../types";

export const getRegistrationStatus = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: REGISTRATION_STATUS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                registration_status: { status },
            } = apiResponse.data.data;
            // console.log(status);
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return status;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateHotelProfile = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_HOTEL_PROFILE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetHotelProfile());
            dispatch(listHotelProfile());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listHotelProfile = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: HOTEL_PROFILE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                hotel_profile: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_HOTEL_PROFILE,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetHotelProfile = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_HOTEL_PROFILE });
    };
};

export const createRoomTypes = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CREATE_ROOMTYPES,
            variables: requestData,
        });
        
        if (apiResponse.data.customStatus) {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};