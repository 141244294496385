import { unAuthorizedPost } from "../../services";
import { ADD_BOOKING, CHECK_AVAILABILITY, CHECK_ROOMTYPE_PRICING, GET_HOTEL_DETAILS, LIST_MEAL_PLANS } from "../../graphql";
import { RESET_AVAILABILITY_DATA, RESET_HOTEL_DETAILS, RESET_ROOMTYPE_PRICING, SAVE_AVAILABILITY_DATA, SAVE_HOTEL_DETAILS, SAVE_ROOMTYPE_PRICING, TOGGLE_LOADING } from "../types";

const bookingEngineUrl = "https://api-dev.travelnet.in/graphql/users";

export const getHotelDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost(bookingEngineUrl, {
            query: GET_HOTEL_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_hotel_details: { data, status, is_private },
            } = apiResponse.data.data;

            dispatch({
                type: SAVE_HOTEL_DETAILS,
                payload: data,
            });
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return { status, is_private };
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHotelDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_HOTEL_DETAILS });
    };
};

export const checkAvailability = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost(bookingEngineUrl, {
            query: CHECK_AVAILABILITY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                check_availability: { data },
            } = apiResponse.data.data;

            dispatch({
                type: SAVE_AVAILABILITY_DATA,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAvailabilityData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_AVAILABILITY_DATA });
    };
};

export const checkRoomtypePricing = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost(bookingEngineUrl, {
            query: CHECK_ROOMTYPE_PRICING,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                check_roomtype_pricing: { data },
            } = apiResponse.data.data;

            dispatch({
                type: SAVE_ROOMTYPE_PRICING,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetRoomtypePricingData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ROOMTYPE_PRICING });
    };
};

export const addBooking = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost(bookingEngineUrl, {
            query: ADD_BOOKING,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                add_new_booking: { data },
            } = apiResponse.data.data;
            // toast.success("Booking success");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listMealPlans = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost(bookingEngineUrl, {
            query: LIST_MEAL_PLANS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_meal_plans: { data },
            } = apiResponse.data.data;
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};