import {
    RESET_ALL_INVENTORY_REPORTS,
    RESET_ALL_INVENTORY_SCHEDULES,
    RESET_ALL_ROOMS_REPORTS,
    RESET_INVENTORY_SCHEDULE_DETAILS,
    SAVE_ALL_INVENTORY_REPORTS,
    SAVE_ALL_INVENTORY_SCHEDULES,
    SAVE_ALL_ROOMS_REPORTS,
    SAVE_INVENTORY_SCHEDULE_DETAILS
} from "../types";

const INITIAL_STATE = {
    roomsReports: [],
    inventoryReports: [],
    inventorySchedules: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 50,
        count: 1,
    },
    invScheduleDetails: {},
};

const calendarReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_ROOMS_REPORTS:
            return {
                ...state,
                roomsReports: payload
            };

        case RESET_ALL_ROOMS_REPORTS:
            return {
                ...state,
                roomsReports: INITIAL_STATE.roomsReports,
            };

        case SAVE_ALL_INVENTORY_REPORTS:
            return {
                ...state,
                inventoryReports: payload
            };

        case RESET_ALL_INVENTORY_REPORTS:
            return {
                ...state,
                inventoryReports: INITIAL_STATE.inventoryReports,
            };

        case SAVE_ALL_INVENTORY_SCHEDULES:
            return {
                ...state,
                inventorySchedules: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_INVENTORY_SCHEDULES:
            return {
                ...state,
                inventorySchedules: INITIAL_STATE.inventorySchedules,
            };

        case SAVE_INVENTORY_SCHEDULE_DETAILS:
            return {
                ...state,
                invScheduleDetails: payload
            };

        case RESET_INVENTORY_SCHEDULE_DETAILS:
            return {
                ...state,
                invScheduleDetails: INITIAL_STATE.invScheduleDetails,
            };

        default:
            return state;
    }
};

export default calendarReducer;
