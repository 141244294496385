// /* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, Grid, InputAdornment, MenuItem, Stack, useTheme } from "@mui/material";
import { checkRoomAvailability, listAllRoomTypes, resetAvalableRoomsData } from "../../../store/actions";
import { CalendarMonth, RoomPreferences } from "@mui/icons-material";
import { CssTextField } from "../../../components/TextField";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { tokens } from "../../../theme";
import PlusOrMinusButton from "../../../components/PlusOrMinusButton";
import Header from "../../../components/Header";
import moment from "moment";
import * as yup from "yup";

const CheckAvailability = ({ setPage, setCheckingdata }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const checkinRef = useRef();
    const checkoutRef = useRef();
    const [selectedRoomType, setSelectedRoomType] = useState("");

    const { isLoading, roomTypes } = useSelector(
        ({
            loading, admin: {
                roomTypes: { list, showViewMore },
            }
        }) => ({
            isLoading: loading,
            roomTypes: list,
        })
    );

    useEffect(() => {
        document.title = 'New Booking';
        dispatch(listAllRoomTypes({ isForDropdown: true }));
        dispatch(resetAvalableRoomsData());
    }, [dispatch]);

    const handleFormSubmit = async (values) => {
        let roomsData = [{
            room_number: 1,
            no_of_adults: values.numOfAdults,
            no_of_childs: values.numOfChilds,
        }]
        if (selectedRoomType && selectedRoomType !== "no_preference") {
            roomsData[0].roomtype_id = selectedRoomType
        }
        let data = {
            // ...values,
            checkinDate: moment(values.checkinDate, "YYYY-MM-DD").unix(),
            checkoutDate: moment(values.checkoutDate, "YYYY-MM-DD").unix(),
            rooms: roomsData,
        };
        // console.log(data);
        await dispatch(checkRoomAvailability(data));
        setCheckingdata(data);
        setPage(2);
    };
    const initialValues = {
        checkinDate: moment().format("YYYY-MM-DD"),
        checkoutDate: moment().add(1, "days").format("YYYY-MM-DD"),
        numOfAdults: 2,
        numOfChilds: 0,
    };
    const checkoutSchema = yup.object().shape({
        numOfAdults: yup.number().min(1, "Number of Adults must be greater than or equal to 1").required("Required"),
    });

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                    <Box
                        width="100%"
                        p={2}
                        backgroundColor={colors.primary[400]}
                    >
                        <Header title="BOOKING" titleVariant="h4" />
                        <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <Stack>
                                                <CssTextField
                                                    fullWidth
                                                    required
                                                    type="date"
                                                    placeholder="Checkin Date"
                                                    label="Checkin Date"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        if (moment(e.target.value).add(1, "days").format("YYYY-MM-DD") > values.checkoutDate) {
                                                            setFieldValue(
                                                                'checkoutDate',
                                                                moment(e.target.value).add(1, "days").format("YYYY-MM-DD")
                                                            );
                                                        }
                                                    }}
                                                    name="checkinDate"
                                                    value={values.checkinDate}
                                                    inputProps={{ min: moment().format("YYYY-MM-DD") }}
                                                    error={!!touched.checkinDate && !!errors.checkinDate}
                                                    helperText={touched.checkinDate && errors.checkinDate}
                                                    inputRef={checkinRef}
                                                    onClick={() => checkinRef.current.showPicker()} 
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CalendarMonth sx={{ cursor: "pointer" }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Stack>
                                                <CssTextField
                                                    fullWidth
                                                    required
                                                    type="date"
                                                    placeholder="Checkout Date"
                                                    label="Checkout Date"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name="checkoutDate"
                                                    value={values.checkoutDate}
                                                    inputProps={{ min: moment(values.checkinDate).add(1, "days").format("YYYY-MM-DD") }}
                                                    error={!!touched.checkoutDate && !!errors.checkoutDate}
                                                    helperText={touched.checkoutDate && errors.checkoutDate}
                                                    inputRef={checkoutRef}
                                                    onClick={() => checkoutRef.current.showPicker()} 
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CalendarMonth sx={{ cursor: "pointer" }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <PlusOrMinusButton 
                                                min={1}
                                                max={5}
                                                size="large"
                                                label="Number of Adults"
                                                name="numOfAdults"
                                                value={values.numOfAdults}
                                                handleChange={(value) => setFieldValue("numOfAdults" ,value)}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <PlusOrMinusButton 
                                                min={1}
                                                max={5}
                                                size="large"
                                                label="Number of Children"
                                                name="numOfChilds"
                                                value={values.numOfChilds}
                                                handleChange={(value) => setFieldValue("numOfChilds" ,value)}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <CssTextField
                                                fullWidth
                                                select
                                                value={selectedRoomType || "no_preference"}
                                                label="Room Type"
                                                onChange={(e) => setSelectedRoomType(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <RoomPreferences />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            >
                                                <MenuItem value={"no_preference"}>
                                                    {"No Preference"}
                                                </MenuItem>
                                                {roomTypes?.map((roomType, index) => (
                                                    <MenuItem value={roomType._id} key={index}>
                                                        {roomType.name}
                                                    </MenuItem>
                                                ))}
                                            </CssTextField>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Button
                                                fullWidth
                                                // size="small"
                                                type="submit"
                                                color="secondary"
                                                variant="contained"
                                                sx={{ fontSize: "15px" }}
                                                disabled={localStorage?.getItem("isHotsoft") === "true"}
                                            >
                                                {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Check available rooms</b>}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    );
};

export default CheckAvailability;