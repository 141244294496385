import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import CheckAvailability from './checkAvailability';
import AvailableRooms from './availableRooms';
import AddNewBooking from './addNewBooking';
import { useNavigate } from 'react-router-dom';

function DashboardBooking() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [checkingdata, setCheckingdata] = useState({});
    const [selectedRooms, setSelectedRooms] = useState([]);

    useEffect(() => {
        document.title = 'New Booking';
    }, [dispatch]);

    return (
        <Box>
            { page === 1 ?
            <CheckAvailability setPage={setPage} setCheckingdata={setCheckingdata}/>
            : page === 2 ?
            <AvailableRooms setPage={setPage} checkingdata={checkingdata} selectedRooms={selectedRooms} setSelectedRooms={setSelectedRooms} dispatch={dispatch} navigate={navigate}/>
            : page === 3 ?
            <AddNewBooking setPage={setPage} checkingdata={checkingdata} selectedRooms={selectedRooms} navigate={navigate}/>
            : null }
        </Box>
    )
}

export default DashboardBooking;