import React, { useState } from 'react';
import { Box, Button, Divider, Grid, IconButton, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Country, State } from 'country-state-city';
import { CssTextField } from '../../components/TextField';
import { Formik } from 'formik';
import titles from '../../data/nameTitles';
import * as yup from "yup";

function BasicInfo({ basicInfoData, setBasicInfoData, isRegistration, handleProfileSubmit }) {
    // eslint-disable-next-line
    const [name, setName] = useState("");
    const [accDetails, setAccDetails] = useState(basicInfoData?.bank_details || {});
    const allCountries = Country.getAllCountries();
    const allStates = State.getStatesOfCountry(basicInfoData?.country || "IN");
    const countryDetails = Country.getCountryByCode(basicInfoData?.country || "IN");
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        password: "",
        confirmPassword: "",
    }

    const checkoutSchema = yup.object().shape({
        password: yup.string().required("Required").min(8),
        confirmPassword: yup.string().required("Required").oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const handleValueChange = (event) => {
        let fieldname = event?.target?.name;
        let data = basicInfoData;
        data[fieldname] = event?.target?.value || (event?.target?.type === "number" ? 0 : "");
        setBasicInfoData(data);
        setName(event.target.value);
    }

    const handleAccountdetailsChange = (event) => {
        let fieldname = event?.target?.name;
        let data = accDetails;
        data[fieldname] = event?.target?.value || (event?.target?.type === "number" ? 0 : "");
        setAccDetails(data);
        setBasicInfoData({ ...basicInfoData, bank_details: data })
        setName(event.target.value);
    }

    return (
        <Box>
            <Formik onSubmit={handleProfileSubmit} initialValues={initialValues} validationSchema={isRegistration ? checkoutSchema : null}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={{ md: 4, xs: 2 }}>
                            <Grid item md={6} xs={12}>
                                <Stack spacing={2}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Title:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            select
                                            required
                                            size="small"
                                            name="title"
                                            value={basicInfoData?.title || "Mr"}
                                            onChange={handleValueChange}
                                        >
                                            {titles?.map((title, index) => (
                                                <MenuItem value={title} key={index}>
                                                    {title}
                                                </MenuItem>
                                            ))}
                                        </CssTextField>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            First Name:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="text"
                                            placeholder="First Name"
                                            name="first_name"
                                            value={basicInfoData?.first_name || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Last Name:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Last Name"
                                            name="last_name"
                                            value={basicInfoData?.last_name || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Email:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            disabled
                                            size="small"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={basicInfoData?.email || ""}
                                            // onChange={handleValueChange}
                                        />
                                    </Stack>
                                    {isRegistration && (
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Password:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values?.password || ""}
                                            name="password"
                                            error={!!touched.password && !!errors.password}
                                            helperText={touched.password && errors.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton 
                                                            size='small'
                                                            onClick={() => setShowPassword(showPassword ? false : true)}
                                                        >
                                                        {showPassword ? <VisibilityOff fontSize='small'/> : <Visibility fontSize='small'/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                    )}
                                    {isRegistration && (
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Confirm Password:*
                                        </Typography>
                                        <CssTextField
                                            autoComplete='off'
                                            fullWidth
                                            required
                                            size="small"
                                            type="password"
                                            placeholder="Confirm Password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmPassword || ""}
                                            name="confirmPassword"
                                            error={!!touched.confirmPassword && !!errors.confirmPassword}
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                        />
                                    </Stack>
                                    )}
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Address Line 1:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="text"
                                            placeholder="Address Line 1"
                                            name="address1"
                                            value={basicInfoData?.address1 || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Address Line 2:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Address Line 2"
                                            name="address2"
                                            value={basicInfoData?.address2 || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Country:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            select
                                            required
                                            size="small"
                                            name="country"
                                            value={basicInfoData?.country || "IN"}
                                            onChange={handleValueChange}
                                        >
                                            {allCountries?.map((country) => (
                                                <MenuItem value={country.isoCode} key={country.isoCode}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </CssTextField>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            State:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            select
                                            required
                                            size="small"
                                            name="state"
                                            value={basicInfoData?.state || "Kerala"}
                                            onChange={handleValueChange}
                                        >
                                            <MenuItem value={""} sx={{ display: "none" }}>
                                                {"No Preference"}
                                            </MenuItem>
                                            {allStates?.map((state) => (
                                                <MenuItem value={state.name} key={state.name}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                        </CssTextField>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            City:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="city"
                                            name="city"
                                            value={basicInfoData?.city || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    {isRegistration && (
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography width={"50%"}>
                                                Property/Chain Name:*
                                            </Typography>
                                            <CssTextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                placeholder="Property/Chain Name"
                                                name="name"
                                                value={basicInfoData?.name || ""}
                                                onChange={handleValueChange}
                                            />
                                        </Stack>
                                    )}
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Phone Number:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            placeholder="Phone Number"
                                            name="phone"
                                            value={basicInfoData?.phone || ""}
                                            onChange={handleValueChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        +{countryDetails?.phonecode}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Mobile Number:*
                                        </Typography>
                                        <CssTextField
                                            autoComplete='off'
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            placeholder="Mobile Number"
                                            name="mobile"
                                            value={basicInfoData?.mobile || ""}
                                            onChange={handleValueChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        +{countryDetails?.phonecode}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Fax:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="Number"
                                            placeholder="Fax"
                                            name="fax"
                                            value={basicInfoData?.fax || ""}
                                            onChange={handleValueChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        +{countryDetails?.phonecode}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Postal Code:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Postal Code"
                                            name="postal_code"
                                            value={basicInfoData?.postal_code || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack spacing={2}>
                                    <Typography variant='h4' fontWeight={600} color="GrayText">
                                        Account details
                                    </Typography>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            A/c hoder name:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="A/c hoder name"
                                            name="acc_holder_name"
                                            value={accDetails?.acc_holder_name || ""}
                                            onChange={handleAccountdetailsChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            A/c no:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="A/c no"
                                            name="acc_no"
                                            value={accDetails?.acc_no || ""}
                                            onChange={handleAccountdetailsChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            IFSC code:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="IFSC code"
                                            name="ifsc"
                                            value={accDetails?.ifsc || ""}
                                            onChange={handleAccountdetailsChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            A/c type:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="A/c type"
                                            name="acc_type"
                                            value={accDetails?.acc_type || ""}
                                            onChange={handleAccountdetailsChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Bank name:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Bank name"
                                            name="bank_name"
                                            value={accDetails?.bank_name || ""}
                                            onChange={handleAccountdetailsChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Branch:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Branch"
                                            name="branch_name"
                                            value={accDetails?.branch_name || ""}
                                            onChange={handleAccountdetailsChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            GST No:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="GST No"
                                            name="gstin"
                                            value={basicInfoData?.gstin || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Divider />
                                    <Typography variant='h4' fontWeight={600} color="GrayText">
                                        Booking engine
                                    </Typography>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Min. child age:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            placeholder="Min. child age"
                                            name="min_child_age"
                                            value={basicInfoData?.min_child_age || ""}
                                            onChange={handleValueChange}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Max. child age:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            placeholder="Max. child age"
                                            name="max_child_age"
                                            value={basicInfoData?.max_child_age || ""}
                                            onChange={handleValueChange}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Booking engine discount (%):
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            placeholder="Booking engine discount (%)"
                                            name="bke_discount"
                                            value={basicInfoData?.bke_discount || ""}
                                            onChange={handleValueChange}
                                            inputProps={{ min: 0, max: 100 }}
                                        />
                                    </Stack>
                                    <Divider />
                                    {!isRegistration && (
                                        <Stack direction="row" spacing={2}>
                                            <Typography mt={0.5} width={"50%"}>
                                                Cancellation policy:
                                            </Typography>
                                            <CssTextField
                                                fullWidth
                                                multiline
                                                maxRows={10}
                                                size="small"
                                                type="text"
                                                placeholder="Cancellation policy"
                                                name="cancel_policy"
                                                value={basicInfoData?.cancel_policy || ""}
                                                onChange={handleValueChange}
                                            />
                                        </Stack>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Box>
                                    <Stack alignItems="flex-end">
                                        <Button type="submit" size='small' variant='contained'>
                                            <b>Submit</b>
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default BasicInfo