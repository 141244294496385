/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Checkbox, Chip, Grid, MenuItem, Stack, Switch, Typography } from '@mui/material';
import { CssTextField } from "../../components/TextField";
import { getMealPlansForDropdown, getSubAgentsForDropdown, updateBookingDatas } from "../../store/actions";
import { Formik } from "formik";
import { toast } from "react-toastify";
import titles from "../../data/nameTitles";

const formatRooms = (rooms = []) => {
    return rooms?.map((e) => {
        return{
            _id: e?._id,
            room_no: e?.room_id?.room_no,
            dates: e?.dates,
            roomtype_id: e?.room_id?.roomtype_id?._id,
            no_of_adults: e?.no_of_adults,
            no_of_childs: e?.no_of_childs,
            meal_plan_id: e?.meal_plan?.plan,
        }
    })
}

export default function BookingUpdation({ bookingDetails, setUpdation, colors, dispatch, agentsList }) {
    const [agentEnabled, setAgentEnabled] = useState(bookingDetails?.agent_id?._id ? true : false);
    const [agent, setAgent] = useState(bookingDetails?.agent_id?._id ? bookingDetails?.agent_id?._id : "no_preference");
    const [subAgent, setSubAgent] = useState(bookingDetails?.sub_agent_id?._id ? bookingDetails?.sub_agent_id?._id : "no_preference");
    const [subAgentsList, setSubAgentsList] = useState([]);
    const [notifyUser, setNotifyUser] = useState(false);
    const [mPlans, setMPlans] = useState([]);
    const [rooms, setRooms] = useState(formatRooms(bookingDetails?.rooms) || []);
    const [temp, setTemp] = useState("");

    const handleAgentChange = async (e) => {
        setAgent(e.target.value)
        setSubAgent("no_preference")
    }
    const fetchSubAgents = async () => {
        if(agent !== "no_preference"){
            let res = await dispatch(getSubAgentsForDropdown({ id: agent }));
            setSubAgentsList(res);
        }
    }

    const fetchMealPlans = async () => {
        let mealPlans = await dispatch(getMealPlansForDropdown())
        setMPlans(mealPlans);
    }

    const handleRoomsDataChange = (e, index) => {
        var roomsData = rooms;
        var fieldname = e?.target?.name;
        roomsData[index][fieldname] = e.target.value === "no_preference" ? "" : e.target.value;
        setTemp(e.target.value);
        setRooms(roomsData);
    }

    useEffect(() => {
        fetchMealPlans();
    },[])

    useEffect(() => {
        fetchSubAgents()
    },[agent])

    const handleFormSubmit = async (values) => {
        if(agentEnabled && agent === "no_preference"){
            return toast.error("Please select an agent");
        }
        else if(agentEnabled && agent !== "no_preference"){
            if(subAgent === "no_preference"){
                return toast.error("Please select a sub agent");
            } else {
                let filtered = subAgentsList?.filter((e) => e._id === subAgent)
                if(!filtered?.length){
                    return toast.error("Please select a valid sub agent");
                }
            }
        }

        let data = {
            booking_id: values.booking_id,
            title: values.title,
            name: values.name,
            email: values.email,
            custom_price: values.custom_price || 0,
            paid_amount: values.paid_amount || 0,
            guest_name: values.name,
            guest_email: values.email,
            guest_address: values.guest_address,
            guest_mobile: values.guest_mobile,
            remarks: values.remarks,
            preference: values.preference,
            billing_instruction: values.billing_instruction,
            notify_user: notifyUser,
            rooms: rooms.filter((e) => delete e.roomtype_id)
                        .filter((e) => delete e.dates)
                        .filter((e) => delete e.room_no),
        }
        if(agentEnabled){
            data.agent_id = agent;
            data.sub_agent_id = subAgent;
        }
        console.log(data);
        await dispatch(updateBookingDatas(data));
        setUpdation(false);
    }

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={bookingDetails}
        >
            {({ values, handleChange, handleSubmit }) => (
                <form id="bookingUpdation" onSubmit={handleSubmit}>
                    <Grid container rowSpacing={2} columnSpacing={{ md: 4, xs: 2 }}>
                        <Grid item md={12} xs={12}>
                            <Typography fontWeight="bold" color={colors.greenAccent[500]}>
                                Booking id: {bookingDetails?.booking_id || "-"}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={2}>
                                <Stack direction="row" spacing={2}>
                                    <Typography width={"70%"} mt={0.5}>
                                        Title:
                                    </Typography>
                                    <CssTextField
                                        select
                                        fullWidth
                                        required
                                        size='small'
                                        name="title"
                                        onChange={handleChange}
                                        value={values?.title || "Mr"}
                                    >
                                        {titles?.map((title, index) => (
                                            <MenuItem value={title} key={index}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </CssTextField>
                                </Stack>
                                <UpdationInput
                                    required
                                    inputLabel={"Name"}
                                    field={"name"}
                                    value={values?.name || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    required={!agentEnabled}
                                    inputLabel={"Email"}
                                    field={"email"}
                                    value={values?.email || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    required={!agentEnabled}
                                    type="number"
                                    inputLabel={"Mobile"}
                                    field={"guest_mobile"}
                                    value={values?.guest_mobile}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    type="number"
                                    inputLabel={"Paid amount"}
                                    field={"paid_amount"}
                                    value={values?.paid_amount}
                                    handleChange={handleChange}
                                    max={values?.custom_price || values?.total_price}
                                />
                                <UpdationInput
                                    type="number"
                                    inputLabel={"Special price"}
                                    field={"custom_price"}
                                    value={values?.custom_price}
                                    handleChange={handleChange}
                                />
                            </Stack>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={2}>
                                <UpdationInput
                                    inputLabel={"Preference"}
                                    field={"preference"}
                                    value={values?.preference || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    inputLabel={"Billing instruction"}
                                    field={"billing_instruction"}
                                    value={values?.billing_instruction || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    multiline
                                    minRows={2}
                                    inputLabel={"Address"}
                                    field={"guest_address"}
                                    value={values?.guest_address || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    multiline
                                    minRows={2}
                                    inputLabel={"Remarks"}
                                    field={"remarks"}
                                    value={values?.remarks || ""}
                                    handleChange={handleChange}
                                />
                                <Stack direction="row" alignItems="center">
                                    <Typography noWrap>Agent booking</Typography>
                                    <Switch
                                        checked={agentEnabled}
                                        onChange={(e) => setAgentEnabled(e.target.checked)}
                                    />
                                </Stack>
                                {agentEnabled && (
                                    <>
                                        <Stack direction="row" spacing={2}>
                                            <Typography width={"70%"}>
                                                Agent / Agency:
                                            </Typography>
                                            <CssTextField
                                                fullWidth
                                                select
                                                size="small"
                                                value={agent}
                                                required={agentEnabled}
                                                onChange={handleAgentChange}
                                            >
                                                <MenuItem value={"no_preference"}>
                                                    {"No selection"}
                                                </MenuItem>
                                                {agentsList?.map((agent) => (
                                                    <MenuItem value={agent._id} key={agent._id}>
                                                        {(agent?.first_name && `${agent?.first_name} ${agent?.last_name || ""}`) || agent?.email}
                                                    </MenuItem>
                                                ))}
                                            </CssTextField>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Typography width={"70%"}>
                                                Sub Agent:
                                            </Typography>
                                            <CssTextField
                                                fullWidth
                                                select
                                                size="small"
                                                value={subAgent}
                                                required={agentEnabled}
                                                onChange={(e) => setSubAgent(e.target.value)}
                                                disabled={!agent || agent === "no_preference"}
                                            >
                                                <MenuItem value={"no_preference"}>
                                                    {"No selection"}
                                                </MenuItem>
                                                {subAgentsList?.map((subagent) => (
                                                    <MenuItem value={subagent._id} key={subagent._id}>
                                                        {subagent?.name || ""}
                                                    </MenuItem>
                                                ))}
                                            </CssTextField>
                                        </Stack>
                                    </>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Grid container spacing={2} mt={2}>
                                {rooms?.map((element, index) => {
                                    let mealPlans = mPlans?.filter((e) => e.roomtype_id === element?.roomtype_id)
                                    return (
                                        <Grid item md={6} xs={12} key={index}>
                                            <Box
                                                width="100%"
                                                p={2}
                                                borderRadius={2}
                                                backgroundColor={colors.primary[500]}
                                            >
                                                <Stack spacing={2}>
                                                    <Stack color={colors.greenAccent[500]} fontWeight={600}>
                                                        Room no: {element?.room_no}
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"40%"} mt={1}>
                                                            Selected dates:
                                                        </Typography>
                                                        <Chip label={element?.dates} size='small' />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Number of adults:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            required
                                                            size='small'
                                                            type="number"
                                                            placeholder="Number of adults"
                                                            name="no_of_adults"
                                                            value={element?.no_of_adults}
                                                            onChange={(e) => handleRoomsDataChange(e, index)}
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                        />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Number of children:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            required
                                                            size='small'
                                                            type="number"
                                                            placeholder="Number of children"
                                                            name="no_of_childs"
                                                            value={element?.no_of_childs}
                                                            onChange={(e) => handleRoomsDataChange(e, index)}
                                                            InputProps={{ inputProps: { min: 0 } }}
                                                        />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Meal plan:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            select
                                                            size="small"
                                                            name='meal_plan_id'
                                                            value={element?.meal_plan_id || "no_preference"}
                                                            onChange={(e) => handleRoomsDataChange(e, index)}
                                                        >
                                                            <MenuItem value={"no_preference"}>
                                                                {"Room Only"}
                                                            </MenuItem>
                                                            {mealPlans?.map((e) => (
                                                                <MenuItem value={e?._id} key={e?._id}>
                                                                    <Stack
                                                                        width="100%"
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        spacing={2}
                                                                    >
                                                                        <Typography>
                                                                            {e?.name?.toUpperCase()}
                                                                        </Typography>
                                                                        <Typography>
                                                                            ₹{e?.rate}
                                                                        </Typography>
                                                                    </Stack>
                                                                </MenuItem>
                                                            ))}
                                                        </CssTextField>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Stack direction="row" alignItems="center">
                                <Checkbox
                                    checked={notifyUser}
                                    onChange={(e) => setNotifyUser(e.target.checked)}
                                />
                                <Typography noWrap>Send email</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
}

const UpdationInput = ({ inputLabel, field, value, handleChange, required, type = "text", size = "small", multiline, minRows, min = 0, max }) => {
    return (
        <Stack direction="row" spacing={2}>
            <Typography width={"70%"} mt={0.5}>
                {inputLabel}: {required ? "*" : ""}
            </Typography>
            <CssTextField
                fullWidth
                multiline={multiline}
                minRows={minRows || ""}
                required={required}
                size={size}
                type={type}
                placeholder={`${inputLabel} ${required ? "*" : ""}`}
                name={field}
                value={value}
                onChange={handleChange}
                inputProps={type === "number" ? {min: min, max: max || undefined} : undefined}
            />
        </Stack>
    )
}