import { tokens } from "../theme";
import { MenuItem, Select, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ResponsivePagination from 'react-responsive-pagination';
// import 'react-responsive-pagination/themes/classic.css';
import './pagination.css';

export const PaginationFooter = ({ count = 1, pageSize, pageNumber, fetchMoreData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const totalPages = Math.ceil(count/pageSize);

    const handlePageNumberChange = (num) => {
        fetchMoreData({ pageNumber: num });
    }
    const handlePageSizeChange = (e) => {
        fetchMoreData({ pageSize: e.target.value, pageNumber: 1 });
    }
    function getCurrentRows(rowsPerPage, currPage, rowCount) {
        var from = (rowsPerPage * currPage) - rowsPerPage + 1;
        var to = (rowsPerPage * currPage) > rowCount ? rowCount : rowsPerPage * currPage;
        if(rowCount === 0) return "0 of 0"
        else return from + "-" + to + " of " + rowCount;
    }

    return (
        <Stack 
            p={1} 
            bgcolor={colors.blueAccent[700]} 
            overflow={"hidden"} 
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
        >
            <ResponsivePagination
                current={pageNumber ?? 1}
                total={totalPages || 1}
                onPageChange={handlePageNumberChange}
                maxWidth={smScreen ? 600 : 200}
            />
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography fontSize="small" noWrap>
                    {getCurrentRows(pageSize, pageNumber, count)}
                </Typography>
                <Select
                    size="small"
                    // label="Rows per page"
                    value={pageSize ?? 10}
                    onChange={handlePageSizeChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                    }}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                </Select>
            </Stack>
        </Stack>
    );
}