import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHotelDetails, resetAllHotelDetails, resetAvailabilityData } from '../../store/actions';
import { Container, Stack, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { tokens } from '../../theme';
import Loader from '../../components/Loader';
import Page1 from './page1';
import AvailableRoomtypes from './availableRoomtypes';
import BookNow from './bookNow';
import Airpay from './airpayPG';
import ErrorPage from './errorPage';

// const exceptions = ["misty-mountain-plantation-resort"];

function BookingEngine() {
    const { code } = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [status, setStatus] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [roomtypeId, setRoomtypeId] = useState("");
    const [page, setPage] = useState(1);
    const [errMsg, setErrMsg] = useState("");
    const [paymentData, setPaymentData] = useState({});

    const { isLoading, hotelDetails, availableRoomtypes } = useSelector(
        ({ loading, bookingEngine: { hotelDetails, availableRoomtypes } }) => ({
            isLoading: loading,
            hotelDetails,
            availableRoomtypes,
        })
    );
    // console.log(hotelDetails);
    useEffect(() => {
        document.title = 'Booking';
        dispatch(resetAllHotelDetails());
        dispatch(resetAvailabilityData());
        fetchData();
        // eslint-disable-next-line
    }, [])

    const fetchData = async () => {
        let { status, is_private } = await dispatch(getHotelDetails({ hotelCode: code }));
        if (status === true) {
            setStatus(status);
            setIsPrivate(is_private);
        }
    }

    return (
        <>
        <Loader visible={isLoading} />
        <Stack width="100%" height="100%" style={{
            backgroundImage: `url(${hotelDetails?.hotel_img || "https://assets.cntraveller.in/photos/63b80c6d79d81704e445df00/3:2/w_1920,h_1280,c_limit/Westin%20Himalayas%20facade.jpg"})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
        }}>
            <Stack style={{backdropFilter: hotelDetails?.hotel_img && page === 1 ? "" : `blur(10px)`}}>
            {status ? (
                <Container>
                    {page === 1 && (
                       <Page1 
                            code={code}
                            dispatch={dispatch}
                            isPrivate={isPrivate}
                            colors={colors}
                            setPage={setPage}
                            setErrMsg={setErrMsg}
                            hotelDetails={hotelDetails}
                            isLoading={isLoading}
                        />
                    )}
                    {page === 2 && (
                        <AvailableRoomtypes
                            roomtypes={availableRoomtypes}
                            colors={colors}
                            setPage={setPage}
                            roomtypeId={roomtypeId}
                            setRoomtypeId={setRoomtypeId}
                        />
                    )}
                    {page === 3 && (
                        <BookNow
                            // roomtypes={availableRoomtypes}
                            code={code}
                            isLoading={isLoading}
                            setPage={setPage}
                            roomtypeId={roomtypeId}
                            dispatch={dispatch}
                            setPaymentData={setPaymentData}
                        />
                    )}
                    {page === 4 && (
                        <Airpay
                            // code={code}
                            // isLoading={isLoading}
                            // setPage={setPage}
                            // roomtypeId={roomtypeId}
                            // dispatch={dispatch}
                            paymentData={paymentData}
                        />
                    )}
                    {page === 5 && (
                        <ErrorPage
                            msg={errMsg}
                        />
                    )}
                </Container>
            ) : (
                <Stack alignItems="center" justifyContent="center" minHeight={"100vh"}>
                    <Stack p={5} bgcolor={colors.primary[400]}>
                        Property does not exist!
                    </Stack>
                </Stack>
            )}
            </Stack>
        </Stack>
        </>
    )
}

export default BookingEngine