/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useSelector } from "react-redux";
import { ArrowBack, ArrowForward, Block } from "@mui/icons-material";
import Header from "../../../components/Header";
import { CssTextField } from "../../../components/TextField";
import { addNewGroupBooking, getMealPlansForDropdown } from "../../../store/actions";
import ConfirmModal from "../../../components/ConfirmModal";

const AvailableRooms = ({ setPage, checkingdata, selectedRooms, setSelectedRooms, dispatch, navigate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isPvt, setIsPvt] = useState(false);
  const [modal, setModal] = useState(false);
  const [mPlans, setMPlans] = useState([]);
  const [temp, setTemp] = useState("");


  const { availableRoomsData } = useSelector(
    ({
      loading,
      admin: {
        availableRooms: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      availableRoomsData: list,
    })
  );

  useEffect(() => {
    fetchMealPlans();
  },[])

  const fetchMealPlans = async () => {
    let mealPlans = await dispatch(getMealPlansForDropdown())
    setMPlans(mealPlans);
  }

  const handleBackButton = () => {
    setPage(1)
    setSelectedRooms([]);
  }

  const handleBlockButton = async () => {
    let data = {
      ...checkingdata,
      numOfAdults: checkingdata.rooms[0]?.no_of_adults,
      numOfChilds: checkingdata.rooms[0]?.no_of_childs,
      name: "Blocked",
      guestName: "Blocked",
      email: "block@phs.in",
      guestEmail: "block@phs.in",
      status: "hold",
      agentId: "",
      subAgentId: "",
      rooms: selectedRooms,
    };

    console.log(data);
    let result = await dispatch(addNewGroupBooking(data));
    if (result) {
      navigate("/bookings");
    }
  };

  const handleRoomSelection = (room) => {
    if (selectedRooms?.filter((e) => e.room_id === room?._id)?.length) {
      let removed = selectedRooms?.filter((e) => e.room_id !== room?._id);
      setSelectedRooms(removed);
    } else {
      let mPlns = mPlans?.filter((l) => l.roomtype_id === room?.roomtype_id?._id);
      let data = [{
        room_id: room?._id,
        meal_plan_id: mPlns[0]?._id || "",
      }]
      if(room?.roomtype_id?.is_private){
        setSelectedRooms(data);
      } else {
        if(isPvt){
          setSelectedRooms(data);
        }else {
          let added = selectedRooms?.concat(data);
          setSelectedRooms(added);
        }
      }
    }
    setIsPvt(room?.roomtype_id?.is_private)
  }

  const handleMealPlanSelection = (mealPlanId, roomId) => {
    var data = selectedRooms;
    var index = selectedRooms.findIndex(e => e.room_id === roomId);
    data[index].meal_plan_id = mealPlanId;
    setTemp(mealPlanId)
    setSelectedRooms(data);
  }

  const handleSelectAll = () => {
    let rooms = [];
    availableRoomsData[0]?.data?.map((e) => {
      if(!e?.roomtype_id?.is_private){
        let mPlns = mPlans?.filter((l) => l.roomtype_id === e?.roomtype_id?._id);
        rooms.push({room_id: e?._id, meal_plan_id: mPlns[0]?._id || "",})
      }
      return null;
    })
    setIsPvt(false)
    setSelectedRooms(rooms)
  }

  return (
    <Box               
      width="100%"
      p={2}
      backgroundColor={colors.primary[400]}
    >
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={1}
        mb="10px"
      >
        <Stack 
          spacing={1}
          direction="row"
          alignItems="flex-end"
        >
          <Box title="go back" height={"40px"}>
            <IconButton type="button" onClick={handleBackButton}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Header title="AVAILABLE ROOMS" titleVariant="h4" />
        </Stack>
        <Button variant="text" onClick={handleSelectAll}><b>Select All</b></Button>
      </Stack>
      <Grid container spacing={1}>
        {availableRoomsData?.map((element, index) => (
          <Grid item lg={12} xs={12} key={index}>
            <Box>
              <Grid container spacing={1}>
                {/* <Grid item lg={12} xs={12}>
                  <Typography variant="h4"><b>Room {element?.room_number}</b></Typography>
                </Grid> */}
                {element?.data.map((room, i) => {
                  let mealPlans = mPlans?.filter((e) => e.roomtype_id === room?.roomtype_id?._id)
                  return (
                  <Grid item lg={6} md={12} xs={12} key={i}>
                    <Stack
                      // p={1}
                      borderRadius={2}
                      overflow="hidden"
                      border={1}
                      borderColor={selectedRooms?.length ? room?.roomtype_id?.is_private === isPvt ? colors.greenAccent[600] : colors.grey[600] : colors.greenAccent[600]}
                      backgroundColor={selectedRooms?.filter((e) => e.room_id === room?._id)?.length ? colors.greenAccent[800] : colors.primary[500]}
                      direction="row"
                      spacing={1}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleRoomSelection(room)}
                    >
                      <Stack bgcolor={selectedRooms?.length ? room?.roomtype_id?.is_private === isPvt ? colors.greenAccent[600] : colors.grey[600] : colors.greenAccent[600]} p={1} alignItems="center">
                        <Typography noWrap><small>Room No</small></Typography>
                        <Typography fontSize={20} fontWeight={600}>{room?.room_no}</Typography>
                      </Stack>
                      <Stack p={1}>
                        <Stack color={selectedRooms?.length ? room?.roomtype_id?.is_private === isPvt ? colors.greenAccent[500] : colors.grey[600] : colors.greenAccent[500]}><b>{room?.roomtype_id?.name}</b></Stack>
                        <Stack>Rs {room?.rate}</Stack>
                        {/* <Stack>{room?.roomtype_id?.is_private ? "pvt" : "room"}</Stack> */}
                        {/* <Stack>Number of beds: {room?.no_of_beds}</Stack> */}
                      </Stack>
                    </Stack>
                    {/* {selectedRooms?.filter((e) => e.room_id === room?._id)?.length ? ( */}
                    {selectedRooms?.map((prop, idx) => { 
                      if (prop?.room_id === room?._id) return (
                      <Stack p={1} bgcolor={colors.primary[500]} borderRadius={2} key={idx}>
                        <CssTextField
                          fullWidth
                          select
                          size="small"
                          label="Select meal plan"
                          value={prop?.meal_plan_id || "no_preference"}
                          onChange={(e) => handleMealPlanSelection(e?.target?.value, room?._id)}
                        >
                          {mealPlans?.length < 1 ?
                          <MenuItem value={"no_preference"}>
                            {"CP"}
                          </MenuItem>
                          : null}
                          {mealPlans?.map((e) => (
                            <MenuItem value={e?._id} key={e?._id}>
                              <Stack
                                width="100%"
                                direction="row"
                                justifyContent="space-between"
                                spacing={2}
                              >
                                <Typography>
                                  {e?.name?.toUpperCase()}
                                </Typography>
                                <Typography>
                                  ₹{e?.rate}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          ))}
                        </CssTextField>
                      </Stack>
                    )
                    else return ""
                    })}
                  </Grid>
                )})}
              </Grid>
              {!element?.data?.length ?
                <Stack p={2} alignItems={"center"}>
                  No Rooms Available for selected inputs
                </Stack>
                : null}
            </Box>
          </Grid>
        ))}
      </Grid>

      <Grid mt={2} container spacing={1}>
        <Grid item lg={6} xs={6}>
          <Button
            fullWidth
            // size="small"
            color="secondary"
            variant="outlined"
            sx={{ fontSize: "15px" }}
            disabled={!selectedRooms?.length}
            endIcon={<Block />}
            onClick={() => setModal(true)}
          >
            <b>Block rooms</b>
          </Button>
        </Grid>
        <Grid item lg={6} xs={6}>
          <Button
            fullWidth
            // size="small"
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ fontSize: "15px" }}
            disabled={!selectedRooms?.length}
            endIcon={<ArrowForward />}
            onClick={() => {
              setPage(3)
            }}
          >
            <b>Continue</b>
          </Button>
        </Grid>
      </Grid>

      <ConfirmModal 
        open={modal} 
        setModal={setModal} 
        text="Are you sure to block the selected rooms?" 
        action={handleBlockButton}
      />
    </Box >
  );
};

export default AvailableRooms;