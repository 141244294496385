import { combineReducers } from "redux";
import { LOGOUT } from "../types";
import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import adminReducer from "./adminReducer";
import usersReducer from "./usersReducer";
import agentsReducer from "./agentsReducer";
import bookingReducer from "./bookingReducer";
import calendarReducer from "./calendarReducer";
import registrationReducer from "./registrationReducer";
import RoomtypesReducer from "./roomtypesReducer";
import bookingEngineReducer from "./bookingEngineReducer";
import dashboardReducer from "./dashboardReducer";
import otasReducer from "./otasReducer";
import hotsoftReducer from "./hotsoftReducer";

const appReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    admin: adminReducer,
    users: usersReducer,
    otas: otasReducer,
    agents: agentsReducer,
    booking: bookingReducer,
    calendar: calendarReducer,
    registration: registrationReducer,
    roomtypes: RoomtypesReducer,
    bookingEngine: bookingEngineReducer,
    dashboard: dashboardReducer,
    hotsoft: hotsoftReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
