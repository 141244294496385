import React, { useState } from "react";
import { Box, Button, Modal, Stack, Switch, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { Close } from "@mui/icons-material";

export default function AvailabilityUpdation({ name = "", isActive, action }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modal, setModal] = useState(false);

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    function handleActionBtn() {
        action()
        setModal(false)
    }

    return (
        <>
            <Switch
                checked={isActive}
                onClick={() => setModal(true)}
            />
            <Modal open={modal} onClose={() => setModal(false)}>
                <Box sx={modalStyle}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h4">
                            Are you sure to <b>{isActive ? "disable" : "enable"}</b> ?
                        </Typography>
                        <Close onClick={() => setModal(false)} sx={{ cursor: "pointer" }} />
                    </Stack>
                    <Stack mb={5}>
                        <Typography color={colors.greenAccent[500]} fontSize={20}>
                            <b>{name || ""}</b>
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="end">
                        <Button
                            // fullWidth
                            variant="text"
                            onClick={() => setModal(false)}
                        >
                            <b>Close</b>
                        </Button>
                        <Button
                            // fullWidth
                            sx={{ width: 100 }}
                            variant="contained"
                            onClick={handleActionBtn}
                        >
                            <b>Yes</b>
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}
