import React, { useEffect } from "react";
import { 
  AddToQueue, AutoAwesome, Groups2, Groups3Outlined, InventoryTwoTone, MeetingRoom, Web,
} from "@mui/icons-material";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Grid from "@mui/material/Unstable_Grid2";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";

const Settings = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    document.title = 'Settings';
  }, []);

  return (
    <Box m="20px" mb={15}>
      {/* HEADER */}

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="SETTINGS" subtitle="Welcome to settings" />
      </Box>

      {/* GRIDS */}
      <Grid container rowSpacing={{ xs: 1, sm: 2 }} columnSpacing={2}>
        {/* <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/inventory")}
          >
            <StatBox
              title="Inventory reports"
              // progress="0.50"
              // increase="+21%"
              icon={
                <Inventory
                  sx={{ color: colors.greenAccent[500], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid> */}
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/inventory-schedules")}
          >
            <StatBox
              title="Inventory schedules"
              // progress="0.50"
              // increase="+21%"
              icon={
                <InventoryTwoTone
                  sx={{ color: colors.greenAccent[500], fontSize: "40px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/otas")}
          >
            <StatBox
              title="OTAs"
              icon={
                <Web
                  sx={{ color: colors.greenAccent[500], fontSize: "40px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/rooms")}
          >
            <StatBox
              title="Rooms"
              icon={
                <MeetingRoom
                  sx={{ color: colors.greenAccent[500], fontSize: "40px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/agents")}
          >
            <StatBox
              title="Agents"
              icon={
                <Groups2
                  sx={{ color: colors.greenAccent[500], fontSize: "40px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/staffs")}
          >
            <StatBox
              title="Staffs"
              icon={
                <Groups3Outlined
                  sx={{ color: colors.greenAccent[500], fontSize: "40px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/highlights")}
          >
            <StatBox
              title="Highlights"
              icon={
                <AutoAwesome
                  sx={{ color: colors.greenAccent[500], fontSize: "40px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/facilities")}
          >
            <StatBox
              title="Facilities"
              icon={
                <AddToQueue
                  sx={{ color: colors.greenAccent[500], fontSize: "40px" }}
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
