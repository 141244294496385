import Dashboard from "./views/dashboard";
import Rooms from "./views/rooms";
import Highlights from "./views/highlights";
import Bookings from "./views/bookings";
// import Calendar from "./views/calendar";
import Facilities from "./views/facilities";
import RoomTypes from "./views/room-types";
import Pricings from "./views/pricings";
import AgentsList from "./views/agents";
import Profile from "./views/profile";
import Registration from "./views/registration";
import NewBooking from "./views/bookings/new-booking";
import AddNewRate from "./views/pricings/addNewRate";
import BookingDetails from "./views/bookings/bookingDetails";
import RoomDetails from "./views/rooms/roomDetails";
import AgentDetails from "./views/agents/agentDetails";
import Settings from "./views/settings";
import HotelUsers from "./views/users";
import HotelOtas from "./views/otas";
import RoomtypeDetails from "./views/room-types/roomtypeDetails";
// import WeeklyReports from "./views/calendar/weeklyReports";
import BulkRoomtypeCreation from "./views/room-types/bulk-creation";
import UpdateCheckinDetails from "./views/bookings/update-checkin-details";
import GroupBookingPage1 from "./views/calendar/groupBookingPage1";
// import InventoryReports from "./views/inventory";
import InventorySchedules from "./views/inventory-schedules";
import InventoryScheduleDetails from "./views/inventory-schedules/inventoryScheduleDetails";
import BookingReports from "./views/booking-reports";
import RevManage from "./views/revenue-manager";
import HotsoftUpdatesList from "./views/hotsoft";
import HotsoftUpdateDetails from "./views/hotsoft/hotsoftUpdateDetails";
import WeeklyReportsMobile from "./views/calendar/weeklyReportsMobile";
// import Form from "./views/form";
// import Bar from "./views/bar";
// import Line from "./views/line";
// import Pie from "./views/pie";
// import FAQ from "./views/faq";
// import Geography from "./views/geography";

export const adminRoutes = [
    {
        path: "/dashboard",
        component: <Dashboard />,
    },
    {
        path: "/registration",
        component: <Registration />,
    },
    {
        path: "/rooms",
        component: <Rooms />,
    },
    {
        path: "/room-details/:id",
        component: <RoomDetails />,
    },
    {
        path: "/room-types",
        component: <RoomTypes />,
    },
    {
        path: "/roomtype-creation",
        component: <BulkRoomtypeCreation />,
    },
    {
        path: "/roomtype-details/:id",
        component: <RoomtypeDetails />,
    },
    {
        path: "/pricings",
        component: <Pricings />,
    },
    {
        path: "/new-pricing",
        component: <AddNewRate />,
    },
    {
        path: "/bookings",
        component: <Bookings />,
    },
    {
        path: "/new-booking",
        component: <NewBooking />,
    },
    {
        path: "/booking-details/:id",
        component: <BookingDetails />,
    },
    {
        path: "/reports",
        component: <BookingReports />,
    },
    {
        path: "/update-checkin/:id",
        component: <UpdateCheckinDetails />,
    },
    {
        path: "/highlights",
        component: <Highlights />,
    },
    {
        path: "/facilities",
        component: <Facilities />,
    },
    // {
    //     path: "/form",
    //     component: <Form />,
    // },
    // {
    //     path: "/checkin-details",
    //     component: <Calendar />,
    // },
    {
        path: "/calendar",
        // component: <WeeklyReports />,
        component: <WeeklyReportsMobile />,
    },
    // {
    //     path: "/inventory",
    //     component: <InventoryReports />,
    // },
    {
        path: "/inventory-schedules",
        component: <InventorySchedules />,
    },
    {
        path: "/inventory-schedule-details/:id",
        component: <InventoryScheduleDetails />,
    },
    {
        path: "/agents",
        component: <AgentsList />,
    },
    {
        path: "/agent-details/:id",
        component: <AgentDetails />,
    },
    {
        path: "/staffs",
        component: <HotelUsers />,
    },
    {
        path: "/otas",
        component: <HotelOtas />,
    },
    {
        path: "/profile",
        component: <Profile />,
    },
    {
        path: "/group-booking",
        component: <GroupBookingPage1 />,
    },
    {
        path: "/settings",
        component: <Settings />,
    },
    {
        path: "/revenue-manager",
        component: <RevManage />,
    },
    {
        path: "/hotsoft-updates",
        component: <HotsoftUpdatesList />,
    },
    {
        path: "/hotsoft-update-details/:id",
        component: <HotsoftUpdateDetails />,
    },
]